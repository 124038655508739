import React from 'react';
import { Box, Button, Image, Text, VStack } from '@chakra-ui/react';
import handImg from "assets/img/hand.svg"; // Assuming this path is correct and manImg is used for all for illustration
import { FaHandHoldingHeart, FaThumbsUp } from 'react-icons/fa';

const MotivationalCard = ({ onNext }) => {
  return (
    <VStack
      width={'100%'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      spacing={4}
      p={5}
      bg="salmon"
      color="white"
      align="center"
    >
      <Text fontSize="xl" fontWeight="bold">
        You're in good hands
      </Text>
      {/* Placeholder image - Replace with your actual image */}
      {/* <Image src={handImg} alt="Good hands" boxSize="150px" /> */}
      <FaThumbsUp  size={50}/>
      <Text textAlign="center">
        You're not alone, we've helped 36,27,436 people lose weight!*
      </Text>
      <Button colorScheme="red" onClick={onNext}>
        Continue
      </Button>
      <Text fontSize="xs">
        *Noom subscribers who lost at least 2% of their original weight as of October 2023.
      </Text>
    </VStack>
  );
};

export default MotivationalCard;
