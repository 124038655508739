import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Checkbox,
  CheckboxGroup,
  Button,
  Heading,
  Stack,
  Box,
} from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

const HomeEquipmentStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedEquipment, setSelectedEquipment] = useState(formData.homeEquipment || []);

  const equipmentOptions = [
    { id: 'yoga_mat', title: 'Yoga Mat' },
    { id: 'dumbbells', title: 'Dumbbells' },
    { id: 'bar_bells', title: 'Bar bells' },
    { id: 'kettle_bells', title: 'Kettle bells' },
    { id: 'resistance_bands', title: 'Resistance Bands' },
    { id: 'treadmill', title: 'Treadmill' },
    { id: 'stationary_cycle', title: 'Cycle / Stationary Cycle' },
    { id: 'none', title: 'None' }
  ];

  const handleEquipmentSelection = (selections) => {
    setSelectedEquipment(selections);
    setFormData({ ...formData, homeEquipment: selections });
  };
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
  setDisplayheader(true);
   setQuestionNo(26)
 }, []);
 //for setting question no end
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10}   className='bottomImage'>
      <Heading as="h5" size="md" textAlign="left" mb={1}>
        What equipment is available with you at home?
      </Heading>

      <CheckboxGroup colorScheme="teal" value={selectedEquipment} onChange={handleEquipmentSelection}>
        {equipmentOptions.map((equipment) => (
          <Checkbox className='default_checkbox1'  key={equipment.id} value={equipment.id}>
            {equipment.title}
          </Checkbox>
        ))}
      </CheckboxGroup>

      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={() => setCurrentStep(currentStep + 1)} isDisabled={selectedEquipment.length === 0}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default HomeEquipmentStep;
