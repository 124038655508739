import React, { useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Heading,
} from '@chakra-ui/react';

const EditIdealWeightInputStep = ({ formData, setFormData }) => {
  
 

  const handleWeightChange = (e) => {
    const value = e.target.value;
    const valueAsNumber = parseFloat(value);

    if (!isNaN(valueAsNumber) && value !== '') {
      // Directly update formData
         setFormData({ ...formData, idealWeight: valueAsNumber });
       
    } else if (value === '') {
      setFormData({ ...formData, idealWeight: '' });
    }
  };

  return (
    <>
        <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel>  What is your ideal weight that you want to achieve?</FormLabel>
      
        <Input
          type="number" 
          value={formData.idealWeight?.toString() || ''}
          onChange={handleWeightChange}
          mt={3}
         
        />
      </FormControl>
    </>
  );
};

export default EditIdealWeightInputStep;
