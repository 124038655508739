import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Heading,
  Stack,
  Image,
} from '@chakra-ui/react';
import MotivationalCard2 from './MotivationalCard2';
import down_left_backgd_img from 'assets/img/down_left_backgd_img.png'
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const IdealWeightInputStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [idealWeight, setIdealWeight] = useState('');
  const minWeight = 30; // Define the minimum value based on your requirements
  const maxWeight = 200; // Define the maximum value based on your requirements
  const [showMotivationCard, setShowMotivationCard] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    // Validate formData.idealWeight on load and adjust if necessary
    let initialWeight = formData.idealWeight;

    // If the initial weight is not set or is out of range, set it to a default value
    if (initialWeight==null || initialWeight < minWeight || initialWeight > maxWeight || initialWeight === '') {
      initialWeight = ''; // Set it to empty if it's out of range
      setIsValid(false);
    } else {
      setIsValid(true); // Set validity flag
    }

    setIdealWeight(initialWeight);
    setFormData({ ...formData, idealWeight: initialWeight });
  }, []);

  const handleWeightChange = (e) => {
    const valueAsNumber = e.target.value;

    if (!isNaN(valueAsNumber)) {
      setIdealWeight(valueAsNumber);
      setFormData({ ...formData, idealWeight: valueAsNumber });

      if (valueAsNumber >= minWeight && valueAsNumber <= maxWeight) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    }
  };

  const handleNext = () => {
    setShowMotivationCard(true);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1); // Move to the previous step
  };
  const handleGotIt = () => {
    // Close the motivational card and go to the next step
    setShowMotivationCard(false);
    setCurrentStep(currentStep + 1);
  };

//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(9)
}, []);
//for setting question no end

  return (<>

  
    <VStack spacing={4} align="stretch" pl={10} pr={10}
      className='bottomImage'
    >
      {!showMotivationCard && (
        <>
          {/* <Heading as="h3" size="lg" textAlign="center" color={'teal'}>
            What is your ideal weight that you want to achieve?
          </Heading> */}

          <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
          What is the ideal weight you want to achieve?(in kgs)
          
          </Heading>
      <p  textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>Recommended Weight Range For You : 75-85kgs</p>
          <FormControl id="ideal-weight-control">
            {/* <FormLabel>Enter your ideal weight (in kilograms)</FormLabel> */}
            <Input
              type="number"
              value={idealWeight}
              onChange={handleWeightChange}
              mt={3}
              placeholder='Type your answer here...'
              className='default_input'
            />
          </FormControl>

       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
            <Button className='default_back_button'  colorScheme="teal" variant="outline" onClick={handleBack}>
              Back
            </Button>
            {/* <Button
             className='default_next_button' 
              colorScheme="teal"
              onClick={handleNext}
              isDisabled={!isValid}> */}

                <Button
             className='default_next_button' 
              colorScheme="teal"
              onClick={handleGotIt}
              isDisabled={!isValid}>
              Next
            </Button>
          </Stack>
          

        </>
      )}

      {showMotivationCard && (
        <MotivationalCard2 handleGotIt={handleGotIt} />
      )}
    </VStack>
    {/* <Image src={down_left_backgd_img} alt="Good hands" boxSize="300px"   /> */}
    </>
  );
};

export default IdealWeightInputStep;
