import React, { useState, useEffect, useContext } from 'react';
import { FormControl, FormLabel, Flex, Box, Button, Image, Stack, Heading, Radio, RadioGroup } from '@chakra-ui/react';
import manImg from "assets/img/maleGender.png"; // Assuming this path is correct and manImg is used for all for illustration
import womanImg from "assets/img/femaleGender.png"; // Assuming this path is correct and manImg is used for all for illustration
import othersImg from "assets/img/othersGender.png"; // Assuming this path is correct and manImg is used for all for illustration
import down_backgd_img from 'assets/img/down_left_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const GenderStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [gender, setGender] = useState(formData.gender || '');
  const [isGenderValid, setIsGenderValid] = useState(false);

  useEffect(() => {
    // Check if a gender has been selected
    setIsGenderValid(['M', 'F', 'O'].includes(formData.gender));
  }, [formData.gender]);

  const handleGenderChange = (selectedGender) => {
    const genderValue = selectedGender === 'male' ? 'M' : selectedGender === 'female' ? 'F' : 'O';
    setGender(genderValue);
    setFormData({ ...formData, gender: genderValue });
    setIsGenderValid(true);
  };

  const nextStep = () => {
    if (currentStep < totalSteps && isGenderValid) { 
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  //for setting question no start
  const { setQuestionNo,setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(true);
    setQuestionNo(3)
  }, []);
 //for setting question no end

  return (
    <>
      <FormControl isRequired pl={10} pr={10} className='bottomLeftImage'>
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>What's your gender? </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>This question is required. </p>
     
     <br />
      {/* <Heading  as="h3" size="lg"   textAlign="center"  mb={6} color={'teal'}>What's your gender?</Heading> */}
        {/* <FormLabel textAlign="center">What's your gender?</FormLabel> */}
        <RadioGroup onChange={handleGenderChange} value={gender}>
        <Flex justify="space-between" mt={4}>
          <Box 
          onClick={() => handleGenderChange('male')}
           cursor={'pointer'}
           boxShadow={gender === 'M' ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0 0 5px rgba(0, 0, 0, 0.1)'}
           border={gender === 'M' ?   '':'1px solid grey'}
            p={4}
             
            m={1}
            borderRadius="md"
            align='center' 
            width={'30%'}
           >
            <Radio value='M'    className='default_radio_input1'  />
            <Image src={manImg} h='60px' mx="auto" />
            <p   mb={5}  align='center'> Male</p>
          </Box>


          <Box 
          onClick={() => handleGenderChange('female')}
           cursor={'pointer'}
           boxShadow={gender === 'F' ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0 0 5px rgba(0, 0, 0, 0.1)'}
            p={4}
           
            m={1}
            borderRadius="md" 
            border={gender === 'F' ?   '':'1px solid grey'}
            align='center' 
            width={'30%'}
           >
            <Radio value='F'    className='default_radio_input1'   />
            <Image src={womanImg} h='60px' mx="auto"  />
            <p  mb={5} align='center'>Female</p>
          </Box>


          <Box 
          onClick={() => handleGenderChange('others')} 
          cursor={'pointer'}
          boxShadow={gender === 'O' ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0 0 5px rgba(0, 0, 0, 0.1)'}
          border={gender === 'O' ?   '':'1px solid grey'}
            p={4}
          
            m={1}
            borderRadius="md"
          
            align='center' 
            width={'30%'}
           >
            <Radio value='O'       className='default_radio_input1'  /> 
            <Image src={othersImg} h='60px' w='60px' mx="auto" />
            <p mb={5} align='center'>Others</p>
          </Box>
        </Flex>
        </RadioGroup>
      {/* </FormControl> */}
       
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8} >
        <Button className='default_back_button' colorScheme='teal' variant='outline' onClick={prevStep}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme='teal'   onClick={nextStep} isDisabled={!isGenderValid}>
          Next
        </Button>
      </Stack>
</FormControl>
      {/* <Image src={down_backgd_img} alt="Good hands"   boxSize="300px" /> */}

    </>
  );
};

export default GenderStep;
