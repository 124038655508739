import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Box, Input, InputGroup, InputLeftElement, Link, SimpleGrid, Skeleton } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { doLogout } from 'helpers/loginHelper';
import { fetchAllUserDietInfo } from 'services/foodItemsService';
import { IoFastFood } from 'react-icons/io5';
import { MdLock } from 'react-icons/md';
import { FaDownload, FaEdit, FaEye, FaRegEdit, FaTrash, FaWhatsapp } from 'react-icons/fa';
import { downloadPdf } from 'services/foodItemsService';
import { sendPdfToWhatsApp } from 'services/foodItemsService';
import { deleteDietInfo } from 'services/foodItemsService';
import { goals } from '../userDiet/Fields/FitnessGoalSelectorStep';
import { durations } from '../userDiet/Fields/TimeSinceIdealWeightStep';
import { encrypt } from 'helpers/EncrDecr';
  
export default function UserDietInfoList() {
  const [userDietInfoData, setUserDietInfoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const previewUrl=process.env.REACT_APP_PREVIEW_URL;
  const fetchUserDietInfo = () => {
    setLoading(true); // Set loading to true when starting data fetch

    fetchAllUserDietInfo()
      .then(res => {
        setUserDietInfoData(res);
        setLoading(false); // Set loading to false when data fetch is completed
      })
      .catch(error => {
        setLoading(false); // Set loading to false when data fetch is completed

        if (error.response && error.response.status === 401) {
          // Handle 401 Unauthorized error
          Swal.fire({
            icon: 'error',
            title: 'Please log in again.',
          }).then((result) => {
            // Check if the user clicked the "OK" button on the success message
            if (result.isConfirmed || result.isDismissed) {
              doLogout();
              history.push('/logout');
            }
          });
        } else {
          console.error('Error fetching user diet info:', error);
        }
      });
  };

  useEffect(() => {
    fetchUserDietInfo();
   }, []);



   
  const DeleteUserDiet = (id) => {
    Swal.fire({
      title: 'Are you sure want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        deleteDietInfo(id)
          .then(downloadRes => {
            console.log(downloadRes);
  
            if(downloadRes.statusCode === 200){
              fetchUserDietInfo();
              Swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              );
            } else {
              Swal.fire({
                icon: 'error',
                title: downloadRes.message,
              });
            }
          })
          .catch(error => {
            console.error('Error during the deletion:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to Delete',
            });
          });
      }
    });
  };
  





  const handleEdit = (id) => {
    // Handle the edit action here, you can navigate to an edit page or perform any other action
    Swal.fire({
      title: 'Are you sure you want to edit this User?',
      text: 'You will be redirected to the edit page.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, edit it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Navigate to the edit component with the specific ID

       let encId= encrypt(id);

        history.push(`/diet/editUserDiet/${encId}`);
      }
    });    
  };
  
  const sendToWhatsApp = (id) => {
    // Handle the edit action here, you can navigate to an edit page or perform any other action
    sendPdfToWhatsApp(id)
    .then(downloadRes => {
      console.log(downloadRes);


      if(downloadRes.statusCode===200){
        // history.push('/userDiet');  
         Swal.fire({
          icon: 'success',
          title: downloadRes.message,
          //title: "Pdf  Successfully Sent to Whatsapp",
         })   
       }else{
        Swal.fire({
          icon: 'error',
          title: downloadRes.message,
         });
      }


    })
    .catch(error => {
      console.error('Error downloading PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to download PDF',
      });
    });    
  };
  const columns = [
    //{ name: 'ID', selector: row => row.id,  },
    {
      name: 'SNo.',
      selector: (row, index) => index + 1,
      sortable: false,
      width: '70px'
    },
    {
      name: 'User Entered On',
      selector: row => row.insertedOn,
      sortable: true,
      sortFunction: (rowA, rowB) => {
        const dateA = new Date(rowA.insertedOn);
        const dateB = new Date(rowB.insertedOn);
        return dateA.getTime() - dateB.getTime();
      }
    }
    ,
    { name: 'Edit', 
      cell: (row) =>   <FaRegEdit onClick={() => handleEdit(row.id)} as={MdLock} width='20px' height='20px' color='inherit' style={{ cursor: 'pointer',color:'blue' }}/> ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    } 
    ,
    { name: 'Delete', 
      cell: (row) =>   <FaTrash onClick={() => DeleteUserDiet(row.id)} as={MdLock} width='20px' height='20px' color='inherit' style={{ cursor: 'pointer' ,color:'red'}}/> ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

    {
      name: 'View',
      cell: (row) => ( row.dietFilePath!=null&&
          <Link href={previewUrl+row.dietFilePath} target="_blank">
              <FaEye as={MdLock} width='40px' height='40px' color='inherit' style={{ cursor: 'pointer' }}/>
          </Link>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
  },
    { name: 'Whatsapp', 
      cell: (row) =>   <FaWhatsapp onClick={() => sendToWhatsApp(row.id)} as={MdLock} width='20px' height='20px' color='inherit' style={{ cursor: 'pointer',color:'green' }}/> ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    { name: 'Phone Number', selector: row => row.phoneNumber,  sortable: true,},
    { name: 'Name', selector: row => row.name,  sortable: true,},
    { name: 'Gender', selector: row => row.gender,  sortable: true,},
    { name: 'Age', selector: row => row.age,  sortable: true,},

    { name: 'Body Fat Percentage', selector: row => row.bodyFatPercentage,  sortable: true,},

    
    { name: 'Height Type', selector: row => row.heightType,  sortable: true,},
    { name: 'Height In Cm', selector: row => row.heightInCm ? row.heightInCm : '-',  sortable: true,},
    { name: 'Height In Feet', selector: row => row.heightInFeet? row.heightInFeet : '-',  sortable: true,},
    { name: 'Height In Inches', selector: row => row.heightInInches? row.heightInInches : '-', sortable: true,},


    { name: 'Weight Type', selector: row => row.weightType, sortable: true,},
    { name: 'Weight In Kg', selector: row => row.weightInKg? row.weightInKg : '-', sortable: true,},
    { name: 'Weight In Lbs', selector: row => row.weightInLbs? row.weightInLbs : '-', sortable: true,},
    
    { name: 'Goal', selector: row => getGoalTitleById(row.goal), sortable: true,},

    { name: 'Time Since Ideal Weight', selector: row => getDurationTitleById(row.timeSinceIdealWeight), sortable: true,},
    { name: 'Ideal Weight', selector: row => row.idealWeight, sortable: true,},
    { name: 'Preparing Meal', selector: row => row.prepareYourMeal, sortable: true,},
    { name: 'Activity Level', selector: row => row.activityLevel, sortable: true,},

    { name: 'Currently Workout ', selector: row => row.workout, sortable: true,},
    { name: 'Intensity ', selector: row => row.intensity? row.intensity : '-', sortable: true,},

    { name: 'Add Workout ', selector: row => row.addWorkout? row.addWorkout : '0', sortable: true,},
    { name: ' Weight training every day ', selector: row => row.weightTrainingDuration? row.weightTrainingDuration+'min' : '-', sortable: true,},

    { name: ' No of training days per week ', selector: row => row.trainingDaysPerWeek? row.trainingDaysPerWeek+'days' : '-', sortable: true,},





    // { name: 'Email', selector: row => row.email,  sortable: true,},
    // { name: 'Body Weight', selector: row => row.weightInKg,  sortable: true, },
    { name: 'Physical Activity Factor', selector: row => row.physicalActivityFactor,  sortable: true,},
    { name: 'Thermic Effect of Food Factor', selector: row => row.thermicEffectOfFoodFactor,  sortable: true,},
    { name: 'Weight Training Duration', selector: row => row.weightTrainingDuration, sortable: true, },
    { name: 'Energy Balance Factor', selector: row => row.energyBalanceFactor, sortable: true, },
    { name: 'Training Days Per Week', selector: row => row.trainingDaysPerWeek, sortable: true, },
    // { name: 'Breakfast Items', selector: row => row.breakfastItems,  },
    { name: 'Lean Mass', selector: row => row.leanMass.toFixed(2), sortable: true, },
    { name: 'Katch McArdle BMR', selector: row => row.katchMcArdleBmr.toFixed(2), sortable: true, },
    { name: 'Training Energy Expenditure', selector: row => row.trainingEnergyExpenditure.toFixed(2),  sortable: true,},
    { name: 'Resting Energy Expenditure', selector: row => row.restingEnergyExpenditure.toFixed(2), sortable: true, },
    { name: 'Total EE on Training Days', selector: row => row.totalEeOnTrainingDays.toFixed(2), sortable: true, },
    { name: 'Maintenance Energy Intake', selector: row => row.maintenanceEnergyIntake.toFixed(2),sortable: true,  },
    { name: 'Average Target Energy Intake', selector: row => row.averageTargetEnergyIntake.toFixed(2), sortable: true, },
  ];
  

  const getGoalTitleById = (goalId) => {
    const goal = goals.find(g => g.id === goalId);
    return goal ? goal.title : '-';
  };
  
  const getDurationTitleById = (durationId) => {
    const duration = durations.find(d => d.id === durationId);
    return duration ? duration.title : '-';
  };
  


  const handleSearchChange = event => {
    setSearchText(event.target.value);
  };
  const filteredData = userDietInfoData.filter(item => {
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const field = item[key];
        if (typeof field === 'string' && field.toLowerCase().includes(searchText.toLowerCase())) {
          return true; // Include the item if the string field matches the search text
        } else if (typeof field === 'number' && field.toString().includes(searchText)) {
          return true; // Include the item if the number field matches the search text
        }
        // Add additional checks for other data types if needed
      }
    }
    return false; // Exclude the item if no field matches the search text
  });
  

  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="100%" mx="auto" p={6} borderRadius="lg" boxShadow="lg" bg="white">
      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <div className='container'>
          <div className="card">
            <div className="table-container">
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                <Input type="text" placeholder="Search" value={searchText} onChange={handleSearchChange} />
              </InputGroup>
              <br></br>
              {loading ? (
                <>
                  <Skeleton height="20px" mb="2" />
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} height="20px" mb="2" />
                  ))}
                </>
              ) : (
                <DataTable
                  title="User Diet Information"
                  columns={columns}
                  data={filteredData}
                  progressPending={loading}
                  pagination
                  paginationPerPage={20}
                  onChangePage={page => setCurrentPage(page)}
                  fixedHeader
                  striped
                  noHeader
                  customStyles={{
                    header: {
                      style: {
                        backgroundColor: 'red', // Change heading color
                        color: 'white', // Change text color
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </SimpleGrid>
    </Box>
  );
}
