import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Checkbox,
  CheckboxGroup,
  Button,
  Heading,
  Stack,
  Text
} from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

const WorkoutActivitiesStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const workoutActivities = [
    { id: 'sports', title: 'Any sport (badminton, swimming, etc)' },
    { id: 'walking_jogging', title: 'Walking / jogging' },
    { id: 'strength_training', title: 'Strength Training' },
    { id: 'yoga_body_weight', title: 'Yoga / Body weight training' },
    { id: 'dance_zumba', title: 'Dance / Zumba' }
  ];

  const [selectedActivities, setSelectedActivities] = useState(formData.workoutActivities || []);

  const handleActivitySelection = (activities) => {
    setSelectedActivities(activities);
    setFormData({ ...formData, workoutActivities: activities });
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1); // Move to the next step
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
  setDisplayheader(true);
   setQuestionNo(24)
 }, []);
 //for setting question no end
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10} className='bottomImage'>
      <Heading textAlign="left" as="h6" size='md' mb={1} color={'#b2b2b'}>
        What type of workout/activities do you add to this program?
      </Heading>
   
      
      <CheckboxGroup colorScheme="teal" value={selectedActivities} onChange={handleActivitySelection}>
        {workoutActivities.map((activity) => (
          <Checkbox className='default_checkbox1' key={activity.id} value={activity.id} pl={4} pr={4} pt={3} pb={3}>
            {activity.title}
          </Checkbox>
        ))}
      </CheckboxGroup>
      <Text fontSize="sm" textAlign="left" mb={6}>
       <b>Note:</b>  Strength training is important for all of us to do. It will be a part of your program - it is not only for body builders - basic for everyone to have. For us to have minimum muscle mass - strength
      </Text>
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={handleNext} isDisabled={selectedActivities.length === 0}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default WorkoutActivitiesStep;
