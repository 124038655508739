import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Box,
  Button,
  Text,
  useColorModeValue,
  Heading,
  Stack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Radio,
  RadioGroup
} from '@chakra-ui/react';

import looseWeightImg from "assets/img/fitness/loose_weight.png"; // Update the import paths as necessary
import gainWeightImg from "assets/img/fitness/gain_weight.png";
import bodyRecompositionImg from "assets/img/fitness/body_recomposition.png";
import improveHealthImg from "assets/img/fitness/improve_health.png";
import noomGraphicImg from "assets/img/fitness/noom_graphic.jpg"; // Add your noom card graphic image path here
import GoalStepBanner from './GoalStepBanner';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

export const goals = [
  { 
    id: 'weight_loss', 
    title: 'I want to lose Weight', 
    description: 'I want to lose 5 to 15 kgs (or more).',
    img: looseWeightImg
  },
  { 
    id: 'weight_gain', 
    title: 'I want to weight Gain',
    description: 'I want to build muscle & increase my overall body weight.',
    img: gainWeightImg
  },
  { 
    id: 'improve_health',
    title: 'I want to work on & improve my health issues',
    description: 'I want to improve my nutrition & overall health, while maintaining my current weight.',
    img: improveHealthImg
  },
  { 
    id: 'body_recomposition', 
    title: 'I want to get leaner',
    description: 'I want to lose around 5 to 15 kgs of body fat & gain muscle.',
    img: bodyRecompositionImg
  }
 
];

const GoalCard = ({ goal, isSelected, onSelect }) => {
  const bg = useColorModeValue('black.100', 'black.700');
  // const selectedBg = useColorModeValue('blue.100', 'blue.700');
  const selectedBg = 'black';
  const borderColor = isSelected ? 'blue.500' : 'gray.200';

  return (
    <Box
     width={'90%'}
      borderWidth="1px"
      borderColor={borderColor}
      bg={isSelected ? selectedBg : bg}
      p={4}
      m={4}
      borderRadius="md"
      shadow={isSelected ? 'md' : 'none'}
      _hover={{ shadow: 'md', borderColor: 'blue.500' }}
      transition="all 0.2s"
      cursor="pointer"
      display='flex'
      alignItems='center'
      onClick={() => onSelect(goal.id)}
      // className="goalselect_div"
      color={isSelected?"white":""}
      className='default_input'
    >
      {/* <Image src={goal.img} boxSize='50px' mr={4} /> */}
      <Radio value={goal.id}       className='default_radio_input1 default_input' mr={5} /> 
      <Box>
        <Text   fontWeight="bold">{goal.title}</Text>
        <Text fontSize="sm">{goal.description}</Text>
      </Box>
    </Box>
  );
};

const NoomCardModal = ({ onClose }) => {
  return (
    <Modal isOpen={true} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Image src={noomGraphicImg} alt="Noom results graph" mx="auto" />
          <Text fontSize="md" p={4}>
            Noom creates long-term results through habit and behavior change, not restrictive dieting.
          </Text>
          <Text fontSize="sm" color="gray.600">
            Scientific Reports - 78% of participants using Noom lost weight over a 6-month study.
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={onClose}>
            Got it!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const FitnessGoalSelectorStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedGoal, setSelectedGoal] = useState(formData.goal);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isGoalStepBanner, setIsGoalStepBanner] = useState(true); // State to toggle forms

  const handleSelectGoal = (goalId) => {
    setSelectedGoal(goalId);
    setFormData({ ...formData, goal: goalId });
  };

  const handleNext = () => {
    if (selectedGoal) {
      onOpen(); // Open the NoomCard modal
    }
  };

  const handleGotIt = () => {
    onClose(); // Close the NoomCard modal
    setCurrentStep(currentStep + 1); // Move to the next step
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(8)
}, []);
//for setting question no end
  return (<>

{/* {isGoalStepBanner ? (
        <GoalStepBanner onNext={() => setIsGoalStepBanner(false)}   onBack={handleBack} />
      ) : ( */}



    <VStack spacing={4} align="stretch"  pl={10} pr={10}
   className='bottomImage'
      
    
    
    >
<Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>What do you want to get out of this fitness program? </Heading>
     
     <br />

      {/* <Heading as="h3" size="lg" textAlign="center" color={'teal'}>What's your goal?</Heading> */}
      <RadioGroup onChange={handleSelectGoal} value={selectedGoal}> 
      {goals.map((goal) => (
       
        <GoalCard
          key={goal.id}
          goal={goal}
          isSelected={selectedGoal === goal.id}
          onSelect={handleSelectGoal}
        />
       
      ))}
      </RadioGroup>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        {/* <Button className='default_next_button'  colorScheme="teal" onClick={handleNext} isDisabled={!selectedGoal}> */}
        <Button className='default_next_button'  colorScheme="teal" onClick={handleGotIt} isDisabled={!selectedGoal}>
          Next
        </Button>
      </Stack>
      <br></br>
      <br></br>
      {isOpen && <NoomCardModal onClose={handleGotIt} />}
      
    </VStack>
  {/* )} */}
    </>
  );
};

export default FitnessGoalSelectorStep;
