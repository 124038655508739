import React, { useEffect, useState } from 'react';
import {
  VStack,
  Heading,
  Box,
  Select,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { durations } from 'views/diet/userDiet/Fields/TimeSinceIdealWeightStep';

 

const EditTimeSinceIdealWeightStep = ({ formData, setFormData }) => {
const [durations1,setDurations]=useState([])
  // Handler for duration change
  const handleDurationChange = (value) => {
    setFormData({ ...formData, timeSinceIdealWeight: value });
  };
  useEffect(() => {
    setDurations(durations);
  }, []);
  // No need to use useEffect to set initial value since it directly uses formData now

  return (
    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel>        How long has it been since you were at your ideal weight? </FormLabel>
      
      <Box>
        <Select 
          placeholder="Select duration" 
          value={formData.timeSinceIdealWeight || ''} // Ensuring the select shows the correct initial value
          onChange={(e) => handleDurationChange(e.target.value)}
        >
          {durations1.map((duration) => (
            <option key={duration.id} value={duration.id}>{duration.title}</option>
          ))}
        </Select>
      </Box>
</FormControl>
  );
};

export default EditTimeSinceIdealWeightStep;
