import React, { useEffect } from 'react';
import { FormControl, FormLabel, Input, Select, Button, Flex, Progress } from '@chakra-ui/react';
import { fetchUserDietInfo } from 'services/foodItemsService';
import EditHeightStep from './Fields/EditHeightStep';
import EditWeightStep from './Fields/EditWeightStep';
import EditLifestyleIssuesStep from './Fields/EditLifestyleIssuesStep';
import EditFitnessGoalSelectorStep from './Fields/EditFitnessGoalSelectorStep';
import EditTimeSinceIdealWeightStep from './Fields/EditTimeSinceIdealWeightStep';
import EditLifeEventsSelectorStep from './Fields/EditLifeEventsSelectorStep';
import EditIdealWeightInputStep from './Fields/EditIdealWeightInputStep';
import EditDietaryRestrictionsStep from './Fields/EditDietaryRestrictionsStep';
import EditPrepareYourMealStep from './Fields/EditPrepareYourMealStep';
import EditMealStep from './Fields/EditMealStep';
import EditActivityLevelStep from './Fields/EditActivityLevelStep';
import EditWorkoutStep from './Fields/EditWorkoutStep';
import EditAddWorkoutStep from './Fields/EditAddWorkoutStep';
import EditTrainingDaysStep from './Fields/EditTrainingDaysStep';
import { decrypt } from 'helpers/EncrDecr';

export const EditDietForm = ({ encId,formData,setFormData, handleChange, foodItems,setFoodItems,addOnItems,setAddOnItems, handleCheckboxChange, handleSubmit,isLoading ,setIsLoading}) => {
  

    const fetchUserDiet = (id) => {
      setIsLoading(true);
      fetchUserDietInfo(id)
          .then(res => {
            setIsLoading(false);
              if(res.statusCode===200){
                  
                  setFormData(res.data)
                   }
              console.log(res)
          })
          .catch(error => {
            setIsLoading(false);
              console.error('Error fetching food items:', error);
          });
  }; 



  useEffect(() => {
    let id=decrypt(encId);
console.log(encId)

    fetchUserDiet(id)
     
}, []);
  return (
    <div>
      <br /><div style={{textAlign:'center',width:'100%'}}>
      {isLoading&&<> <Progress style={{textAlign:'center',width:'100%'}} isIndeterminate  value={100} /> Fetching Data....Please Wait.......<br></br><br></br></>}



      </div>
      <Flex justifyContent="space-between" mb={8}>
        <FormControl flexBasis="48%">
          <FormLabel>Name</FormLabel>
          <Input type="text" name="name" value={formData.name} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
        </FormControl>

        <FormControl flexBasis="48%">
        <FormLabel>Gender</FormLabel>
        <Select
          name="gender"
          value={formData.gender}
          onChange={handleChange}  style={{pointerEvents:'none',background:'#F4F7FE'}}
        >
          <option value="M">Male</option>
          <option value="F">Female</option>
          <option value="O">Others</option>
        </Select>
      </FormControl>

        {/* <FormControl flexBasis="48%">
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={formData.email} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
        </FormControl> */}
      </Flex> 
      
      <Flex justifyContent="space-between" mb={8}>
     

      <FormControl flexBasis="48%">
          <FormLabel>Age</FormLabel>
          <Input type="number" name="age" value={formData.age} onChange={handleChange} />
        </FormControl>
  
        <FormControl flexBasis="48%">
          <FormLabel>Body fat percentage (%)</FormLabel>
          <Input type="number" name="bodyFatPercentage" value={formData.bodyFatPercentage} onChange={handleChange} />
        </FormControl>
      </Flex>

      
      
      <Flex justifyContent="space-between" mb={8}>
            <EditHeightStep flexBasis="48%" formData={formData} setFormData={setFormData} />
            <EditWeightStep flexBasis="48%" formData={formData} setFormData={setFormData} />
      </Flex>      
      <Flex justifyContent="space-between" mb={8}>
             <EditLifestyleIssuesStep flexBasis="48%" formData={formData} setFormData={setFormData} />
             <EditFitnessGoalSelectorStep flexBasis="48%" formData={formData} setFormData={setFormData} />
      </Flex> 
      <Flex justifyContent="space-between" mb={8}>
             <EditTimeSinceIdealWeightStep flexBasis="48%" formData={formData} setFormData={setFormData} />
             <EditLifeEventsSelectorStep flexBasis="48%" formData={formData} setFormData={setFormData} />
       </Flex>  
       
        <Flex justifyContent="space-between" mb={8}>
             <EditIdealWeightInputStep flexBasis="48%" formData={formData} setFormData={setFormData} />
             <EditDietaryRestrictionsStep flexBasis="48%" formData={formData} setFormData={setFormData} />
        </Flex>    
        
         <Flex justifyContent="space-between" mb={8}>
             <EditPrepareYourMealStep flexBasis="48%" formData={formData} setFormData={setFormData} />
             <EditActivityLevelStep flexBasis="48%" formData={formData} setFormData={setFormData} />
       </Flex>         
         <Flex justifyContent="space-between" mb={8}>
             <EditWorkoutStep flexBasis="48%" formData={formData} setFormData={setFormData} />
             <EditAddWorkoutStep flexBasis="48%" formData={formData} setFormData={setFormData} />
        </Flex> 
         <Flex justifyContent="space-between" mb={8}>
             <EditTrainingDaysStep flexBasis="48%" formData={formData} setFormData={setFormData} />

             <FormControl flexBasis="48%">
          <FormLabel>Energy balance factor</FormLabel>
          <Input type="number" name="energyBalanceFactor" value={formData.energyBalanceFactor} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
        </FormControl>
         </Flex>  
         
    





      <Flex justifyContent="space-between" mb={8}>
        <FormControl flexBasis="48%">
          <FormLabel>Physical Activity Factor</FormLabel>
          <Input type="number" name="physicalActivityFactor" value={formData.physicalActivityFactor} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
        </FormControl>
        <FormControl flexBasis="48%">
          <FormLabel>Thermic Effect of Food factor</FormLabel>
          <Input type="number" name="thermicEffectOfFoodFactor" value={formData.thermicEffectOfFoodFactor} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
        </FormControl>
      </Flex>



      <Flex justifyContent="space-between" mb={8}>
              <EditMealStep flexBasis="48%" formData={formData} setFormData={setFormData} />
      </Flex>

      <Flex justifyContent="space-between" mb={8}>
        {/* <FormControl flexBasis="48%">
          <FormLabel>Duration of weight training session (Min)</FormLabel>
          <Input type="number" name="weightTrainingDuration" value={formData.weightTrainingDuration} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
        </FormControl> */}
        
      </Flex>
      {/* <FormControl mb={8}>
        <FormLabel>Number of training days per week (#Days)</FormLabel>
        <Select
          name="trainingDaysPerWeek"
          value={formData.trainingDaysPerWeek}
          onChange={handleChange}  style={{pointerEvents:'none',background:'#F4F7FE'}}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
        </Select>
      </FormControl> */}


      {/* <Flex justifyContent="space-between" mb={8}>
        <FormControl flexBasis="48%">
          <FormLabel>Big Meals</FormLabel>
          <Select
            name="bigMeals"
            value={formData.bigMeals}
            onChange={handleChangeMeals}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </Select>
        </FormControl>
        <Flex direction="column" justifyContent="space-between" flexBasis="48%">
              {formData.bigMeals > 0 && renderBigMealsMultiSelects()}
              {formData.bigMeals > 0 &&  renderAddOnMealsMultiSelects()}
        </Flex>

      </Flex> */}
      
      
      {/* <Flex justifyContent="space-between" mb={8}>
       
        <FormControl flexBasis="48%">
          <FormLabel>Small Meals</FormLabel>
          <Select
            name="smallMeals"
            value={formData.smallMeals}
            onChange={handleChangeMeals}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
          </Select>
        </FormControl>
        <Flex direction="column" justifyContent="space-between" flexBasis="48%">
        {formData.smallMeals > 0 && renderSmallMeansMultiSelects()}
        </Flex>

      </Flex> */}




      {/* <div>
        <Heading as='h5' size='md' style={{ marginTop: '10px', marginBottom: '10px' }}>Breakfast Items</Heading>
        <CheckboxGroup colorScheme="green" onChange={handleCheckboxChange} value={formData.breakfastItems}>
          {foodItems.map(item => (
            <Checkbox key={item.id} value={item.id + ""} style={{ marginRight: '10px' }}>
              {item.subHead}
            </Checkbox>
          ))}
        </CheckboxGroup>
      </div> */}

      <br />

      <Button type="submit" colorScheme="blue" isLoading={isLoading} loadingText="Sending Data...">Submit</Button>
    </div>
  );
};
