import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdFastfood,
  MdLock,
} from "react-icons/md";

// Admin Imports
import DietDashboard from "views/diet/Employees";
import ApproveLeave from "views/diet/ApproveLeave";
import { Home } from "views/diet/Home";
import { FaHome, FaRegEdit, FaRegListAlt, FaUserEdit, FaUserSlash, FaUsers } from "react-icons/fa";
import { IoFastFood, IoFastFoodSharp, IoList, IoReceiptOutline, IoReceiptSharp } from "react-icons/io5";
import FoodForm from "views/diet/Food/FoodForm";
import FoodList from "views/diet/Food/FoodList";
import { RiUserSearchLine } from "react-icons/ri";
import UserDietInfoList from "views/diet/Users/UserDietInfoList";
import { EditUserDietInfo } from "views/diet/Users/EditUserDietInfo";
import EditFoodForm from "views/diet/Food/EditFoodForm";
import IngredientsForm from "views/diet/Ingredients/IngredientsForm";
import IngredientList from "views/diet/Ingredients/IngredientsList";
import EditIngredientForm from "views/diet/Ingredients/EditIngredientsForm";
 
// Auth Imports

const routes = [
  
    {
    name: "Home",
    layout: "/diet",
    path: "/home",
    icon: <FaHome as={MdLock} width='20px' height='20px' color='inherit' />,
    component: Home,
    display:true
  } 
  , 
   {
    name: "Ingredients Data",
    layout: "/diet",
    path: "/ingredientsList",
    icon: <FaRegListAlt as={MdLock} width='20px' height='20px' color='inherit' />,
    component: IngredientList, 
    display:true
  },
  
  {
    name: "Ingredients Entry",
    layout: "/diet",
    path: "/ingredientsData",
    icon: <IoReceiptOutline as={MdLock} width='20px' height='20px' color='inherit' />,
    component: IngredientsForm, 
    display:true
  },
    
  {
    name: "Edit Food Data ",
    layout: "/diet",
    path: "/editIngredientData/:id",
    icon: <FaUserEdit as={MdLock} width='20px' height='20px' color='inherit' />,
    component: EditIngredientForm,
    display:false
    },



  
  {
    name: "Food Data",
    layout: "/diet",
    path: "/foodList",
    icon: <MdFastfood as={MdLock} width='20px' height='20px' color='inherit' />,
    component: FoodList, 
    display:true
  }, 
  {
    name: "Food Data Enty",
    layout: "/diet",
    path: "/foodData",
    icon: <IoFastFood as={MdLock} width='20px' height='20px' color='inherit' />,
    component: FoodForm,
    display:true
  },  
  {
    name: "Users ",
    layout: "/diet",
    path: "/userDiets",
    icon: <FaUsers as={MdLock} width='20px' height='20px' color='inherit' />,
    component: UserDietInfoList,
    display:true

    }, 
     {
    name: "Edit User Diet ",
    layout: "/diet",
    path: "/editUserDiet/:id",
    icon: <FaUserEdit as={MdLock} width='20px' height='20px' color='inherit' />,
    component: EditUserDietInfo,
    display:false
    },  
    
    
    {
    name: "Edit Food Data ",
    layout: "/diet",
    path: "/editFoodData/:id",
    icon: <FaUserEdit as={MdLock} width='20px' height='20px' color='inherit' />,
    component: EditFoodForm,
    display:false
    },
];

export default routes;
