import React from 'react';
import {
  VStack,
  Select,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const EditTrainingDaysStep = ({ formData, setFormData }) => {
  // Directly handle changes to the number of training days
  const handleTrainingDaysChange = (event) => {
    const { value } = event.target;
    // Update the form data directly with the new value
    setFormData({ ...formData, trainingDaysPerWeek: value });
  };

  return (
    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
      <FormLabel>No of training days per week?</FormLabel>
      <Select 
        onChange={handleTrainingDaysChange} 
        value={formData.trainingDaysPerWeek || ''} // Directly use formData for the Select value
        placeholder="Select number of days"
      >
        <option value="3">Min 3 days</option>
        <option value="4">4 days</option>
        <option value="5">5 days</option>
        <option value="6">6 days</option>
        {/* Add more options as needed */}
      </Select>
    </FormControl>
  );
};

export default EditTrainingDaysStep;
