import { isLoggedIn } from 'helpers/loginHelper';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
 
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn() ? <Component {...props} /> : <Redirect to="/auth" />
    }
  />
);

export default ProtectedRoute;
