import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Box,
  SimpleGrid,
  Select,
  CheckboxGroup,
  Stack,
  Checkbox,
  Text,
  Image,
  Switch,
} from '@chakra-ui/react';
import { fetchFoodHeads } from 'services/foodItemsService';
import { fetchFoodStates } from 'services/foodItemsService';
import { fetchFoodMeasures } from 'services/foodItemsService';
import Swal from 'sweetalert2';
import { saveFoodData } from 'services/foodItemsService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { doLogout } from 'helpers/loginHelper';
import { fetchfoodAllergies } from 'services/foodItemsService';
import Multiselect from 'multiselect-react-dropdown';
import { fetchAllFoodIngredient } from 'services/foodItemsService';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';
import { uploadFoodIcon } from 'services/foodItemsService';

const FoodForm = () => {

    const initialFormData = {
        subHead: '',
        head: '',
        state: '',
        quantity: '',
        measured: '',
        protein: '',
        carbs: '',
        fats: '',
        calories: '',
        category: '',
        maxCalories: '',
        minCalories: '',
        allergies:[],
        ingredients:[],
        ingredientPercentages:{},
        preparationSteps: [''], // Start with an empty step
         foodIconFileUrl: '', // URL for the image preview
         foodIconPath:'',
         isDisplay: false,
         dishType: '',
      };
      const history = useHistory();

      const [isLoading, setIsLoading] = useState(false); // New state for loading indication

    const [foodHeads, setFoodHeads] = useState([]);
    const [foodStates, setFoodStates] = useState([]);
    const [foodMeasures, setFoodMeasures] = useState([]);
    const [allergiesList, setAllergiesList] = useState([]);
    const [foodIngredientData, setFoodIngredientData] = useState([]);

  const [formData, setFormData] = useState(initialFormData);
  const [foodIconFile, setFoodIconFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        // Check if the file size is greater than 1MB
        if (file.size > 1048576) { // 1MB = 1 * 1024 * 1024 = 1,048,576 bytes
            // Alert the user and exit the function if the file is too large
            Swal.fire({
                icon: 'error',
                title: 'File Too Large',
                text: 'Please select a file smaller than 1MB.',
            });
            // Optionally, clear the selected file
            e.target.value = '';
            return; // Exit the function
        }

        setFoodIconFile(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setFormData(prev => ({
                ...prev,
                foodIconFileUrl: reader.result,
             }));
        };
        reader.readAsDataURL(file);
    }
};




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData)
  };
 // Handle selection of allergies
 const handleAllergyChange = (selected) => {
   setFormData({ ...formData, allergies: selected });
};
const handleIngredientChange = (selected) => {
  // Create a new object for storing updated ingredient percentages
  const updatedPercentages = {};

  // Transfer over the percentages for the ingredients that remain selected
  selected.forEach((ingredientId) => {
     const existingPercentage = formData.ingredientPercentages[ingredientId];
    
    // If there's an existing percentage for this ingredient, use it; otherwise, initialize to an empty string
    updatedPercentages[ingredientId] = existingPercentage !== undefined ? existingPercentage : '';
  });

  // Update the formData state with the new list of selected ingredients and their percentages
  setFormData({
    ...formData,
    ingredients: selected,
    ingredientPercentages: updatedPercentages
  });
};

const handlePercentageChange = (ingredientId, event) => {
  const newPercentage = event.target.value;
  // Validate the percentage input here if needed (e.g., not allowing more than 100%)

  // Update the ingredientPercentages state
  setFormData({
      ...formData,
      ingredientPercentages: {
          ...formData.ingredientPercentages,
          [ingredientId]: newPercentage,
      },
  });
};

const handleStepChange = (index, event) => {
  const newSteps = [...formData.preparationSteps];
  newSteps[index] = event.target.value;
  setFormData({ ...formData, preparationSteps: newSteps });
};

const addStep = () => {
  setFormData({ ...formData, preparationSteps: [...formData.preparationSteps, ''] });
};

const removeStep = (index) => {
  const newSteps = [...formData.preparationSteps];
  newSteps.splice(index, 1);
  setFormData({ ...formData, preparationSteps: newSteps });
};









const fetchFoodAllHeads = () => {
    fetchFoodHeads()
        .then(res => {
            setFoodHeads(res);
        })
        .catch(error => {
            console.error('Error fetching food items:', error);
        });
};
 const fetchFoodAllStates = () => {
    fetchFoodStates()
        .then(res => {
            setFoodStates(res);
        })
        .catch(error => {
            console.error('Error fetching food items:', error);
        });
};  
const fetchFoodAllMeasures = () => {
    fetchFoodMeasures()
        .then(res => {
            setFoodMeasures(res);
        })
        .catch(error => {
            console.error('Error fetching food items:', error);
        });
}; 
const fetchfoodAllAllergies = () => {
  fetchfoodAllergies()
    .then(data => {
      setAllergiesList(data);
    })
    .catch(error => {
      console.error('Error fetching allergies:', error);
     });
    }
    
const fetchfoodIngredientData = () => {
  fetchAllFoodIngredient()
    .then(data => {
      setFoodIngredientData(data);
    })
    .catch(error => {
      console.error('Error fetching allergies:', error);
     });
    }




useEffect(() => {
    fetchFoodAllHeads();
    fetchFoodAllStates();
    fetchFoodAllMeasures();
    fetchfoodAllAllergies();
    fetchfoodIngredientData();
}, []);


const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    const errors = {};

    // Check if sub_head is empty
    if (!formData.subHead.trim() || formData.subHead.trim()==='0') {
      errors.subHead = 'Meal Name is required';
  }

  // Check if head is empty
  if (!formData.head.trim() || formData.head.trim()==='0') {
      errors.head = 'Meal Type is required';
  }

  // Check if state is empty
  if (!formData.state.trim() || formData.state.trim()==='0') {
      errors.state = 'State is required';
  }
    // Check if dishType is empty
    if (!formData.dishType) {
      errors.dishType = 'Dish Type is required';
  }
  // Check if quantity is empty
   
  if (!formData.quantity) {
      errors.quantity = 'Quantity is required';
    } else if (isNaN(formData.quantity)) {
      errors.quantity = 'Quantity  must be a number';
    }


  // Check if measured is empty
  if (!formData.measured.trim() || formData.measured.trim()==='0') {
      errors.measured = 'Measured is required';
  }


  if (!formData.protein) {
      errors.protein = 'Protein is required';
    } else if (isNaN(formData.protein)) {
      errors.protein = 'Protein  must be a number';
    }

  if (!formData.carbs) {
      errors.carbs = 'Carbs is required';
    } else if (isNaN(formData.carbs)) {
      errors.carbs = 'Carbs  must be a number';
    }

    
   if (!formData.fats) {
      errors.fats = 'Fats is required';
    } else if (isNaN(formData.fats)) {
      errors.fats = 'Fats  must be a number';
    }      
   if (!formData.calories) {
      errors.calories = 'Calories is required';
    } else if (isNaN(formData.calories)) {
      errors.calories = 'Calories  must be a number';
    }
 


     // Check if calories is empty
  if (!formData.category.trim() || formData.category.trim()==='0') {
      errors.category = 'Category is required';
  } 

  if (!formData.minCalories) {
    errors.minCalories = 'Min Calories is required';
  } else if (isNaN(formData.minCalories)) {
    errors.minCalories = 'Min Calories  must be a number';
  }



  if (!formData.maxCalories) {
      errors.maxCalories = 'Max Calories is required';
    } else if (isNaN(formData.maxCalories)) {
      errors.maxCalories = 'Max Calories  must be a number';
    }


  // Calculate the sum of ingredient percentages
  const sumOfPercentages = Object.values(formData.ingredientPercentages).reduce((sum, value) => {
    return sum + (value ? parseFloat(value) : 0);
  }, 0);

  // Check if the sum of ingredient percentages is 100
  if (sumOfPercentages !== 100) {
    errors.ingredientPercentages = 'The sum of ingredient percentages must equal 100.';
  }



    // Validate preparation steps: ensure none are empty or just whitespace
    formData.preparationSteps.forEach((step, index) => {
      if (!step.trim()) {
          errors[`step${index}`] = `Step ${index + 1} cannot be empty.`;
      }
  });

  if (!foodIconFile) {
    errors.foodIconPath = "Please select a file to upload.";
   
}




    // Add similar validation checks for other fields...

    // If there are errors, display alert with error messages
    if (Object.keys(errors).length > 0) {
        let errorMessage = '<ul>';
        Object.values(errors).forEach(error => {
            errorMessage += `<li>${error}</li>`;
        });
        errorMessage += '</ul>';
        
        Swal.fire({
            icon: 'error',
            title: 'Required Fields!',
            html: errorMessage
        });
        return;
    }

    // Proceed with form submission if no errors
    console.log('Form submitted successfully:', formData);

// Start loading
setIsLoading(true);

try {
    // Upload the food icon and get the response
    const uploadResponse = await uploadFoodIcon(foodIconFile);
    if (uploadResponse.statusCode === 200) {
        // Update formData with the path received from the upload response
        const updatedFormData = {
            ...formData,
            foodIconPath: uploadResponse.data // Assuming server returns the file path in `data`
        };

        // Save the food data with the updated form data
        const saveResponse = await saveFoodData(updatedFormData);
        if (saveResponse.statusCode === 200) {
           setFormData(initialFormData);
           setFoodIconFile(null);
           document.getElementById('fileInput').value = '';

            Swal.fire({
                icon: 'success',
                title: 'Successfully added Meal Item',
            }).then(result => {
                if (result.isConfirmed || result.isDismissed) {
                    history.push('/diet/foodData');
                }
            });
        } else {
            throw new Error(saveResponse.message || 'Failed to save food data');
        }
    } else {
        throw new Error(uploadResponse.message || 'Failed to upload file');
    }
} catch (error) {
    console.error('Error during form submission:', error);
    if (error.response && error.response.status === 401) {
              // Handle 401 Unauthorized error
              
              Swal.fire({
                  icon: 'error',
                  title: 'Please log in again.',
              }).then((result) => {
                  // Check if the user clicked the "OK" button on the success message
                  if (result.isConfirmed || result.isDismissed) {  
                      doLogout() 
                      history.push('/logout');
                   }
              });
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: error.message || 'An error occurred during form submission.',
            });
            }

                
     
} finally {
    setIsLoading(false); // Stop loading irrespective of the outcome
}
 
        // setIsLoading(true)

        // await uploadFoodIcon(foodIconFile).then(res=>{
        //   console.log(res)

        //   if(res.statusCode===200){
        //     setFormData({
        //       ...formData,
        //       foodIconPath: res.data
        //     });

        //   }else{

        //     Swal.fire({
        //       icon: 'error',
        //       title: res.message,
        //     }).then((result) => {
        //         // Check if the user clicked the "OK" button on the success message
        //         if (result.isConfirmed || result.isDismissed) {          
        //             setIsLoading(false)
        //         }
        //     });
        //   }
        // })

        
       





    //     saveFoodData(formData).then(res => {

    // if(res.statusCode===200){
    //     setFormData(initialFormData);
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Successfully added Meal Item',
    //     }).then((result) => {
    //         // Check if the user clicked the "OK" button on the success message
    //         if (result.isConfirmed || result.isDismissed) {
    //             // Redirect to the '/diet/foodData' page
    //             setIsLoading(false)
    //             history.push('/diet/foodData');

    //         }
    //     });
    // }else{
    //     Swal.fire({
    //         icon: 'error',
    //         title: res.message,
    //     }).then((result) => {
    //         // Check if the user clicked the "OK" button on the success message
    //         if (result.isConfirmed || result.isDismissed) {          
    //             setIsLoading(false)
    //         }
    //     });
    // }
    //     setIsLoading(false)
       
    //     })
    //     .catch(error => {
    //       console.error('Error sending diet info:', error);
    //       if (error.response && error.response.status === 401) {
    //         // Handle 401 Unauthorized error
            
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Please log in again.',
    //         }).then((result) => {
    //             // Check if the user clicked the "OK" button on the success message
    //             if (result.isConfirmed || result.isDismissed) {  
    //                 doLogout() 
    //                 history.push('/logout');
    //              }
    //         });
    //         // Perform logout action or redirect to login page
    //       } else {
    //         console.error('Error fetching food items:', error);
    //       }
    //       setIsLoading(false)
    //     });

};




  return (
   

    <Box mt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="80%" mx="auto"    p={6} borderRadius="lg" boxShadow="lg" bg="white">
    <SimpleGrid
      
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
  
         
    <form onSubmit={handleSubmit}>
      <Flex direction="column">
      <Flex>
      <FormControl mt={4}>
                <FormLabel>Food Icon</FormLabel>
                <Input type="file" p={1} accept="image/*"  id="fileInput"  onChange={handleFileChange} />
             
            </FormControl>  
            
             <FormControl mt={4} >
                 
                {formData.foodIconFileUrl && (
                    <Box mt={4}  direction='column' alignContent={'center'}>
                        <Image 
                        src={formData.foodIconFileUrl}   
                       
                    alt="Preview" ml={10} mb={5} p={1} maxW="100px" maxH="100px"  
                     className='previewImage'/>
                    </Box>
                )}
             
            </FormControl>


      </Flex>
        <Flex>
          <FormControl id="subHead" mr={4}>
            <FormLabel>Meal Name</FormLabel>
            <Input
              type="text"
              name="subHead"
              value={formData.subHead}
              onChange={handleChange}
               
            />
          </FormControl>

          <FormControl id="head">
            <FormLabel>Meal Type</FormLabel>
            <Select
            name="head"
            value={formData.head}
            onChange={handleChange}
             
          >
                     <option key='0' value='0'>--Select--</option>

            {foodHeads.map((foodHead) => (
          <option key={foodHead.headName} value={foodHead.headName}>
            {foodHead.headName}
          </option>
        ))}
          </Select>
          </FormControl>
        </Flex>
        <Flex>
            <FormControl id="dishType" mr={4}>
                <FormLabel>Dish Type</FormLabel>
                <Select
                  name="dishType"
                  value={formData.dishType}
                  onChange={handleChange}
                  placeholder="--Select--"
                >
                  <option value="VEG_MAIN_COURSE">VEG MAIN COURSE</option>
                  <option value="TIFFINS">TIFFINS</option>
                  <option value="NON_VEG_MAIN_COURSE">NON-VEG MAIN COURSE</option>
                </Select>
              </FormControl>

              <FormControl display="flex" alignItems="center" mt={4}>
                    <FormLabel htmlFor="isDisplay" mb="0">
                        Display Food
                    </FormLabel>
                    <Switch
                        id="isDisplay"
                        isChecked={formData.isDisplay}
                        onChange={() => setFormData({ ...formData, isDisplay: !formData.isDisplay })}
                        colorScheme="teal"
                    />
                </FormControl>
            </Flex>
        <Flex mt={4}>
          <FormControl id="state" mr={4}>
            <FormLabel>State</FormLabel>
    


        <Select
            name="state"
            value={formData.state}
            onChange={handleChange}
             
          >
           <option key='0' value='0'>--Select--</option>
            {foodStates.map((foodState) => (
            <option key={foodState.stateName} value={foodState.stateName}>
                {foodState.stateName}
            </option>
             ))}
          </Select>



          </FormControl>

          <FormControl id="quantity">
            <FormLabel>Quantity</FormLabel>
            <Input
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
               
            />
          </FormControl>
        </Flex>

        <Flex mt={4}>
          <FormControl id="measured" mr={4}>
            <FormLabel>Measured</FormLabel>
           

<Select
            name="measured"
            value={formData.measured}
            onChange={handleChange}
             
          >
           <option key='0' value='0'>--Select--</option>
            {foodMeasures.map((foodMeasure) => (
            <option key={foodMeasure.measureName} value={foodMeasure.measureName}>
                {foodMeasure.measureName} 
            </option>
             ))}
          </Select>



          </FormControl>

          <FormControl id="protein">
            <FormLabel>Protein</FormLabel>
            <Input
              type="number"
              name="protein"
              value={formData.protein}
              onChange={handleChange}
               
            />
          </FormControl>
        </Flex>

        <Flex mt={4}>
          <FormControl id="carbs" mr={4}>
            <FormLabel>Carbs</FormLabel>
            <Input
              type="number"
              name="carbs"
              value={formData.carbs}
              onChange={handleChange}
              
            />
          </FormControl>

          <FormControl id="fats">
            <FormLabel>Fats</FormLabel>
            <Input
              type="number"
              name="fats"
              value={formData.fats}
              onChange={handleChange}
               
            />
          </FormControl>
        </Flex>

        <Flex mt={4}>
          <FormControl id="calories" mr={4}>
            <FormLabel>Calories</FormLabel>
            <Input
              type="number"
              name="calories"
              value={formData.calories}
              onChange={handleChange}
               
            />
          </FormControl> 
           <FormControl id="category" mr={4}>
            <FormLabel>Category</FormLabel>
          


            <Select
                 name="category"
                 value={formData.category}
                 onChange={handleChange}
                  
                >
                <option key='0' value='0'>--Select--</option>
                <option key='BIGMEAL' value='BIGMEAL'>BIGMEAL</option>
                <option key='ADDON' value='ADDON'>ADDON</option>
                    
                </Select>


          </FormControl>

          {/* <FormControl id="estimatedOption1Quantity">
            <FormLabel>Estimated Option 1 Quantity</FormLabel>
            <Input
              type="number"
              name="estimated_option1quantity"
              value={formData.estimated_option1quantity}
              onChange={handleChange}
              required
            />
          </FormControl> */}
        </Flex>

        <Flex mt={4}>
        
          

          <FormControl id="minCalories" mr={4}>
            <FormLabel>Min Calories</FormLabel>
            <Input
              type="number"
              name="minCalories"
              value={formData.minCalories}
              onChange={handleChange}
               
            />
          </FormControl> 

          <FormControl id="maxCalories" mr={4}>
            <FormLabel>Max Calories</FormLabel>
            <Input
              type="number"
              name="maxCalories"
              value={formData.maxCalories}
              onChange={handleChange}
               
            />
          </FormControl>  
          
      




          </Flex>

          <Flex mt={4}>
          <FormControl id="allergies" mr={4}>
          <FormLabel>Ingredients</FormLabel>
          <Multiselect
            options={foodIngredientData} // Options to display in the dropdown
            selectedValues={formData.ingredients.map(ingredientId => foodIngredientData.find(ingredient => ingredient.id.toString() === ingredientId))} // Convert selected allergy IDs back to the full objects expected by Multiselect
            onSelect={(selectedList) => handleIngredientChange(selectedList.map(item => item.id.toString()))} // Update form state on item select
            onRemove={(selectedList) => handleIngredientChange(selectedList.map(item => item.id.toString()))} // Update form state on item remove
            displayValue="ingredientName" // Property to display in the dropdown options
            placeholder="Select Ingredients..."
            style={{ chips: { background: 'teal' },   }}
          />
        </FormControl>
        

        <FormControl id="allergies" mr={4}>
  <FormLabel>Allergies</FormLabel>
  <Multiselect
    options={allergiesList} // Options to display in the dropdown
    selectedValues={formData.allergies.map(allergyId => allergiesList.find(allergy => allergy.id.toString() === allergyId))} // Convert selected allergy IDs back to the full objects expected by Multiselect
    onSelect={(selectedList) => handleAllergyChange(selectedList.map(item => item.id.toString()))} // Update form state on item select
    onRemove={(selectedList) => handleAllergyChange(selectedList.map(item => item.id.toString()))} // Update form state on item remove
    displayValue="allergyName" // Property to display in the dropdown options
    placeholder="Select allergies..."
    style={{ chips: { background: 'teal' },   }}
  />
</FormControl>


          </Flex>
          <div mt={4} >
{/* Render the percentage fields next to the selected ingredients */}
{formData.ingredients.map((ingredientId) => {
  // Find the ingredient by id
  const ingredient = foodIngredientData.find(item => item.id.toString() === ingredientId);
  return (
    <Flex key={ingredientId} mt={4} align="center" >
      <Text width="175px" mr={2}>{ingredient ? ingredient.ingredientName : "Unknown"} - </Text>
      <Input
        type="number"
        placeholder="Percentage"
        value={formData.ingredientPercentages[ingredientId] || ''}
        onChange={(e) => handlePercentageChange(ingredientId, e)}
        width="105px" // You can adjust the width as needed
      />
      <Text ml={2}>%</Text>
       
    </Flex>
  );
})}
<Flex mt={4}>
    <Text mr={2}>Total Percentage:</Text>
    <Text>{
      Object.values(formData.ingredientPercentages).reduce((sum, value) => {
    return sum + (value ? parseFloat(value) : 0);
  }, 0)

    }%</Text>
  </Flex>
</div>

<Box mt={4}>
  <FormLabel>Preparation Steps</FormLabel>
  {formData.preparationSteps.map((step, index) => (
    <Flex key={index} align="center" mb={2}>
      <Input
        placeholder={`Step ${index + 1}`}
        value={step}
        onChange={(e) => handleStepChange(index, e)}
        mr={2}
      />
      <FaTrash  onClick={() => removeStep(index)} ml={2}   style={{ cursor: 'pointer' ,color:'red'}}> </FaTrash>
      {/* {errors[`step${index}`] && <Text color="crimson">{errors[`step${index}`]}</Text>} */}

    </Flex>
  ))}
  <Button onClick={addStep} mt={2} colorScheme='linkedin'>
        Add Step <FaPlusCircle      style={{ cursor: 'pointer' ,color:''}}> </FaPlusCircle>

  </Button>
</Box>

 
      </Flex>
      <Button mt={4} colorScheme="teal" type="submit"   isLoading={isLoading} loadingText="Sending Data...">
        Submit
      </Button>
    </form>
    </SimpleGrid>
    </Box>
  );
};

export default FoodForm;
