import React from 'react';
import { Box, Image, Text, Badge, Heading } from '@chakra-ui/react';

const AddOnMealSelect = ({ meals, selectedMeals, onMealToggle ,heading }) => {
  // Assuming meals is an array of meal objects
  // Divide meals into four rows
  const rows = new Array(4).fill().map((_, i) =>
    meals.slice(i * meals.length / 4, (i + 1) * meals.length / 4)
  );
  const previewUrl = process.env.REACT_APP_PREVIEW_URL;

  return (
    <Box overflowX="scroll" whiteSpace="nowrap" width="100%" padding="10px">
 {heading && (
        <Box mb={4}>
          <Heading size="md">{heading}</Heading> {/* Use the heading prop */}
        </Box>
      )}

      {rows.map((rowMeals, rowIndex) => (
        <Box key={rowIndex} display="flex" flexDirection="row" mb="10px">
          {rowMeals.map((meal) => (
            <Box
              key={meal.id}
              onClick={() => onMealToggle(meal)}
              minWidth="120px"
              alignItems={'center'}
              // border="1px solid #ddd"
              borderRadius="8px"
              display="inline-block"
              marginRight="10px"
              position="relative"
              bg={selectedMeals.some(selectedMeal => selectedMeal.id === meal.id) ? 'teal.100' : 'white'}
              boxShadow={selectedMeals.some(selectedMeal => selectedMeal.id === meal.id) ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0'}
              cursor="pointer"
            >
              <Image
                src={previewUrl + meal.foodIconPath} // Replace with your image property
                alt={meal.subHead}
                objectFit="cover"
                width="full"
                align={'center'}
                height="100px"
                borderRadius="8px"
        border="1px solid #ddd"
              />
              {selectedMeals.some(selectedMeal => selectedMeal.id === meal.id) && (
                <Badge position="absolute" top="1" right="1" colorScheme="green">
                  Selected
                </Badge>
              )}
              <Text p="2" isTruncated textAlign="center">
                {meal.subHead}
              </Text>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default AddOnMealSelect;
