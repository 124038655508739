import React, { useContext, useEffect } from 'react';
import { Box, Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import coach_vatsav_logo from 'assets/img/couchVastavLogo.png';
 import goal from 'assets/img/goal.png'; // Replace with your actual image path
import { AppContext } from 'contexts/AppContext';

const GoalCard = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Hide header based on AppContext
  const { setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(false);
  }, [setDisplayheader]);
  return (
  <Flex
    direction="column"
    align="center"
    justify="center"
  
    bg="#161616"
    color="white"
     
    p={10}
    minH="80vh"
  >
   

    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mb={8}>
      <Image
        src={goal} // Replace with your actual logo path
        alt="fruitsImage"
        mb={4}
         align={'center'}
         width={'60%'}
      />
    </Box>

    <Box     textAlign="left">
      <Text fontSize="xl" fontWeight="bold"   mb={4}>
       Set Your Goal
      </Text>
      <Flex align="center" justify="center" mb={4} display={'flex'} justifyContent={'space-between'}>
        <Text  >
          Let us get to know what you want to get out of this program
        </Text>
      
      </Flex>
      
     
      
      <Flex mt={2} direction="row" justify="center" align="center">
        <Divider  height={'5px'} backgroundColor={'#ffbe4a'} borderColor="#ffbe4a" width="25%"  m={1} borderRadius={10}/>
        <Divider height={'5px'} backgroundColor={'#ffbe4a'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
        <Divider height={'5px'} backgroundColor={'#ffffff'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
        <Divider height={'5px'} backgroundColor={'#ffffff'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
      </Flex>
    </Box>
 
    <Flex mt={8} mb={20} width="100%" justifyContent="space-around" flexDirection={'row'} alignItems={'center'}>
     <Button className='default_back_button' bg="gray.700" _hover={{ bg: 'gray.600' }} onClick={backStep}>
        Back
      </Button>  
      <Button className='default_next_button' bg="blue.400" _hover={{ bg: 'blue.300' }} onClick={nextStep}>
        Next
      </Button>
    </Flex>
  </Flex>
)};

export default GoalCard;
