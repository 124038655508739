import axios from "axios"
import { getToken, getUserId } from "./loginHelper";
export const BASE_URL=process.env.REACT_APP_API_URL;

//  export const BASE_URL='http://200.69.21.187:8080/dietplan/api/v1'
//export const BASE_URL='http://localhost:8080/api/v1'
 
export const myAxios=axios.create({
    baseURL:BASE_URL
});

export const myPrivateAxios=axios.create({
    baseURL:BASE_URL
});




myPrivateAxios.interceptors.request.use((config)=>{
    const token=getToken();
    const userId = getUserId();
    console.log('userId: ' + userId);
    console.log(token);
    if (token) {
      config.headers.authToken=`${token}`;
      config.headers.userId = `${userId}`;
      return config;
    }
  }, (error)=>Promise.reject(error));