import React from 'react';
import {
  VStack,
  Button,
  Heading,
  Select,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

// const activityLevels = [
//   { id: 'very-light', title: 'Very Light', description: 'Sitting most of the day (example: desk job).' },
//   { id: 'light', title: 'Light', description: 'A mix of sitting, standing, and light activity (example: teacher).' },
//   { id: 'moderate', title: 'Moderate', description: 'Continuous gentle to moderate activity (example: restaurant server).' },
//   { id: 'heavy', title: 'Heavy', description: 'Strenuous activity throughout the day (example: construction work).' },
// ];
const activityLevels = [
  { id: 'mostly-sitting', title: 'Mostly Sitting', description: 'Spend majority of the day sitting down (eg desk job)' },
  { id: 'some-movement', title: 'Some Movement', description: 'Move around occasionally throughout the day (eg. a teacher)' },
  { id: 'very-active', title: 'Very Active', description: 'Often on your feet & moving around (eg. a shop owner)' },
  { id: 'extremely-active', title: 'Extremely Active', description: 'Engage in vigorous physical activity throughout the day (eg. atheletes or fitness enthusiast)' },
];
const EditActivityLevelStep = ({ formData, setFormData }) => {

  const handleActivityLevelChange = (e) => {
    setFormData({ ...formData, activityLevel: e.target.value });
  };

  return (
    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel> Describe how active you are each day.?</FormLabel>
   
      <Select
        placeholder="Select your activity level"
        value={formData.activityLevel || ''}
        onChange={handleActivityLevelChange}
      >
        {activityLevels.map((level) => (
          <option key={level.id} value={level.id}>{level.title} - {level.description}</option>
        ))}
      </Select>
 </FormControl>
  );
};

export default EditActivityLevelStep;
