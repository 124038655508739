import React, { useEffect, useState } from 'react';
import { FormControl, FormLabel, Select, Text, Stack, Button, Heading, Flex } from '@chakra-ui/react';
import Multiselect from 'multiselect-react-dropdown';
import { fetchFoodItemsByCategory } from 'services/foodItemsService';

const EditMealStep = ({ formData, setFormData  }) => {
  const [selectedMeal, setSelectedMeal] = useState(formData.meal || '');
  const [isValid, setIsValid] = useState(false);
  const [foodItems, setFoodItems] = useState([]);
  const [addOnItems, setAddOnItems] = useState([]);
  const [resetKey, setResetKey] = useState(0); // Add a key for remounting the Multiselect components

  const handleMealChange = (event) => {
    setSelectedMeal(event.target.value);
    //setIsValid(!!event.target.value); // Ensure a meal is selected
    if (event.target.value === 'o') {
      setFormData({
        ...formData,
        meal: event.target.value,
        bigMeals: '',
        smallMeals: '',
        addOnMeals: '',
        bigMealsItems: [],
        smallMealsItems: [],
        addOnMealsItems: [],
      });
    } else {
      const [bigMealsIndex, smallMealsIndex] = event.target.value.split('-');
      setFormData({
        ...formData,
        meal: event.target.value,
        bigMeals: parseInt(bigMealsIndex),
        smallMeals: parseInt(smallMealsIndex),
        addOnMeals: '',
        bigMealsItems: [],
        smallMealsItems: [],
        addOnMealsItems: [],
      });
    }

    // Update the key to remount the Multiselect components
    setResetKey(prevKey => prevKey + 1);
  };


  const [selectedSmallMeals, setSelectedSmallMeals] = useState([]);
  const [selectedBigMeals, setSelectedBigMeals] = useState([]);
  const [selectedAddOnMeals, setSelectedAddOnMeals] = useState([]);
 

  const fetchFoodItems = (category,allergies) => {
    fetchFoodItemsByCategory(category,allergies)
      .then(res => {
        setFoodItems(res);
      })
      .catch(error => {
        console.error('Error fetching food items:', error);
      });
  };    

  const fetchAddOnItems = (category,allergies) => {
    fetchFoodItemsByCategory(category,allergies)
      .then(res => {
        setAddOnItems(res);
      })
      .catch(error => {
        console.error('Error fetching food items:', error);
      });
  };

  const renderSmallMeansMultiSelects = () => {
    const multiSelects = [];
    for (let i = 0; i < formData.smallMeals; i++) {
      multiSelects.push(
        <FormControl key={i} mb={4}>
          <FormLabel>Select Small Meals {i + 1}</FormLabel>
          <Multiselect
            key={resetKey} // Add key prop
            options={foodItems}
            selectedValues={selectedSmallMeals[i] || []}
            onSelect={(selectedList) => handleSmallMealsMultiSelect(selectedList, i)}
            onRemove={(selectedList) => handleSmallMealsMultiSelect(selectedList, i)}
            displayValue="subHead"
          />
        </FormControl>
      );
    }
    return multiSelects;
  };

  const renderBigMealsMultiSelects = () => {
    const multiSelects = [];
    for (let i = 0; i < formData.bigMeals; i++) {
      multiSelects.push(
        <FormControl key={i} mb={4} flexBasis="100%">
          <FormLabel>Select Big Meals {i + 1}</FormLabel>
          <Multiselect
            key={resetKey} // Add key prop
            options={foodItems}
            selectedValues={selectedBigMeals[i] || []}
            onSelect={(selectedList) => handleBigMealsMultiSelect(selectedList, i)}
            onRemove={(selectedList) => handleBigMealsMultiSelect(selectedList, i)}
            displayValue="subHead"
          />
        </FormControl>
      );
    }
    return multiSelects;
  };

  const renderAddOnMealsMultiSelects = () => {
    const multiSelects = [];
    multiSelects.push(       
      <FormControl key="addon">
        <FormLabel>Add Ons</FormLabel>
        <Multiselect
          key={resetKey} // Add key prop
          options={addOnItems}
          selectedValues={selectedAddOnMeals[0] || []}
          onSelect={(selectedList) => handleAddOnMealsMultiSelect(selectedList, 0)}
          onRemove={(selectedList) => handleAddOnMealsMultiSelect(selectedList, 0)}
          displayValue="subHead"
        />
      </FormControl>
    );
    return multiSelects;
  };

  const handleSmallMealsMultiSelect = (selectedList, index) => {
    const updatedSmallMealsItems = [...formData.smallMealsItems];
    updatedSmallMealsItems[index] = selectedList;
    setFormData({
      ...formData,
      smallMealsItems: updatedSmallMealsItems
    });
    console.log(formData);
  };

  const handleBigMealsMultiSelect = (selectedList, index) => {
    const updatedBigMealsItems = [...formData.bigMealsItems];
    updatedBigMealsItems[index] = selectedList;
    setFormData({
      ...formData,
      bigMealsItems: updatedBigMealsItems
    });
  };

  const handleAddOnMealsMultiSelect = (selectedList, index) => {
    const updatedAddOnMealsItems = [...formData.addOnMealsItems];
    updatedAddOnMealsItems[index] = selectedList;
    setFormData({
      ...formData,
      addOnMealsItems: updatedAddOnMealsItems
    });
    console.log(formData);
  };
// Validation function
// Validation function
const validateForm = () => {
  // Checks that each array of big meal items has at least one item selected
  const allBigMealsSelected = formData.bigMealsItems.every(mealArray => mealArray && mealArray.length > 0);
  const allSmallMealsSelected = formData.smallMealsItems.every(mealArray => mealArray && mealArray.length > 0);
  const allAddOnsSelected = formData.addOnMealsItems.every(mealArray => mealArray && mealArray.length > 0);
  // Assuming that addOnMealsItems is a single list, not an array of lists
  // const allAddOnsSelected = formData.addOnMealsItems.length > 0;

  // Update the validation state based on whether all conditions are met
  // alert(allBigMealsSelected)
  if(selectedMeal !=='0' && formData.bigMealsItems.length>0 && (formData.smallMeals >0 ?formData.smallMealsItems.length>0:true) && formData.addOnMealsItems.length>0){
    setIsValid(  allBigMealsSelected && allSmallMealsSelected && allAddOnsSelected);
  }else{
    setIsValid(false)
  }
};

// UseEffect hook to run validation whenever any related state changes
useEffect(() => {
  validateForm();
  // You might need to add other dependencies here if there are other pieces of state
  // that affect the validity of the form.
}, [selectedMeal, formData.bigMealsItems, formData.smallMealsItems, formData.addOnMealsItems]);

  useEffect(() => {
    
    fetchFoodItems('BIGMEAL',formData.allergies);
    fetchAddOnItems('ADDON',formData.allergies);
  
    // Reinitialize selections based on formData
    if (formData.bigMealsItems) {
      setSelectedBigMeals(formData.bigMealsItems);
    }
    if (formData.smallMealsItems) {
      setSelectedSmallMeals(formData.smallMealsItems);
    }
    if (formData.addOnMealsItems) {
      setSelectedAddOnMeals(formData.addOnMealsItems);
    }
  
    // Set the initial meal selection
    setSelectedMeal(formData.meal || '');
  
    // Validate form based on reinitialized state
    //setIsValid(!!formData.meal);
    validateForm();

  }, [selectedMeal, formData.bigMealsItems, formData.smallMealsItems, formData.addOnMealsItems,formData.allergies]);

  return (
    <Flex display={'flex'} flexDirection={'column'} flexBasis="100%">
      <FormControl isRequired >
        <FormLabel>Which of the following best describes how often you eat in a typical day?*</FormLabel>
        <Select
          id='meal'
          value={selectedMeal}
          onChange={handleMealChange}
          placeholder='Select...'
          fontSize="lg"
          height="40px"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
          _placeholder={{ fontSize: 'md', color: 'gray.500' }}
        >
          <option value="2-1">2 big meals with small snack</option>
          <option value="3-1">3 Big meals with a snack </option>
          <option value="3-0">3 big meals </option>
          <option value="4-0">4 meals  </option>
        </Select>
      </FormControl>
      
      <Flex direction="row" justifyContent="space-between" mt={4}>
        <Stack flexBasis="48%">
          {formData.bigMeals > 0 && renderBigMealsMultiSelects()}
        </Stack>
        <Stack flexBasis="48%">
          {formData.smallMeals > 0 && renderSmallMeansMultiSelects()}
          {formData.bigMeals > 0 && renderAddOnMealsMultiSelects()}
        </Stack>
      </Flex>
    </Flex>
  );
  
};

export default EditMealStep;
