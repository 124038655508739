import React, { useEffect, useState } from 'react';
import {
  Select,
  FormControl,
  FormLabel
} from '@chakra-ui/react';
import { goals } from 'views/diet/userDiet/Fields/FitnessGoalSelectorStep';



 

const EditFitnessGoalSelectorStep = ({ formData, setFormData }) => {
 
  const [goals1,setGoals]=useState([])

  const handleSelectGoal = (goalId) => {
    setFormData({ ...formData, goal: goalId });
  };

  useEffect(()=>{
    setGoals(goals)
  },[])
  return (
    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel>Goal </FormLabel>
       <Select placeholder="Select your goal" value={formData.goal} onChange={(e) => handleSelectGoal(e.target.value)}>
        {goals1.map((goal) => (
          <option key={goal.id} value={goal.id}>{goal.title}</option>
        ))}
      </Select>
       
   </FormControl>
  );
};

export default EditFitnessGoalSelectorStep;
