import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Flex,
  Box,
  SimpleGrid,
  Select,
  Stack,
  RadioGroup,
  Radio,
  Switch,
  Image,
} from '@chakra-ui/react';
import { fetchFoodMeasures } from 'services/foodItemsService';
import Swal from 'sweetalert2';
import { saveFoodData } from 'services/foodItemsService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { doLogout } from 'helpers/loginHelper';
import { saveFoodIngredient } from 'services/foodItemsService';
import { uploadFoodIcon } from 'services/foodItemsService';

const IngredientsForm = () => {

    const initialFormData = {
        ingredientName: '',
        quantity: '',
        measured: '',
        protein: '',
        carbs: '',
        fats: '',
        calories: '',
        isDisplay: false,  // 'true' as a string if you want to default to true
        ingredientIconFileUrl: '', // URL for the image preview
        ingredientIconPath:''
      };
      const history = useHistory();

      const [isLoading, setIsLoading] = useState(false); // New state for loading indication

 
    const [foodMeasures, setFoodMeasures] = useState([]);
 
  const [formData, setFormData] = useState(initialFormData);
  const [ingredientIconFile, setIngredientIconFile] = useState(null);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData)
  };
 
  
const fetchFoodAllMeasures = () => {
    fetchFoodMeasures()
        .then(res => {
            setFoodMeasures(res);
        })
        .catch(error => {
            console.error('Error fetching food items:', error);
        });
}; 
 
useEffect(() => {
      fetchFoodAllMeasures();
 }, []);


const handleSubmit =async  (e) => {
    e.preventDefault();

    // Validation checks
    const errors = {};

    if (!ingredientIconFile) {
      errors.ingredientIconPath = "Please select a file to upload.";
    } 
    // Check if sub_head is empty
    if (!formData.ingredientName.trim() || formData.ingredientName.trim()==='0') {
      errors.subHead = 'Ingredient Name is required';
  }

  

 
  // Check if quantity is empty
   
  if (!formData.quantity) {
      errors.quantity = 'Quantity is required';
    } else if (isNaN(formData.quantity)) {
      errors.quantity = 'Quantity  must be a number';
    }


  // Check if measured is empty
  if (!formData.measured.trim() || formData.measured.trim()==='0') {
      errors.measured = 'Measured is required';
  }


  if (!formData.protein) {
      errors.protein = 'Protein is required';
    } else if (isNaN(formData.protein)) {
      errors.protein = 'Protein  must be a number';
    }

  if (!formData.carbs) {
      errors.carbs = 'Carbs is required';
    } else if (isNaN(formData.carbs)) {
      errors.carbs = 'Carbs  must be a number';
    }

    
   if (!formData.fats) {
      errors.fats = 'Fats is required';
    } else if (isNaN(formData.fats)) {
      errors.fats = 'Fats  must be a number';
    }      
   if (!formData.calories) {
      errors.calories = 'Calories is required';
    } else if (isNaN(formData.calories)) {
      errors.calories = 'Calories  must be a number';
    }
 

 
 

    // Add similar validation checks for other fields...

    // If there are errors, display alert with error messages
    if (Object.keys(errors).length > 0) {
        let errorMessage = '<ul>';
        Object.values(errors).forEach(error => {
            errorMessage += `<li>${error}</li>`;
        });
        errorMessage += '</ul>';
        
        Swal.fire({
            icon: 'error',
            title: 'Required Fields!',
            html: errorMessage
        });
        return;
    }

    // Proceed with form submission if no errors
    console.log('Form submitted successfully:', formData);
   // Proceed with form submission if no errors
   setIsLoading(true);

   try {
       const uploadResponse = await uploadFoodIcon(ingredientIconFile);
       if (uploadResponse.statusCode === 200) {
           const updatedFormData = {
               ...formData,
               ingredientIconPath: uploadResponse.data // Assuming server returns the file path in `data`
           };

           const saveResponse = await saveFoodIngredient(updatedFormData);
           if (saveResponse.statusCode === 200) {
               setFormData(initialFormData);
               setIngredientIconFile(null);
               document.getElementById('fileInput').value = '';

               Swal.fire({
                   icon: 'success',
                   title: 'Successfully added Ingredient',
               }).then(result => {
                   if (result.isConfirmed || result.isDismissed) {
                       history.push('/diet/ingredientsData');
                   }
               });
           } else {
               throw new Error(saveResponse.message || 'Failed to save ingredient data');
           }
       } else {
           throw new Error(uploadResponse.message || 'Failed to upload file');
       }
   } catch (error) {
       console.error('Error during form submission:', error);
       if (error.response && error.response.status === 401) {
        // Handle 401 Unauthorized error
        
        Swal.fire({
            icon: 'error',
            title: 'Please log in again.',
        }).then((result) => {
            // Check if the user clicked the "OK" button on the success message
            if (result.isConfirmed || result.isDismissed) {  
                doLogout() 
                history.push('/logout');
             }
        });
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message || 'An error occurred during form submission.',
      });
      }
   } finally {
       setIsLoading(false); // Stop loading irrespective of the outcome
   }

    // console.log('Form data is valid:', formData);

    //     setIsLoading(true)
    //     saveFoodIngredient(formData).then(res => {

    // if(res.statusCode===200){
    //     setFormData(initialFormData);
    //     Swal.fire({
    //         icon: 'success',
    //         title: 'Successfully added Ingredient',
    //     }).then((result) => {
    //         // Check if the user clicked the "OK" button on the success message
    //         if (result.isConfirmed || result.isDismissed) {
    //             // Redirect to the '/diet/foodData' page
    //             setIsLoading(false)
    //             history.push('/diet/ingredientsData');

    //         }
    //     });
    // }else{
    //     Swal.fire({
    //         icon: 'error',
    //         title: res.message,
    //     }).then((result) => {
    //         // Check if the user clicked the "OK" button on the success message
    //         if (result.isConfirmed || result.isDismissed) {          
    //             setIsLoading(false)
    //         }
    //     });
    // }
    //     setIsLoading(false)
       
    //     })
    //     .catch(error => {
    //       console.error('Error sending diet info:', error);
    //       if (error.response && error.response.status === 401) {
    //         // Handle 401 Unauthorized error
            
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Please log in again.',
    //         }).then((result) => {
    //             // Check if the user clicked the "OK" button on the success message
    //             if (result.isConfirmed || result.isDismissed) {  
    //                 doLogout() 
    //                 history.push('/logout');
    //              }
    //         });
    //         // Perform logout action or redirect to login page
    //       } else {
    //         console.error('Error fetching food items:', error);
    //       }
    //       setIsLoading(false)
    //     });

};

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
      // Check if the file size is greater than 1MB
      if (file.size > 1048576) { // 1MB = 1 * 1024 * 1024 = 1,048,576 bytes
          // Alert the user and exit the function if the file is too large
          Swal.fire({
              icon: 'error',
              title: 'File Too Large',
              text: 'Please select a file smaller than 1MB.',
          });
          // Optionally, clear the selected file
          e.target.value = '';
          return; // Exit the function
      }

      setIngredientIconFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
          setFormData(prev => ({
              ...prev,
              ingredientIconFileUrl: reader.result,
           }));
      };
      reader.readAsDataURL(file);
  }
};


  return (
   

    <Box mt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="80%" mx="auto"    p={6} borderRadius="lg" boxShadow="lg" bg="white">
    <SimpleGrid
      
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
  
         
    <form onSubmit={handleSubmit}>
      <Flex direction="column">

      <Flex>
      <FormControl mt={4}>
                <FormLabel>Ingredient Icon</FormLabel>
                <Input type="file" p={1} accept="image/*"  id="fileInput"  onChange={handleFileChange} />
             
            </FormControl>  
            
             <FormControl mt={4} >
                 
                {formData.ingredientIconFileUrl && (
                    <Box mt={4}  direction='column' alignContent={'center'}>
                        <Image 
                        src={formData.ingredientIconFileUrl}   
                       
                    alt="Preview" ml={10} mb={5} p={1} maxW="100px" maxH="100px"  
                     className='previewImage'/>
                    </Box>
                )}
             
            </FormControl>


      </Flex>



        <Flex  mt={4}>
          <FormControl id="ingredientName" mr={4}>
            <FormLabel>Ingredient Name</FormLabel>
            <Input
              type="text"
              name="ingredientName"
              value={formData.ingredientName}
              onChange={handleChange}
               
            />
          </FormControl>

      
       
 

          <FormControl id="quantity">
            <FormLabel>Quantity</FormLabel>
            <Input
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
               
            />
          </FormControl>
        </Flex>

        <Flex mt={4}>
          <FormControl id="measured" mr={4}>
            <FormLabel>Measured</FormLabel>
           

<Select
            name="measured"
            value={formData.measured}
            onChange={handleChange}
             
          >
           <option key='0' value='0'>--Select--</option>
            {foodMeasures.map((foodMeasure) => (
            <option key={foodMeasure.measureName} value={foodMeasure.measureName}>
                {foodMeasure.measureName} 
            </option>
             ))}
          </Select>



          </FormControl>

          <FormControl id="protein">
            <FormLabel>Protein</FormLabel>
            <Input
              type="number"
              name="protein"
              value={formData.protein}
              onChange={handleChange}
               
            />
          </FormControl>
        </Flex>

        <Flex mt={4}>
          <FormControl id="carbs" mr={4}>
            <FormLabel>Carbs</FormLabel>
            <Input
              type="number"
              name="carbs"
              value={formData.carbs}
              onChange={handleChange}
              
            />
          </FormControl>

          <FormControl id="fats">
            <FormLabel>Fats</FormLabel>
            <Input
              type="number"
              name="fats"
              value={formData.fats}
              onChange={handleChange}
               
            />
          </FormControl>
        </Flex>

        <Flex mt={4}>
          <FormControl id="calories" mr={4}>
            <FormLabel>Calories</FormLabel>
            <Input
              type="number"
              name="calories"
              value={formData.calories}
              onChange={handleChange}
               
            />
          </FormControl> 
      {/* Switch for isDisplay */}
      <FormControl display="flex" alignItems="center" mt={4}>
                    <FormLabel htmlFor="isDisplay" mb="0">
                        Display Ingredient
                    </FormLabel>
                    <Switch
                        id="isDisplay"
                        isChecked={formData.isDisplay}
                        onChange={() => setFormData({ ...formData, isDisplay: !formData.isDisplay })}
                        colorScheme="teal"
                    />
                </FormControl>
 
        </Flex>

    

      </Flex>
      <Button mt={4} colorScheme="teal" type="submit"   isLoading={isLoading} loadingText="Sending Data...">
        Submit
      </Button>
    </form>
    </SimpleGrid>
    </Box>
  );
};

export default IngredientsForm;
