import { myAxios, myPrivateAxios } from "../helpers/axioHelper"





export const getPaymentStatus=async (mobieNo)=>{
  const response = await myAxios.get('/getPaymentStatus/'+mobieNo)
  console.log(response)
  return response.data
}

export const fetchFoodData= async (id) => {
    const response = await myPrivateAxios.get(`/getFoodData/${id}`)
     return response.data
};


export const saveFoodData=async data=>{
  const response = await myPrivateAxios.post('/saveFoodData', data)
  console.log(response)
  return response.data
}
export const editFoodData=async data=>{
  const response = await myPrivateAxios.post('/editFoodData', data)
  console.log(response)
  return response.data
}

export const deleteFoodData=async id=>{
  const response = await myPrivateAxios.delete('/deleteFoodData/'+ id)
  return response.data
}




export const saveFoodIngredient=async data=>{
  const response = await myPrivateAxios.post('/saveFoodIngredient', data)
  return response.data
}


export const fetchAllFoodIngredient= async () => {
  const response = await myAxios.get(`/getAllFoodIngredients`)
  console.log(response)

  return response.data
};

export const fetchDisplayFoodIngredient= async () => {
  const response = await myAxios.get(`/getDisplayFoodIngredients`)
  console.log(response)

  return response.data
};

export const deleteFoodIngredientData=async id=>{
  const response = await myPrivateAxios.delete('/deleteFoodIngredient/'+ id)
  return response.data
}

export const editFoodIngredientData=async data=>{
  const response = await myPrivateAxios.post('/editFoodIngredient', data)
  return response.data
}
export const fetchFoodIngredientData= async (id) => {
  const response = await myPrivateAxios.get(`/getFoodIngredient/${id}`)
   return response.data
};






export const sendDietInfo=async data=>{
    const response = await myAxios.post('/sendDietInfo', data)
    console.log(response)
    return response.data
}

export const sendEditDietInfo=async data=>{
    const response = await myPrivateAxios.post('/sendEditDietInfo', data)
    console.log(response)
    return response.data
}
export const deleteDietInfo=async id=>{
    const response = await myPrivateAxios.delete('/deleteDietInfo/'+ id)
     return response.data
}



export const genPdf=async (id)=>{
    const response = await myAxios.get('/genPdf/'+id)
    console.log(response)
    return response.data
}
export const sendPdfToWhatsApp=async (id)=>{
    const response = await myPrivateAxios.get('/sendPdfToWhatsApp/'+id)
    console.log(response)
    return response.data
}

// export const fetchFoodItemsByCategory = async (category,allergies,ingredients) => {
//     const allergyIdsStr = allergies.join(',');
//     const response = await myAxios.get(`/foodItems/${category}?allergyIdsStr=${allergyIdsStr}&ingredients=${ingredients.join(',')}`)
//     console.log(response)
//     return response.data
// };
export const fetchFoodItemsByCategory = async (category, allergies, itemList) => {
  const allergyIdsStr = allergies.join(',');

  // Filter and extract numeric IDs for foodItems and ingredients
  const foodItems = itemList.filter(item => item.startsWith('item-')).map(item => item.split('-')[1]).join(',');
  const ingredients = itemList.filter(item => item.startsWith('ing-')).map(item => item.split('-')[1]).join(',');

  // Construct the API request URL with extracted IDs for both foodItems and ingredients
  const response = await myAxios.get(`/foodItems/${category}?allergyIdsStr=${allergyIdsStr}&ingredients=${ingredients}&foodItems=${foodItems}`);
  console.log(response);
  return response.data;
};




export const fetchFoodItemsByDisplay = async () => {
   const response = await myAxios.get(`/displayFoodItems`)
  console.log(response)
  return response.data
};

export const fetchAllFoodItems= async () => {
    const response = await myPrivateAxios.get(`/getAllFoodItems`)
    console.log(response)
    return response.data
};
export const fetchAllUserDietInfo= async () => {
    const response = await myPrivateAxios.get(`/getAllUserDietInfo`)
    console.log(response)
    return response.data
};
export const fetchUserDietInfo= async (id) => {
    const response = await myAxios.get(`/getUserDietInfo/${id}`)
    console.log(response)
    return response.data
};


export const fetchFoodHeads = async () => {
    const response = await myAxios.get(`/foodHeads`)
    console.log(response)
    return response.data
};
export const fetchFoodStates = async () => {
    const response = await myAxios.get(`/foodStates`)
    console.log(response)
    return response.data
};
export const fetchFoodMeasures = async () => {
    const response = await myAxios.get(`/foodMeasures`)
    console.log(response)
    return response.data
};

export const fetchLifestyleIssues = async () => {
    const response = await myAxios.get(`/lifestyleIssues`)
    console.log(response)
    return response.data
};
export const fetchlifeEvents = async () => {
    const response = await myAxios.get(`/lifeEvents`)
    console.log(response)
    return response.data
};
export const fetchfoodAllergies = async () => {
    const response = await myAxios.get(`/foodAllergies`)
    console.log(response)
    return response.data
};





// Uploads the food icon image and returns the path or filename
export const uploadFoodIcon = async (file) => {
  const formData = new FormData();
  formData.append('foodIconFile', file); // Ensure the server is expecting 'foodIconFile' as the key

      const response = await myPrivateAxios.post('/uploadFoodIcon', formData, {
          headers: {
              'Content-Type': 'multipart/form-data'  // This content type will be set automatically by Axios when using FormData
          }
      });
          return response.data; // Assuming the server sends back the filename or path of the uploaded image
     
 
};









//==============================================
export const downloadPdf = async (id) => {
  try {
    const response = await myAxios.get('/genPdf/'+id, {
      responseType: 'blob', // Important: Set the response type to 'blob'
    });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: 'application/pdf' });

    // Create a URL for the blob
    const url = window.URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'diet'+id+'.pdf'); // Set the filename

    // Simulate click to download the PDF file
    document.body.appendChild(link);
    link.click();

    // Cleanup
    link.parentNode.removeChild(link);
   // window.open(url, '_blank');

    return true;
  } catch (error) {
    console.error('Error downloading PDF:', error);
    return error;
  }
};