import React, { useState, useEffect, useContext } from 'react';
import { VStack, Button, Heading, Radio, RadioGroup, Box, useRadioGroup, useRadio, useColorModeValue, Stack } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

// Drink options with unique IDs
const drinkOptions = [
  { id: 'regular_tea', title: 'Yes, I drink Regular Tea' },
  { id: 'green_tea', title: 'Yes, I drink Green Tea' },
  { id: 'black_coffee', title: 'Yes, I drink Black Coffee' },
  { id: 'milk_coffee', title: 'Yes, I drink Milk Coffee' },
  { id: 'none', title: 'No, I don\'t drink either' },
];

// Custom radio card component
const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const bg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = "black";
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  return (
    <Box as='label' w="full">
      <input {...input} />
      <Box
        {...checkbox}
        bg={bg}
        borderColor={borderColor}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        mb={'10px'}
        _checked={{
          bg: selectedBg,
          borderColor: 'teal.500',
          color: 'white',
        }}
        display={'flex'}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const DrinkPreferenceStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedDrink, setSelectedDrink] = useState(formData.drink);

  useEffect(() => {
    // Ensure we have a default value if the form data already contains a drink selection
    if (formData.drink) {
      setSelectedDrink(formData.drink);
    }
  }, [formData.drink]);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "drink",
    defaultValue: selectedDrink,
    onChange: (value) => {
      setSelectedDrink(value);
      // setFormData({ ...formData, drink: value });
      if (value === 'none') {
        // Reset drinkTime if drink is 'none'
      
        setFormData({ ...formData, drink: value, drinkTime: '' });
      } else {
        setFormData({ ...formData, drink: value });
      }
    }
  });
  const group = getRootProps();
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
   setDisplayheader(true);
  setQuestionNo(15)
}, []);
//for setting question no end

  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10} className='bottomImage' >
      <Heading as="h5" size="lg" textAlign="left"  >
        Do you drink Tea or Coffee
      </Heading>
      <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>(& want it to be a part of your nutrition plan?) </p>

      <RadioGroup     value={selectedDrink}>
        <Box {...group}>
          {drinkOptions.map((option) => (
            <RadioCard key={option.id} {...getRadioProps({ value: option.id })}>
              <Radio  className='default_radio_input1 default_input' value={option.id} mr={5} /> {option.title}
            </RadioCard>
          ))} 
        </Box>
      </RadioGroup>
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={() => setCurrentStep(currentStep + 1)} isDisabled={!selectedDrink}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default DrinkPreferenceStep;
