import React from 'react'


// Chakra imports
import {
    Box,
    Heading,
    SimpleGrid,
   } from "@chakra-ui/react";
 
export const Home = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
                <Heading>Welcome</Heading>
            
        </SimpleGrid>
      </Box>
  )
}
