import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { fetchAllFoodItems } from 'services/foodItemsService';
import { Box, Button, Input, InputGroup, InputLeftElement, SimpleGrid, Skeleton, Spinner } from '@chakra-ui/react';
import './food.css'
import { SearchIcon } from '@chakra-ui/icons';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { doLogout } from 'helpers/loginHelper';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { MdLock } from 'react-icons/md';
import { deleteFoodData } from 'services/foodItemsService';
import { fetchAllFoodIngredient } from 'services/foodItemsService';
import { deleteFoodIngredientData } from 'services/foodItemsService';

export default function IngredientList() {
  const [foodIngredientData, setFoodIngredientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
const history=useHistory();


  const fetchFoodIngredient = () => {
    setLoading(true); // Set loading to true when starting data fetch

    fetchAllFoodIngredient()
        .then(res => {
            setFoodIngredientData(res);
            setLoading(false); // Set loading to false when data fetch is completed

        })
        .catch(error => {
          setLoading(false); // Set loading to false when data fetch is completed

            if (error.response && error.response.status === 401) {
                // Handle 401 Unauthorized error
                
                Swal.fire({
                    icon: 'error',
                    title: 'Please log in again.',
                }).then((result) => {
                    // Check if the user clicked the "OK" button on the success message
                    if (result.isConfirmed || result.isDismissed) {  
                        doLogout() 
                        history.push('/logout');
                     }
                });
                // Perform logout action or redirect to login page
              } else {
                console.error('Error fetching food Ingredients:', error);
              }       
            
            });
};


  useEffect(() => {
    fetchFoodIngredient();
  }, []);
  const DeleteFoodIngredientData = (id) => {
    Swal.fire({
      title: 'Are you sure want to delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with the delete operation
        deleteFoodIngredientData(id)
          .then(downloadRes => {
            console.log(downloadRes);
  
            if(downloadRes.statusCode === 200){
              fetchFoodIngredient();
              Swal.fire(
                'Deleted!',
                'Ingredient has been deleted.',
                'success'
              );
            } else {
              Swal.fire({
                icon: 'error',
                title: downloadRes.message,
              });
            }
          })
          .catch(error => {
            console.error('Error during the deletion:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to Delete',
            });
          });
      }
    });
  };
  

 

const columns = [
      //  { name: 'ID', selector: row => row.id, sortable: true,  },
      {
        name: 'S No.',
        selector: (row, index) => index + 1,
        sortable: false,
      },
      {
        name: 'Ingredient Entered On',
        selector: row => row.insertedOn,
        sortable: true,
        sortFunction: (rowA, rowB) => {
          const dateA = new Date(rowA.insertedOn);
          const dateB = new Date(rowB.insertedOn);
          return dateA.getTime() - dateB.getTime();
        }
      }

      ,
    { name: 'Delete', 
      cell: (row) =>   <FaTrash onClick={() => DeleteFoodIngredientData(row.id)} as={MdLock} width='20px' height='20px' color='inherit' style={{ cursor: 'pointer' ,color:'red'}}/> ,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Edit',
      cell: (row) =>   <FaEdit onClick={() => history.push(`/diet/editIngredientData/${row.id}`)} as={MdLock} width='20px' height='20px' color='inherit' style={{ cursor: 'pointer' ,color:'blue'}}/> ,
       ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    

         { name: 'Ingredient Name', selector: row => row.ingredientName, sortable: true, },
        { name: 'Quantity', selector: row => row.quantity, sortable: true, },
        { name: 'Measured', selector: row => row.measured, sortable: true, },
        { name: 'Protein', selector: row => row.protein, sortable: true, },
        { name: 'Carbs', selector: row => row.carbs, sortable: true, },
        { name: 'Fats', selector: row => row.fats, sortable: true, },
        { name: 'Calories', selector: row => row.calories, sortable: true, },
     ];
  const handleSearchChange = event => {
    setSearchText(event.target.value);
  };


  const filteredData = foodIngredientData.filter(item => {
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        const field = item[key];
        if (typeof field === 'string' && field.toLowerCase().includes(searchText.toLowerCase())) {
          return true; // Include the item if the string field matches the search text
        } else if (typeof field === 'number' && field.toString().includes(searchText)) {
          return true; // Include the item if the number field matches the search text
        }
        // Add additional checks for other data types if needed
      }
    }
    return false; // Exclude the item if no field matches the search text
  });

  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="100%" mx="auto"    p={6} borderRadius="lg" boxShadow="lg" bg="white">
    <SimpleGrid
      
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>  
      
      <div className='container'>
          <div className="card">
            <div className="table-container">

        <InputGroup>
            <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
            <Input type="text" placeholder="Search" value={searchText} onChange={handleSearchChange} />
          </InputGroup>
          <br></br>
        
          {loading ? (
            <>
                  <Skeleton height="20px" mb="2" />
                  {[...Array(5)].map((_, index) => (
                    <Skeleton key={index} height="20px" mb="2" />
                  ))}
                </>
              ) : (

              <DataTable
                title="Food Items"
                columns={columns}
                data={filteredData}
                progressPending={loading}
                pagination
                paginationPerPage={20}
                onChangePage={page => setCurrentPage(page)}
                fixedHeader 
                striped
                noHeader
                customStyles={{
                  header: {
                    style: {
                      backgroundColor: 'red', // Change heading color
                      color: 'white', // Change text color
                    },
                  },
                }}
                 
              />)}
            </div>
          </div>
        </div>
    </SimpleGrid>
    </Box>
   );
}

 