import { FormControl, FormLabel } from '@chakra-ui/react';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { fetchLifestyleIssues } from 'services/foodItemsService';

const EditLifestyleIssuesStep = ({ formData, setFormData }) => {
  const [lifestyleIssuesList, setLifestyleIssuesList] = useState([]);
  const [selectedLifestyleIssues, setSelectedLifestyleIssues] = useState([]);

  useEffect(() => {
    fetchLifestyleIssues().then(data => {
      const issues = data.map(issue => ({
        label: issue.name,
        value: issue.id.toString(), // Convert to string
      }));
      setLifestyleIssuesList(issues);
  
      // Filter for pre-selection based on formData
      const selectedIssues = issues.filter(issue => 
        formData.lifestyleIssues?.includes(issue.value)
      );
      setSelectedLifestyleIssues(selectedIssues);
    }).catch(error => {
      console.error('Error fetching lifestyle issues:', error);
    });
  }, [formData.lifestyleIssues]);
  

  const handleLifestyleIssueChange = (selectedList) => {
    setFormData({
      ...formData,
      lifestyleIssues: selectedList.map(item => item.value),
    });
    setSelectedLifestyleIssues(selectedList);
  };

  return (
    <FormControl display={'flex'} flexDirection={'column'} flexBasis="48%">
      <FormLabel>Lifestyle issues</FormLabel>
      <Multiselect
        options={lifestyleIssuesList}
        selectedValues={selectedLifestyleIssues} // Use state to keep track of selected items
        onSelect={handleLifestyleIssueChange}
        onRemove={handleLifestyleIssueChange}
        displayValue="label"
        placeholder="Select lifestyle issues..."
        style={{ chips: { background: 'teal' } }}
      />
    </FormControl>
  );
};

export default EditLifestyleIssuesStep;
