import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, Input, Stack, Button, Heading, Box, Image, Spinner } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { getPaymentStatus } from 'services/foodItemsService';
import { encrypt } from 'helpers/EncrDecr';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import down_left_backgd_img from 'assets/img/down_left_backgd_img.png'
import flagIcon from 'assets/img/indiaFlag.svg'
import { AppContext } from 'contexts/AppContext';
const PhoneNumberStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps,setIsOnboarding }) => {
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || '');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const history = useHistory();
  const { setQuestionNo,setDisplayheader } = useContext(AppContext);
  const [loading, setLoading] = useState(false); // Loading state for the spinner

  const validatePhoneNumber = (number) => {
    // This is a very basic validation. You might want to use a library like libphonenumber
    const regex = /^\+?[0-9]{10,15}$/;
    return regex.test(number);
  };

  const handlePhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;
    setPhoneNumber(newPhoneNumber);
    const isValid = validatePhoneNumber(newPhoneNumber);
    setIsPhoneNumberValid(isValid);
    if (isValid) {
      setFormData({ ...formData, phoneNumber: newPhoneNumber });
    }
  };


  const handlePayment = () => {
    // Logic to handle payment
    // Check if phoneNumber is valid
    const errors = {};
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
    }


    // If there are errors, display SweetAlert with error messages
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Required Fields!',
        text: 'Please fix the following errors:',
        html: Object.values(errors).map(error => `<p>${error}</p>`).join('')
      });
      return;
    }

    setLoading(true);
    getPaymentStatus(formData?.phoneNumber)
    .then(res => {
        if(res.statusCode===200){

          if(res.data!=null){
            let encId= encrypt(res.data);

            history.push(`/editUserDiet/${encId}`); 
          }


          setIsPaymentDone(true);
          nextStep();
        }else{
          setIsPaymentDone(false)
          Swal.fire({
            icon: 'warning',
            title: res.message             
          });
        }
    })
    .catch(error => {
        console.error('Error fetching Payment info:', error);
    })
    .finally(() => {
      setLoading(false); // Set loading to false after the API call completes
    });



   
};


  const nextStep = () => {
    if (currentStep < totalSteps && isPhoneNumberValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    // setCurrentStep(currentStep - 1);
    setIsOnboarding(true)
  };

  useEffect(() => {
    //fetchFoodItems('BREAKFAST');
    setIsPaymentDone(false)

    // Validate phone number when the component mounts or when formData.phoneNumber changes
    const isValid = validatePhoneNumber(formData.phoneNumber);
    setIsPhoneNumberValid(isValid);
    // If the phone number is already valid, we can assume the payment status
    // But you might want to check the actual payment status from your backend
    if (isValid) {
      // You might need to check the payment status here instead
      // if the validity also depends on payment status
      // getPaymentStatus(formData.phoneNumber);
    } 
  }, [formData.phoneNumber]);
  useEffect(() => {
    setQuestionNo(1);
    setDisplayheader(true)
  }, []);

  
  return (
  <>
    <FormControl isRequired pl={10} pr={10}
    
    className='bottomLeftImage'
     >
       <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>Please enter your mobile number </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>Note: This should be the number that you've made the final payment with </p>
     
     <br />
     <div style={{display:'flex'}}>
     <Image src={flagIcon} alt="flag icon" boxSize="40px" mr={2} className='phoneNumberFlag' />
      <Input
        id='phoneNumber'
        type="tel"
        placeholder='000-000-0000'
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        isInvalid={!isPhoneNumberValid && phoneNumber.length > 0}
        className='default_input'
      />
      </div>

      <Stack direction='row' justifyContent='space-around' alignItems='center' mt={8}>
        <Button className='default_back_button' colorScheme='teal' variant='outline' onClick={prevStep}  >
          Back
        </Button>


        {loading ? (
          <Button className='default_next_button' colorScheme='teal' variant='outline'  >
          <Spinner size='sm' />
        </Button>
        ) : 
        ( <Button className='default_next_button' colorScheme='teal' variant='outline' onClick={handlePayment} isDisabled={!isPhoneNumberValid}>
          Next
        </Button>)
        }



       
      </Stack>
    </FormControl>
    {/* <Image src={down_left_backgd_img} alt="Good hands" boxSize="300px"   /> */}
    </>
  );
};

export default PhoneNumberStep;
