import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  Button,
  Heading,
  Stack,
  Radio,
  RadioGroup,
  Box,
  useRadioGroup,
  useRadio,
  useColorModeValue,
} from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';
 
export const meals = [
  { id: '2-1', title: '2 big meals with a small snack' ,description:"Brunch, dinner & a snack"  } ,
  { id: '3-1', title: '3 big meals with a snack'  ,description:"Breakfast, Lunch, Dinner & a snack"  },
  { id: '3-0', title: '3 big meals'  ,description:"Breakfast, Lunch, & Dinner"  },
  { id: '4-0', title: '4 meals' ,description:"Breakfast, Brunch, Lunch & Dinner"   },
];

// Custom radio card component
const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const bg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = "black";
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  return (
    <Box as='label' w="full">
      <input {...input} />
      <Box
        {...checkbox}
        bg={ bg}
        borderColor={borderColor}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
          mb={'10px'}
        _checked={{
          bg: selectedBg,
          borderColor: 'teal.500',
          color: 'white',
        }}
         display={'flex'}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const MealSelectionStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
    const [selectedMeal, setSelectedMeal] = useState(formData.meal || '');

  // Validation on load
  useEffect(() => {
    // If formData already has a valid duration, use it; otherwise default to the first option
    if (formData.meal) {
       setSelectedMeal(formData.meal);
     }
  }, [formData.meal]);



  const handleDurationChange = (value) => {
    setSelectedMeal(value);
    setFormData({ ...formData, meal: value });
  };


  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "meal",
    defaultValue: selectedMeal,
    onChange: handleDurationChange,
  });
  const group = getRootProps();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(18)
}, []);
//for setting question no end
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10}
    
    className='bottomLeftImage'
    >
      {/* <Heading as="h3" size="lg" textAlign="center" mb={6} color={'teal'}>
        How long has it been since you were at your ideal weight?
      </Heading> */}
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
      How do you prefer to divide your meals in a day?      </Heading>
      <RadioGroup onChange={handleDurationChange} value={selectedMeal}> 
      <Box {...group}>
        {meals.map((meal) => (
          <RadioCard key={meal.id} {...getRadioProps({ value: meal.id })}  >
          <Radio value={meal.id}   className='default_radio_input1 default_input' mr={5} />   
          <div>{meal.title}<br></br>  ({meal.description})</div>
        
          </RadioCard>
        ))}
      </Box>

      </RadioGroup>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={handleNext} isDisabled={!selectedMeal}>
          Next
        </Button>
       
      </Stack> 
   
    </VStack>
  );
};

export default MealSelectionStep;
