import React from 'react';
import { Box, Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import coach_vatsav_logo from 'assets/img/couchVastavLogo.png';
import scale_concept_black from 'assets/img/scale_concept_black.png'; // Replace with your actual image path
import leftCornerImage from 'assets/img/leftCornerImage.png';  // Placeholder path, replace with actual
import rightCornerImage from 'assets/img/rightCornerImage.png'; // Placeholder path, replace with actual

const OnboardingForm = ({ onNext }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    minH="100vh"
    bg="#161616"
    color="white"
    // p={5}
  >

<Flex
 width={'100%'}
  justifyContent={'space-between'}
  >
       <Image
        src={leftCornerImage}
        alt="Decorative Image Left"
        width="200px" // Adjust size accordingly
        height="250px"
      />
 
       <Image
        src={rightCornerImage}
        alt="Decorative Image Right"
        width="170px" // Adjust size accordingly
        height="250px"
      />
 
</Flex>

    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mb={8}  >
      <Image
        src={coach_vatsav_logo} // Replace with your actual logo path
        alt="Coach Vatsav"
         
        width={'45%'}
        align={'center'}
      />
    </Box>
    <Box      pl={10} pr={10}>
      <Text  textAlign="center"  fontSize="3xl" fontWeight="bold" color="orange.300" mb={4}>
        Welcome to your Onboarding Form.
      </Text>
      <br></br>
      <Flex   align="center" justify="center" mb={4}  display={'flex'} justifyContent={'space-between'}>
        <Text   fontSize="2xl" fontWeight={'bold'}>
          Please take some time to fill each section properly.
        </Text>
      
      </Flex>
      
      <Text fontSize="sm"  mt={4}  mb={4} >
        Note: Please keep your (<span role="img" aria-label="measuring tape">📏</span>) measuring tape & (<span role="img" aria-label="weighing scale">⚖️</span>) weighing scale ready.
      </Text>
      
      <Flex mt={2} direction="row" justify="center" align="center">
        <Divider  height={'5px'} backgroundColor={'#ffbe4a'} borderColor="#ffbe4a" width="25%"  m={1} borderRadius={10}/>
        <Divider height={'5px'} backgroundColor={'#ffffff'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
        <Divider height={'5px'} backgroundColor={'#ffffff'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
        <Divider height={'5px'} backgroundColor={'#ffffff'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
      </Flex>
    </Box>
    {/* <Flex   width="100%" justifyContent="space-between" flexDirection={'row'} alignItems={'center'}>
    <Image
          src={scale_concept_black} // Replace with your actual additional image path
          alt="Additional Image"
           height={'100px'}
          marginTop={'-200px'}
        />
 
        </Flex> */}
    <Flex mt={8} width="100%" justifyContent="space-between" flexDirection={'column'} alignItems={'center'}>
      {/* <Button bg="gray.700" _hover={{ bg: 'gray.600' }}>
        Back
      </Button> */}
      <Button className='default_next_button' bg="blue.400" _hover={{ bg: 'blue.300' }} onClick={onNext}>
        Next
      </Button>
    </Flex>
  </Flex>
);

export default OnboardingForm;
