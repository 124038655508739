import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, Input, Stack, Button, Text, Image, Heading, Box } from '@chakra-ui/react';
import fatPercentageImg from "assets/img/fatPercentage.jpeg"; // Assuming this path is correct and manImg is used for all for illustration
import down_backgd_img from 'assets/img/down_left_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const FatPercentageStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [bodyFatPercentage, setBodyFatPercentage] = useState(formData.bodyFatPercentage || '');
  const [isValidPercentage, setIsValidPercentage] = useState(false);

  const handleFatPercentageChange = (e) => {
    const newPercentage = e.target.value;
    setBodyFatPercentage(newPercentage);
    // Validate the input value; the percentage must be a number between 2 and 70
    const isValid = !isNaN(newPercentage) && newPercentage >= 2 && newPercentage <= 70;
    setIsValidPercentage(isValid);
    
    // Update formData only if the new value is valid
    if (isValid) {
      setFormData({ ...formData, bodyFatPercentage: newPercentage });
    }
  };

  const nextStep = () => {
    if (currentStep < totalSteps && isValidPercentage) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  useEffect(() => {
    // Validate age on initial mount and when formData changes
    const isValid = !isNaN(formData.bodyFatPercentage) && formData.bodyFatPercentage >= 2 && formData.bodyFatPercentage <= 70;
    setIsValidPercentage(isValid);
  }, [formData.bodyFatPercentage]);

  //for setting question no start
  const { setQuestionNo,setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(true);
    setQuestionNo(7)
  }, []);
 //for setting question no end

  return (
    <Box
    className='bottomLeftImage'
    >
   <FormControl isRequired >
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>What's your fat % ? </Heading>
      {/* <Heading   textAlign="center" as="h3" size="lg"   mb={6} color={'teal'}>What's your body fat percentage ?</Heading> */}
        <Image src={fatPercentageImg} h='200px'   mx="auto" mb={5}/>

        <p   textAlign="left" style={{fontSize:'14px',fontWeight:'bold'}}    mb={6} color={'#b2b2b'}>Pick a visual that closely resembles your current body for us 
to estimate your fat % & enter it below</p>
     <br></br>
     
        {/* <Text>If you have an exact number, please enter:</Text> */}
        <Input
         className='default_input'
          id='bodyFatPercentage'
          type="number"
          placeholder='Please enter a value between 2 & 70 percent'
          value={bodyFatPercentage}
          onChange={handleFatPercentageChange}
        />
             <br></br>
             <br></br>
        {/* <Text mt={2}>Please enter a value between 2 and 70 percent</Text> */}
        <p   textAlign="left" style={{fontSize:'14px'}}  pt={9}   mb={6} color={'#b2b2b'}>Note: In case you're confused between 2 options based on the visual (eg. 35 & 40), always go for the higher value (eg. 40). This will help us calculate your calories less aggressively </p>
   
      </FormControl>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button'  colorScheme='teal' variant='outline' onClick={prevStep}>
          Back
        </Button>
        <Button
         className='default_next_button' 
          colorScheme='teal'
          variant='outline'
          onClick={nextStep}
          isDisabled={!isValidPercentage}
        >
          Next
        </Button>
      </Stack>
      {/* <Image src={down_backgd_img} alt="Good hands"   boxSize="300px"  /> */}
    </Box>
  );
};

export default FatPercentageStep;
