import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  SimpleGrid,
} from '@chakra-ui/react';
 import { fetchFoodItemsByCategory } from 'services/foodItemsService';
import Swal from 'sweetalert2';
import { sendDietInfo } from 'services/foodItemsService';
import { downloadPdf } from 'services/foodItemsService';
import { useHistory } from 'react-router-dom';
 import { ArrowForwardIcon } from '@chakra-ui/icons';
import { getPaymentStatus } from 'services/foodItemsService';
import { EditDietForm } from './EditDietForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchUserDietInfo } from 'services/foodItemsService';
import { sendEditDietInfo } from 'services/foodItemsService';
import { genPdf } from 'services/foodItemsService';
export  const EditUserDietInfo = () => {
  const history = useHistory();
  const {id} = useParams();

 const initialFormData = {
    name: '',
    gender:'',
    email: '',
    phoneNumber: '',
    bodyweight: '',
    bodyFatPercentage: '',
    physicalActivityFactor: '1.15',
    thermicEffectOfFoodFactor: '1.16',
    weightTrainingDuration: '',
    energyBalanceFactor: '0.8',
    trainingDaysPerWeek: '',
    bigMeals:'',
    smallMeals:'',
    addOnMeals:'',
    bigMealsItems: [[]],
    smallMealsItems: [[]],
    addOnMealsItems: [[]], 
  
    age: '',
    heightInCm: '',
    heightType: 'cm', // Assuming you want to set a default value
    heightInFeet: '',
    heightInInches: '',
    weightInKg: '',
    weightInLbs: '',
    weightType: 'kg', // Assuming you want to set a default value
    goal: '',
    timeSinceIdealWeight: '',
    lifeEvents: [],
    idealWeight: '',
    prepareYourMeal: '',
    meal: '',
    activityLevel: '',
    workout: 'No', // Assuming you want to set a default value
    intensity: '',
    addWorkout: 'No', // Assuming you want to set a default value
    hasAllergies: false, // Default value assuming no allergies
    allergies: [],
    lifestyleIssues: []
  };
  const [isLoading, setIsLoading] = useState(false); // New state for loading indication

  const [formData, setFormData] = useState(initialFormData);
  const [foodItems, setFoodItems] = useState([]);
  const [addOnItems, setAddOnItems] = useState([]);
 
  useEffect(() => {

    console.log(formData) 

    }, [formData]);




  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
      console.log(formData)

  };

//   const handleChangeMeals = (e) => {
//     const { name, value } = e.target;
//     console.log(name);

//     // Dynamically update bigMealsItems or smallMealsItems based on the change in bigMeals or smallMeals
//     let updatedMealsItems = [...formData[name + 'Items']];
//     const mealsCount = parseInt(value);

//     // Adjust the length of the meals items array
//     updatedMealsItems = new Array(mealsCount).fill([]);

//     setFormData({
//         ...formData,
//         [name]: value,
//         [name + 'Items']: updatedMealsItems,
//     });
// };



  const handleCheckboxChange = (checkedItems) => {
      setFormData({
          ...formData,
          breakfastItems: checkedItems,
      });
  };

 

  const handleSubmit = (e) => {

    console.log(formData)
    e.preventDefault();
  
    // Validation checks
    const errors = {};
  
    // Check if name is empty
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
  
    // Check if email is valid
    // if (!formData.email.trim()) {
    //   errors.email = 'Email is required';
    // } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
    //   errors.email = 'Email is invalid';
    // }
  
    // Check if phoneNumber is valid
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
    }
   // Gender validation: Must be selected
   if (!formData.gender || formData.gender==='0') {
    errors.gender = 'Please select a gender';
  }


   // Age validation: Required field, must be a number, and within a range
   if (!formData.age) {
    errors.age = 'Age is required';
  } else if (!/^\d+$/.test(formData.age)) {
    errors.age = 'Age must be a number';
  } else if (formData.age < 18 || formData.age > 120) {
    errors.age = 'Age must be between 18 and 120';
  }


    // Check if bodyweight is valid
    if (!formData.weightInKg) {
      errors.weightInKg = 'Bodyweight is required';
    } else if (isNaN(formData.weightInKg)) {
      errors.weightInKg = 'Bodyweight must be a number';
    }
  
    // Check if bodyFatPercentage is valid
    if (!formData.bodyFatPercentage) {
      errors.bodyFatPercentage = 'Body fat percentage is required';
    } else if (isNaN(formData.bodyFatPercentage)) {
      errors.bodyFatPercentage = 'Body fat percentage must be a number';
    }
  



      // Height validation
  if (formData.heightType === 'cm') {
    // Validate height in centimeters
    if (!formData.heightInCm) {
      errors.heightInCm = 'Height is required';
    } else if (!/^\d+$/.test(formData.heightInCm) || parseInt(formData.heightInCm, 10) < 60 || parseInt(formData.heightInCm, 10) > 300) {
      errors.heightInCm = 'Height in centimeters must be a number between 60 and 300';
    }
  } else {
    // Validate height in feet
    if (!formData.heightInFeet) {
      errors.heightInFeet = 'Feet part of height is required';
    } else if (!/^\d+$/.test(formData.heightInFeet) || parseInt(formData.heightInFeet, 10) < 2 || parseInt(formData.heightInFeet, 10) > 9) {
      errors.heightInFeet = 'Feet part of height must be a number between 2 and 9';
    }

    // Validate height in inches
    if (formData.heightInInches && (!/^\d+$/.test(formData.heightInInches) || parseInt(formData.heightInInches, 10) < 0 || parseInt(formData.heightInInches, 10) > 11)) {
      errors.heightInInches = 'Inches part of height, if provided, must be a number between 0 and 11';
    }
  }


 // Weight validation
 if (formData.weightType === 'kg') {
  // Validate weight in kilograms
  if (!formData.weightInKg) {
    errors.weightInKg = 'Weight is required';
  } else if (!/^\d+(\.\d+)?$/.test(formData.weightInKg) || parseFloat(formData.weightInKg) < 22 || parseFloat(formData.weightInKg) > 227) {
    errors.weightInKg = 'Weight in kilograms must be a number between 22 and 227';
  }
} else {
  // Validate weight in pounds
  if (!formData.weightInLbs.trim()) {
    errors.weightInLbs = 'Weight is required';
  } else if (!/^\d+(\.\d+)?$/.test(formData.weightInLbs) || parseFloat(formData.weightInLbs) < 48.5 || parseFloat(formData.weightInLbs) > 500) {
    errors.weightInLbs = 'Weight in pounds must be a number between 48.5 and 500';
  }
}


  // Goal validation: Must be selected
  if (!formData.goal || formData.goal.trim() === "" || formData.goal.trim() === "0" || formData.goal.trim() === 0) {
    errors.goal = 'Please select your fitness goal';
  }

  if (!formData.timeSinceIdealWeight || formData.timeSinceIdealWeight.trim() === "") {
    errors.timeSinceIdealWeight = 'Please select how long it has been since you were at your ideal weight';
  }


  // Life events validation: Must select at least one if required
  if (!formData.lifeEvents || formData.lifeEvents.length === 0) {
    errors.lifeEvents = 'Please select at least one life event that has led to weight gain';
  }

  // Ideal Weight validation
  if (formData.idealWeight === '' || formData.idealWeight === undefined) {
    errors.idealWeight = 'Ideal weight is required';
  } else if (isNaN(formData.idealWeight)) {
    errors.idealWeight = 'Ideal weight must be a number';
  } else if (formData.idealWeight < 30 || formData.idealWeight > 300) {
    // Assuming the reasonable range for an ideal weight is between 30kg to 300kg.
    errors.idealWeight = 'Ideal weight must be between 30kg and 300kg';
  }


  // Dietary restrictions and food allergies validation
  if (formData.hasAllergies) {
    if (!formData.allergies || formData.allergies.length === 0) {
      errors.allergies = 'Please select at least one dietary restriction or food allergy';
    }
  }


  if (!formData.prepareYourMeal || formData.prepareYourMeal.trim() === "" || formData.prepareYourMeal.trim() === "0" || formData.prepareYourMeal === 0) {
    errors.prepareYourMeal = 'Please select how you usually prepare your meals';
  }

  if (!formData.activityLevel || formData.activityLevel.trim() === "" || formData.activityLevel.trim() === "0" ||formData.activityLevel === 0) {
    errors.prepareYourMeal = 'Please select how you usually prepare your meals';
  }

  if (formData.workout === "Yes" && (!formData.intensity || formData.intensity.trim() === "" || formData.intensity.trim() === "0" )) {
    errors.intensity = 'Please select your workout intensity';
  }

  if (formData.addWorkout === "Yes" &&(!formData.weightTrainingDuration || formData.weightTrainingDuration === 0 || formData.weightTrainingDuration === "0" )) {
    errors.weightTrainingDuration = 'Please select the duration of weight training';
  }



  if (!formData.trainingDaysPerWeek  || formData.trainingDaysPerWeek === "" || formData.trainingDaysPerWeek === "0" ||formData.trainingDaysPerWeek === 0) {
    errors.trainingDaysPerWeek = 'Please select the number of training days per week';
  } 







    // Check if physicalActivityFactor is valid
    if (!formData.physicalActivityFactor) {
      errors.physicalActivityFactor = 'Physical activity factor is required';
    } else if (isNaN(formData.physicalActivityFactor)) {
      errors.physicalActivityFactor = 'Physical activity factor must be a number';
    }
  
    // Check if thermicEffectOfFoodFactor is valid
    if (!formData.thermicEffectOfFoodFactor) {
      errors.thermicEffectOfFoodFactor = 'Thermic effect of food factor is required';
    } else if (isNaN(formData.thermicEffectOfFoodFactor)) {
      errors.thermicEffectOfFoodFactor = 'Thermic effect of food factor must be a number';
    }
  
    // Check if weightTrainingDuration is valid
    // if (!formData.weightTrainingDuration) {
    //   errors.weightTrainingDuration = 'Weight training duration is required';
    // } else if (isNaN(formData.weightTrainingDuration)) {
    //   errors.weightTrainingDuration = 'Weight training duration must be a number';
    // }
  
    // Check if energyBalanceFactor is valid
    if (!formData.energyBalanceFactor) {
      errors.energyBalanceFactor = 'Energy balance factor is required';
    } else if (isNaN(formData.energyBalanceFactor)) {
      errors.energyBalanceFactor = 'Energy balance factor must be a number';
    }
  
    // Check if trainingDaysPerWeek is valid
    // if (!formData.trainingDaysPerWeek) {
    //   errors.trainingDaysPerWeek = 'Training days per week is required';
    // } else if (isNaN(formData.trainingDaysPerWeek)) {
    //   errors.trainingDaysPerWeek = 'Training days per week must be a number';
    // }
    if (!formData.meal || formData.meal.trim() === "" || formData.meal.trim() === "0") {
      errors.meal = 'Please select a meal plan';
    }

    if (parseInt(formData.bigMeals) !== formData.bigMealsItems.length) {
      errors.bigMealsItems = 'Please select at least one item for each big meal';
    }
    
    // Check if bigMealsItems is empty
    if (formData.bigMealsItems.some(list => list.length === 0)) {
      errors.bigMealsItems = 'Please select at least one item for each big meal';
    }

    // Check if addOnMealsItems is empty
    if (formData.addOnMealsItems.some(list => list.length === 0)) {
      errors.addOnMealsItems = 'Please select at least one item for add-ons';
    }

    if (formData.smallMeals > 0 && parseInt(formData.smallMeals) !== formData.smallMealsItems.length) {
      errors.smallMealsItems = 'Number of small meals and selected items do not match';
    }
    

    // Check if smallMealsItems is empty when smallMeals has a value
    if (formData.smallMeals > 0 && formData.smallMealsItems.some(list => list.length === 0)) {
      errors.smallMealsItems = 'Please select at least one item for each small meal';
      }

    // If there are errors, display SweetAlert with error messages
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Required Fields!',
        text: 'Please fix the following errors:',
        html: Object.values(errors).map(error => `<p>${error}</p>`).join('')
      });
      return;
    }
  
    // If there are no errors, proceed with submitting the form data  
    
    console.log('Form data is valid:', formData);

    setIsLoading(true)
    sendEditDietInfo(formData).then(res => {
          console.log(res);
          setIsLoading(false)
  if(res.statusCode===200){
       setFormData(initialFormData);
        genPdf(res?.data?.id)
            .then(downloadRes => {
                 console.log(downloadRes);

           if(downloadRes.statusCode===200){
                 Swal.fire({
                  icon: 'success',
                  title: 'Successfully Updated User Diet',
                     }).then((result) => {
                  // Check if the user clicked the "OK" button on the success message
                  if (result.isConfirmed || result.isDismissed) {
                      // Redirect to the '/diet/foodData' page
                       history.push('/diet/userDiets');
          
                  }
              });

            }else{
               Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to download PDF',
              });
              history.push('/diet/userDiets');
            }

              })
          .catch(error => {
            console.error('Error downloading PDF:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Failed to download PDF',
            });
          });





    }
    else{
        Swal.fire({
            icon: 'error',
            title: res.message,
        }).then((result) => {
            // Check if the user clicked the "OK" button on the success message
            if (result.isConfirmed || result.isDismissed) {          
                //history.push('/diet/userDiets');
            }
        });
    }



       
        })
        .catch(error => {
            setIsLoading(false)
          console.error('Error sending diet info:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to send diet information',
          });
        });
    };


  return (
    <Box mt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="80%" mx="auto"    p={6} borderRadius="lg" boxShadow="lg" bg="white">
    <SimpleGrid
      
      columns={{ sm: 1, md: 1 }}
      spacing={{ base: "20px", xl: "20px" }}>
          <Box mb={4}>
              <form onSubmit={handleSubmit}>
                  <FormControl flexBasis="48%">
                      <FormLabel>Phone Number</FormLabel>
                      <Input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} readOnly style={{background:'#F4F7FE'}}/>
                  </FormControl>
                  <br></br>
              <hr></hr>
                  {(
                      <EditDietForm
                          encId={id}
                          formData={formData}
                          setFormData={setFormData}
                          handleChange={handleChange}
                        //  handleChangeMeals={handleChangeMeals}
                          foodItems={foodItems}
                          setFoodItems={setFoodItems}
                          addOnItems={addOnItems}
                          setAddOnItems={setAddOnItems}
                          handleCheckboxChange={handleCheckboxChange}
                          handleSubmit={handleSubmit}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                      />
                  )}

                  
              </form>
          </Box>
          </SimpleGrid>
      </Box>
  );
};

 