import React from 'react';
import { Box, Text, Button, Image, VStack } from '@chakra-ui/react';

const MotivationalCard2 = ({ handleGotIt }) => {
  return (
    <VStack
      bg="white"
      p={5}
      rounded="lg"
      boxShadow="md"
      alignItems="center"
      justifyContent="center"
      spacing={4}
    >
      <Text fontSize="lg" fontWeight="bold" textAlign="center">
        Sticking to a plan can be hard. Noom makes it easy.
      </Text>
      <Box
        borderRadius="full"
        boxSize="100px"
        overflow="hidden"
      >
        <Image
          src="/path/to/your/image.jpg" // replace with the path to your image
          alt="Mike"
        />
      </Box>
      <Text fontStyle="italic" textAlign="center">
        "Noom changed the way I think about portion control. It changed my relationship with food." -Mike
      </Text>
      <Text fontSize="sm">
        Noom users that also adopt a healthy lifestyle typically lose 0.5-1 kgs per week.
      </Text>
      <Button
        colorScheme="red"
        size="lg"
        onClick={handleGotIt}
      >
        Got it!
      </Button>
    </VStack>
  );
};

export default MotivationalCard2;
