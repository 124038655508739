import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, Select, Heading, Flex, Button, Stack, Spinner, Text } from '@chakra-ui/react';
import MealSelect from './MealIcon/MealSelect';
import { fetchFoodItemsByCategory } from 'services/foodItemsService';
import Swal from 'sweetalert2';
import AddOnMealSelect from './MealIcon/AddOnMealSelect';
import { AppContext } from 'contexts/AppContext';

const MealStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [selectedMeal, setSelectedMeal] = useState(formData.meal || '');
  const [isValid, setIsValid] = useState(false);
  const [foodItems, setFoodItems] = useState([]);
  const [addOnItems, setAddOnItems] = useState([]);
  const [currentMealStep, setCurrentMealStep] = useState(0); // Current meal step for sequential selection
  const [selectedBigMeals, setSelectedBigMeals] = useState(formData.bigMealsItems || []);
  const [selectedSmallMeals, setSelectedSmallMeals] = useState(formData.smallMealsItems || []);
  const [selectedAddOnMeals, setSelectedAddOnMeals] = useState(formData.addOnMealsItems || []);
  const [loading,setLoading]=useState(false);

  const prevStep = () => {
    // If we're within the range of big meals but not at the first big meal,
    // we should go back to the previous meal step rather than the previous main step
    if (currentMealStep > 0 && currentMealStep <= formData.bigMeals) {
      setCurrentMealStep(currentMealStep - 1);
    } else {
      // This is the first big meal, so we should go to the previous main step
      if (currentStep > 0) {
        setCurrentStep(currentStep - 1);
      }
    }
  };
   // Scroll to the top of the page when currentStep or currentMealStep changes
   useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep, currentMealStep]);
  // Fetch food items by category
  const fetchFoodItems = (category, allergies,ingredients) => {
    fetchFoodItemsByCategory(category, allergies,ingredients)
      .then(res => {
        setFoodItems(res);
      })
      .catch(error => {
        console.error('Error fetching food items:', error);
      });
  };

  const fetchAddOnItems = (category, allergies,ingredients) => {
    fetchFoodItemsByCategory(category, allergies,ingredients)
      .then(res => {
        setAddOnItems(res);
      })
      .catch(error => {
        console.error('Error fetching add-on items:', error);
      });
  };

  // Validation function
  const validateSelections = (selections) => {
    return selections.length >= 3 && selections.length <= 5;
  };

  // Navigate to the next meal selection or the next step
  const nextStepHandler = () => {
    // Determine if we're dealing with big meals, small meals, or add-ons
    let currentSelections = [];
    if (currentMealStep < formData.bigMeals) {
      currentSelections = selectedBigMeals[currentMealStep];
    } else if (currentMealStep < formData.bigMeals + formData.smallMeals) {
      currentSelections = selectedSmallMeals[currentMealStep - formData.bigMeals];
    } else {
      currentSelections = selectedAddOnMeals[0];
    }

    // Validate current selections
    if (validateSelections(currentSelections)) {
      // If it's the last meal step, move to the next form step, otherwise move to the next meal step
      if (currentMealStep === formData.bigMeals + formData.smallMeals) {
        if (currentStep < totalSteps) {
          setCurrentStep(currentStep + 1);
        }
      } else {
        setCurrentMealStep(currentMealStep + 1);
      }
    } else {
      //alert('Please select between 3 to 5 items.');
      Swal.fire({
        title: 'Selection Error',
        text: 'Please select between 3 to 5 items.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  // Re-render meal selections based on the current step
  const renderMealSelections = () => {

    let mealSelectionHeading;
    let stepIndex;
    // Render Big Meal selections
    if (currentMealStep < formData.bigMeals) {
     // mealSelectionHeading = 'Big Meal Selection';
      mealSelectionHeading = "Please select 3 or more dishes you'd like to have Big Meal";
      stepIndex = currentMealStep + 1;
      return (
        <MealSelect
          meals={foodItems}
          selectedMeals={selectedBigMeals[currentMealStep] || []}
          onMealToggle={(meal) => handleMealToggle(meal, 'bigMeals')}
          mainheading={`${mealSelectionHeading} (Step ${stepIndex})`} // Pass the heading with step index
        />
      );
    }
    // Render Small Meal selections
    else if (currentMealStep < formData.bigMeals + formData.smallMeals) {
      //mealSelectionHeading = 'Snack Selection';
      mealSelectionHeading = "Please select 3 or more dishes you'd like to have for Snacks";
      stepIndex = currentMealStep - formData.bigMeals + 1;
      return (
        <MealSelect
          meals={foodItems}
          selectedMeals={selectedSmallMeals[currentMealStep - formData.bigMeals] || []}
          onMealToggle={(meal) => handleMealToggle(meal, 'smallMeals')}
          mainheading={`${mealSelectionHeading}  `} // Pass the heading with step index

        />
      );
    }
    // Render Add-On Meal selections
    else if (currentMealStep === formData.bigMeals + formData.smallMeals) {
      mealSelectionHeading = 'Add-On Meal Selection';
      stepIndex = currentMealStep - formData.bigMeals - formData.smallMeals + 1;
      return ( 
        <AddOnMealSelect
          meals={addOnItems}
          selectedMeals={selectedAddOnMeals[0] || []}
          onMealToggle={(meal) => handleMealToggle(meal, 'addOnMeals')}
          heading={`${mealSelectionHeading} `} // Pass the heading with step index

        />
      );
    }
  };//... existing imports and component setup
  const handleMealToggle = (meal, category) => {
    let updatedSelections;
    let updateFunction;
    let mealIndex;
    let currentIndex;
  
    switch (category) {
      case 'bigMeals':
        updatedSelections = [...selectedBigMeals];
        currentIndex = currentMealStep < formData.bigMeals ? currentMealStep : 0;
        updateFunction = setSelectedBigMeals;
        break;
      case 'smallMeals':
        updatedSelections = [...selectedSmallMeals];
        currentIndex = currentMealStep >= formData.bigMeals && currentMealStep < formData.bigMeals + formData.smallMeals ? 
                        currentMealStep - formData.bigMeals : 0;
        updateFunction = setSelectedSmallMeals;
        break;
      case 'addOnMeals':
        updatedSelections = [...selectedAddOnMeals];
      // currentIndex = currentMealStep >= formData.bigMeals + formData.smallMeals ? 
      //           currentMealStep - formData.bigMeals - formData.smallMeals : 0;
      currentIndex=0;
        updateFunction = setSelectedAddOnMeals;
        break;
      default:
        return;
    }
  
    // Ensure the array for the current index is initialized
    if (!updatedSelections[currentIndex]) {
      updatedSelections[currentIndex] = [];
    }
  
    // Find the meal in the current selections array
    mealIndex = updatedSelections[currentIndex].findIndex(selectedMeal => selectedMeal.id === meal.id);
  
    if (mealIndex > -1) {
      // Remove meal if already selected
      updatedSelections[currentIndex].splice(mealIndex, 1);
    } else {
      // Add meal if not already selected
      updatedSelections[currentIndex].push(meal);
    }
  
    // Update the appropriate state based on the category
    updateFunction(updatedSelections);
  
    // Update formData with the new meal selections
    setFormData({
      ...formData,
      [`${category}Items`]: updatedSelections
    });
  };
  
//... rest of the component

// const handleMealChange = (event) => {
//   const selectedValue = event.target.value;
//   const [bigMealsIndex, smallMealsIndex] = selectedValue.split('-').map(Number);
//   const addOnMealsIndex=1;
//   setSelectedMeal(selectedValue);
//   setFormData({
//     ...formData,
//     meal: selectedValue,
//     bigMeals: bigMealsIndex,
//     smallMeals: smallMealsIndex,
//     addOnMeals: addOnMealsIndex,
//     bigMealsItems: Array.from({ length: bigMealsIndex }, () => []),
//     smallMealsItems: Array.from({ length: smallMealsIndex }, () => []),
//     addOnMealsItems: Array.from({ length: addOnMealsIndex }, () => [])
//   });
//   setCurrentMealStep(0); // Reset to the first meal step
//   setIsValid(false); // Reset validation

//   // Reset the selections
//   setSelectedBigMeals(Array.from({ length: bigMealsIndex }, () => []));
//   setSelectedSmallMeals(Array.from({ length: smallMealsIndex }, () => []));
//   setSelectedAddOnMeals(Array.from({ length: addOnMealsIndex }, () => []));
// };

const selectMealOnLoad = () => {
   const [bigMealsIndex, smallMealsIndex] = formData.meal.split('-').map(Number);
  const addOnMealsIndex=1;
  setSelectedMeal(formData.meal);
  setFormData({
    ...formData,
    meal: formData.meal,
    bigMeals: bigMealsIndex,
    smallMeals: smallMealsIndex,
    addOnMeals: addOnMealsIndex,
    bigMealsItems: Array.from({ length: bigMealsIndex }, () => []),
    smallMealsItems: Array.from({ length: smallMealsIndex }, () => []),
    addOnMealsItems: Array.from({ length: addOnMealsIndex }, () => [])
  });
  setCurrentMealStep(0); // Reset to the first meal step
  setIsValid(false); // Reset validation

  // Reset the selections
  setSelectedBigMeals(Array.from({ length: bigMealsIndex }, () => []));
  setSelectedSmallMeals(Array.from({ length: smallMealsIndex }, () => []));
  setSelectedAddOnMeals(Array.from({ length: addOnMealsIndex }, () => []));
};


  
  // Effect for fetching food items based on user's allergies
  useEffect(() => {


  const fetchData = async () => {
    try {

      setLoading(true)
      // Create an array of promises
      const fetchPromises = [
      fetchFoodItems('BIGMEAL',formData.allergies,formData.ingredients),
      fetchAddOnItems('ADDON',formData.allergies,formData.ingredients)
      ];

      // Wait for all fetch operations to complete

      
      await Promise.all(fetchPromises);
     


      const [bigMealsIndex, smallMealsIndex] = formData.meal.split('-').map(Number);
      if(bigMealsIndex!==formData.bigMealsItems.length || smallMealsIndex!==formData.smallMealsItems.length){
        await  selectMealOnLoad();
      }
 
 
      // Once all fetches are complete, fetch the main food data
    } catch (error) {
      console.error("Failed to fetch data:", error);
     }
     finally {
      setLoading(false); // Set loading to false after fetching completes
    }
  };

  fetchData();



  }, []);
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(19)
}, []);
//for setting question no end
  return (
    <div   >
      {/* <FormControl isRequired textAlign="center" pl={10} pr={10}>
        <Heading  textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
          Which of the following best describes how often you eat in a typical day?*
        </Heading>
        <Select
        mt={5}
          id='meal'
          value={selectedMeal}
          onChange={handleMealChange}
          placeholder='Select...'
          fontSize="lg"
          height="40px"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
          _placeholder={{ fontSize: 'md', color: 'gray.500' }}
        >
          <option value="2-1">2 big meals with a small snack</option>
          <option value="3-1">3 big meals with a snack</option>
          <option value="3-0">3 big meals</option>
          <option value="4-0">4 meals</option>
        </Select>
      </FormControl> */}
     
      <Flex direction="column" justifyContent="space-between" mt={4} pl={10} pr={10} >

      {loading ? (
        <Flex direction="column" justify="center" align="center" height="100%">
          <Spinner size="xl" color="teal.500" mb={4} />
          <Text fontSize="lg" color="teal.600">Loading...</Text>
        </Flex>
      ) : (
        renderMealSelections()

      )}
      </Flex>

  

       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
      <Button  className='default_back_button' 
    colorScheme='teal'
    variant='outline'
    onClick={prevStep}
    isDisabled={currentStep === 0 && currentMealStep === 0} // Disabled only if it's the very first step
  >
    Back
  </Button>
        <Button colorScheme='teal'  className='default_next_button'  variant='outline' onClick={nextStepHandler} >
          {currentMealStep < formData.bigMeals + formData.smallMeals ? "Next" : "Next"}
        </Button>
      </Stack>
      <br></br>
      <br></br>
    </div>
  );
};

export default MealStep;
