import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  Button,
  Heading,
  Stack,
  Radio,
  RadioGroup,
  Box,
  useRadioGroup,
  useRadio,
  useColorModeValue,
} from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

export const durations = [
  { id: '0-6', title: 'Less than 6 months' },
  { id: '6-12', title: '6 - 12 months' },
  { id: '1-4y', title: '1 - 4 years' },
  { id: '4plus', title: 'More than 4 years' },
];

// Custom radio card component
const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const bg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = "black";
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  return (
    <Box as='label' w="full">
      <input {...input} />
      <Box
        {...checkbox}
        bg={ bg}
        borderColor={borderColor}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
          mb={'10px'}
        _checked={{
          bg: selectedBg,
          borderColor: 'teal.500',
          color: 'white',
        }}
         display={'flex'}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const TimeSinceIdealWeightStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedDuration, setSelectedDuration] = useState(formData.timeSinceIdealWeight);

  // Validation on load
  useEffect(() => {
    // If formData already has a valid duration, use it; otherwise default to the first option
    if (formData.timeSinceIdealWeight) {
       setSelectedDuration(formData.timeSinceIdealWeight);
     }
  }, [formData.timeSinceIdealWeight]);



  const handleDurationChange = (value) => {
    setSelectedDuration(value);
    setFormData({ ...formData, timeSinceIdealWeight: value });
  };


  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "timeSinceIdealWeight",
    defaultValue: selectedDuration,
    onChange: handleDurationChange,
  });
  const group = getRootProps();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(11)
}, []);
//for setting question no end
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10}
    
    className='bottomImage'
    >
      {/* <Heading as="h3" size="lg" textAlign="center" mb={6} color={'teal'}>
        How long has it been since you were at your ideal weight?
      </Heading> */}
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
      How long has it been since you were at your ideal weight?
      </Heading>
      <RadioGroup onChange={handleDurationChange} value={selectedDuration}> 
      <Box {...group}>
        {durations.map((duration) => (
          <RadioCard key={duration.id} {...getRadioProps({ value: duration.id })}  >
          <Radio value={duration.id}   className='default_radio_input1 default_input' mr={5} />   
          <div>{duration.title}</div>
          </RadioCard>
        ))}
      </Box>

      </RadioGroup>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={handleNext} isDisabled={!selectedDuration}>
          Next
        </Button>
       
      </Stack> 
      <br></br>
        <br></br>
    </VStack>
  );
};

export default TimeSinceIdealWeightStep;
