import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  Button,
  Heading,
  Stack,
  Radio,
  RadioGroup,
  Box,
  useRadioGroup,
  useRadio,
  useColorModeValue,
} from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

export const prepareMeals = [
  {  title: 'I cook them myself' },
  {  title: 'Someone else cooks for me' },
  {  title: 'I order from restaurants' },
  {  title: 'I eat premade meals' },
];

// Custom radio card component
const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const bg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg ="black";
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  return (
    <Box as='label' w="full">
      <input {...input} />
      <Box
        {...checkbox}
        bg={ bg}
        borderColor={borderColor}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
          mb={'10px'}
        _checked={{
          bg: selectedBg,
          borderColor: 'teal.500',
          color: 'white',
        }}
        display={'flex'}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const PrepareYourMealStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedPrepareMeal, setSelectedPrepareMeal] = useState(formData.prepareYourMeal);

  // Validation on load
  useEffect(() => {
    // If formData already has a valid duration, use it; otherwise default to the first option
    if (formData.prepareYourMeal) {
       setSelectedPrepareMeal(formData.prepareYourMeal);
     }
  }, [formData.prepareYourMeal]);



  const handlePrepareMealChange = (value) => {
    setSelectedPrepareMeal(value);
    setFormData({ ...formData, prepareYourMeal: value });
  };


  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "prepareYourMeal",
    defaultValue: selectedPrepareMeal,
    onChange: handlePrepareMealChange,
  });
  const group = getRootProps();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(14)
}, []);
//for setting question no end
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10}
    
    className='bottomImage'
    >
      {/* <Heading as="h3" size="lg" textAlign="center" mb={6} color={'teal'}>
        How do you usually prepare your meals?
      </Heading> */}
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
      How do you usually prepare your meals?
      </Heading>

      <RadioGroup onChange={handlePrepareMealChange} value={selectedPrepareMeal}> 
      <Box {...group}>
        {prepareMeals.map((prepareMeal) => (
          <RadioCard key={prepareMeal.title} {...getRadioProps({ value: prepareMeal.title })}>
          <Radio value={prepareMeal.title}   className='default_radio_input1 default_input' mr={5} />  
            {prepareMeal.title}
          </RadioCard>
        ))}
      </Box>

      </RadioGroup>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={handleNext} isDisabled={!selectedPrepareMeal}>
          Next
        </Button>
      </Stack> 
      <br></br>
      <br></br>
    </VStack>
  );
};

export default PrepareYourMealStep;
