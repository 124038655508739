import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Text,
  CheckboxGroup,
  Checkbox
} from '@chakra-ui/react';
import { fetchfoodAllergies } from 'services/foodItemsService';
import Multiselect from 'multiselect-react-dropdown';
 
const EditDietaryRestrictionsStep = ({ formData, setFormData }) => {
  const [allergiesList, setAllergiesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchfoodAllergies().then(data => {
      setAllergiesList(data.map(allergy => ({ label: allergy.allergyName, value: allergy.id.toString() }))); // Ensure consistency in value type
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching allergies:', error);
      setLoading(false);
    });
  }, []);

  // Handler for the select dropdown
  const handleHasAllergiesChange = (e) => {
    const hasAllergies = e.target.value === 'true';
    setFormData({
      ...formData,
      hasAllergies,
      allergies: hasAllergies ? formData.allergies : [],
    });
  };

  // Handler for the multi-select dropdown
  const handleAllergyChange = (selectedList) => {
    setFormData({
      ...formData,
      allergies: selectedList.map(item => item.value),
      bigMealsItems: [],
      smallMealsItems: [],
      addOnMealsItems: []
 
    });
  };

  return (
    <FormControl display={'flex'} flexDirection={'column'} flexBasis="48%">
      <FormLabel>Do you have any dietary restrictions or food allergies?</FormLabel>
      <Select placeholder="Select option" value={String(formData.hasAllergies)} onChange={handleHasAllergiesChange}>
        <option value="true">Yes</option>
        <option value="false">No</option>
      </Select>

      {formData.hasAllergies && (
        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm" mt={4}>
          <Text>Select all that apply:</Text>
          {loading ? (
            <Text>Loading allergies...</Text>
          ) : (
            <Multiselect
              options={allergiesList}
              selectedValues={allergiesList.filter(item => formData.allergies.includes(item.value))}
              onSelect={handleAllergyChange}
              onRemove={handleAllergyChange}
              displayValue="label"
              style={{ chips: { background: 'teal' } }}
            />
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default EditDietaryRestrictionsStep;
