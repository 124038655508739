import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Radio,
  RadioGroup,
  Button,
  Heading,
  Stack,
  Box,
  useRadioGroup,
  useRadio,
  useColorModeValue
} from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

const ExerciseTimeStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedTime, setSelectedTime] = useState(formData.weightTrainingDuration || '');

  const exerciseOptions = [
    { id: '15', title: '15 mins' },
    { id: '30', title: '30 mins' },
    { id: '45', title: '45 mins' },
    { id: '60', title: '1 hour' }
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'weightTrainingDuration',
    defaultValue: selectedTime,
    onChange: (value) => {
      setSelectedTime(value);
      setFormData({ ...formData, weightTrainingDuration: value });
    }
  });
  const group = getRootProps();

  // Custom radio card component
  const RadioCard = (props) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();
  
    const bg = useColorModeValue('gray.100', 'gray.700');
    const selectedBg = "black";
    const borderColor = useColorModeValue('gray.200', 'gray.500');
   //for setting question no start
   const { setQuestionNo,setDisplayheader } = useContext(AppContext);
   useEffect(() => {
    setDisplayheader(true);
  setQuestionNo(27)
}, []);
//for setting question no end
    return (
      <Box as='label' w="full">
        <input {...input} />
        <Box
          {...checkbox}
          bg={bg}
          borderColor={borderColor}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          mb={'10px'}
          _checked={{
            bg: selectedBg,
            borderColor: 'teal.500',
            color: 'white',
          }}
          display={'flex'}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    );
  };

  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10}   className='bottomImage'>
      <Heading as="h5" size="md" textAlign="left" mb={1}>
        How much time can you devote to exercise every day?
      </Heading>

      <RadioGroup defaultValue={selectedTime} value={selectedTime}>
        <Box {...group}>
          {exerciseOptions.map((option) => (
            <RadioCard   key={option.id} {...getRadioProps({ value: option.id })}>
              <Radio   className='default_radio_input1 default_input' value={option.id} mr={5} /> {option.title}
            </RadioCard>
          ))}
        </Box>
      </RadioGroup>

      <Stack direction='row' justifyContent='space-around' alignItems='center' p={4}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={() => setCurrentStep(currentStep + 1)} isDisabled={!selectedTime}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default ExerciseTimeStep;
