import React, { useState, useEffect, useContext } from 'react';
import { VStack, Button, Heading, FormControl, FormErrorMessage, Box, Image, Text, Badge, Stack, SimpleGrid, Flex, Spinner } from '@chakra-ui/react';

import { fetchAllFoodIngredient, fetchFoodItemsByDisplay } from 'services/foodItemsService';
import { fetchDisplayFoodIngredient } from 'services/foodItemsService';
import { AppContext } from 'contexts/AppContext';

const IngredientsSelectorStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(formData.ingredients || []);
  const previewUrl = process.env.REACT_APP_PREVIEW_URL;
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {

        setLoading(true)
        const [ingredients, items] = await Promise.all([
          fetchDisplayFoodIngredient(),
          fetchFoodItemsByDisplay()
        ]);
        setLoading(false)
        const processedIngredients = ingredients.map(ing => ({
          id: `ing-${ing.id}`,
          name: ing.ingredientName,
          type: 'Ingredient',
          imageUrl: previewUrl+ing.ingredientIconPath // Assuming imageUrl is part of the ingredient data
        }));

        const processedItems = items.map(item => ({
          id: `item-${item.id}`,
          name: item.subHead,
          type: 'Food Item',
          imageUrl: previewUrl+item.foodIconPath // Assuming imageUrl is part of the item data
        }));

        const combinedOptions = [...processedIngredients, ...processedItems];
        setOptions(combinedOptions);

        if (formData.ingredients) {
          const preSelectedOptions = combinedOptions.filter(option =>
            formData.ingredients.includes(option.id)
          );
          setSelectedOptions(preSelectedOptions);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleOptionToggle = (option) => {
    const isCurrentlySelected = selectedOptions.some(selectedOption => selectedOption.id === option.id);
    const updatedSelectedOptions = isCurrentlySelected
      ? selectedOptions.filter(selectedOption => selectedOption.id !== option.id)
      : [...selectedOptions, option];
    setSelectedOptions(updatedSelectedOptions);
    setFormData({ ...formData, ingredients: updatedSelectedOptions.map(opt => opt.id) });
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    if (formData.drink === 'none') {
    setCurrentStep(currentStep - 2);
    }else{
    setCurrentStep(currentStep - 1);

    }
  };

  const isValid = selectedOptions.length > 0;
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(17)
}, []);
//for setting question no end
  return (
    <>
     
      <FormControl id="options" isInvalid={!isValid}  pl={10} pr={10}>
       
      
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
      {/* Select Ingredients and Items */}
      Please let us know the food 
items you generally eat & would 
like to incorporate in your diet. 
      </Heading>
      <p  textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>
      This will help us understand what diet to give you.
      
      </p>   
         <p  textAlign="left" style={{fontSize:'16px'}}    mb={6} color={'#b2b2b'}>
       <b>  Select all that you eat -</b>
      
      </p>

        {/* <Box display="flex" flexWrap="wrap" justifyContent="centr"> */}
        {loading ? (
        <Flex direction="column" justify="center" align="center" height="100%">
          <Spinner size="xl" color="teal.500" mb={4} />
          <Text fontSize="lg" color="teal.600">Loading...</Text>
        </Flex>
      ) : (
        <SimpleGrid columns={3} spacing={2}>
          {options.map((option) => (
            <Box
              key={option.id}
              onClick={() => handleOptionToggle(option)}
              minWidth="70px"
              maxWidth="70px"
              p="1"
              m="2"
              alignItems={'center'}
              // border="1px solid #ddd"
              borderRadius="8px"
              display="inline-block"
              bg={selectedOptions.some(selectedOption => selectedOption.id === option.id) ? 'teal.100' : 'white'}
              boxShadow={selectedOptions.some(selectedOption => selectedOption.id === option.id) ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0'}
             

              cursor="pointer"
            >
              <Image
                src={option.imageUrl}
                alt={option.name}
                objectFit="cover"
                height="70px"
                border="1px solid #ddd"
                borderRadius="8px"
              />
              {/* {selectedOptions.some(selectedOption => selectedOption.id === option.id) && (
                <Badge position="absolute" top="1" right="1" colorScheme="green">
                  Selected
                </Badge>
              )} */}
              <Text p="1" isTruncated textAlign="center">
               <b> {option.name}</b>
              </Text>
            </Box>
          ))}
        {/* </Box> */}
        </SimpleGrid>

      )}
        {!isValid && <FormErrorMessage>Please select at least one item.</FormErrorMessage>}
      </FormControl>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button  className='default_next_button' colorScheme="teal" onClick={handleNext} isDisabled={!isValid}>
          Next
        </Button>
      </Stack>
      <br></br>
      <br></br>
    </>
  );
};

export default IngredientsSelectorStep;
