import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, Input, ButtonGroup, Button, Stack, Text, Heading, Image } from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_left_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const WeightStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [weight, setWeight] = useState('');
  const [isMetric, setIsMetric] = useState(formData.weightType ? formData.weightType === 'kg' : true);
  const [isValidWeight, setIsValidWeight] = useState(false);

  // Helper functions for conversion and validation
  const poundsToKilograms = (pounds) => pounds / 2.20462;
  const kilogramsToPounds = (kilograms) => kilograms * 2.20462;
  const validateWeight = (weightInKg) => weightInKg >= 22 && weightInKg <= 227;

  useEffect(() => {
    // On component mount, check if there's existing weight data and initialize accordingly
    const initWeight = isMetric ? formData.weightInKg : formData.weightInLbs;
    if (initWeight) {
      setWeight(initWeight.toString());
      setIsValidWeight(validateWeight(isMetric ? initWeight : poundsToKilograms(initWeight)));
    }
  }, []); // Dependencies intentionally left out to run only once at mount

  // Handle unit toggle
  const handleUnitToggle = (metric) => {
    setIsMetric(metric);
    setWeight(''); // Clear weight input on unit toggle
    setIsValidWeight(false); // Reset validation state
    setFormData({ ...formData, weightType: metric ? 'kg' : 'lbs' }); // Update formData with current unit selection
  };

  const handleWeightChange = (e) => {
    const newWeight = e.target.value;
    setWeight(newWeight);

    // Convert to kg for validation purposes
    let weightInKg = isMetric ? parseFloat(newWeight) : poundsToKilograms(parseFloat(newWeight));
    const isValid = validateWeight(weightInKg);
    setIsValidWeight(isValid);

    if (isValid) {
      // Update formData with both kg and lbs values for reuse
      setFormData({
        ...formData,
        weightInKg: isMetric ? weightInKg : weightInKg.toFixed(2),
        weightInLbs: isMetric ? kilogramsToPounds(weightInKg).toFixed(2) : newWeight,
        weightType: isMetric ? 'kg' : 'lbs',
      });
    }
  };

  const navigateStep = (stepChange) => {
    const nextStep = currentStep + stepChange;
    if (nextStep > 0 && nextStep <= totalSteps) {
      setCurrentStep(nextStep);
    } else if (nextStep < currentStep) {
      // Allow going back even if weight is not valid
      setCurrentStep(currentStep - 1);
    }
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(6)
}, []);
//for setting question no end
  return (
    <>
      <FormControl isRequired display={'flex'} flexDirection={'column'}  pl={10} pr={10} className='bottomLeftImage'>
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>What's your Weight ? </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>Mention in kgs. Eg. 84 </p>
<br></br>
      {/* <Heading   textAlign="left" as="h3" size="lg"   mb={6} color={'teal'}>What's your Weight ?</Heading> */}
        <ButtonGroup isAttached variant='solid'>
          <Button
            mr="-px"
            border='1px'
            colorScheme={!isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitToggle(false)}
          >
            LBS
          </Button>
          <Button
            border='1px'
            colorScheme={isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitToggle(true)}
          >
            KG
          </Button>
        </ButtonGroup>
        <Input
         width="150px"
          type="number"
          placeholder={isMetric ? 'Weight in kg' : 'Weight in lbs'}
          value={weight}
          onChange={handleWeightChange}
          mt={3}
          className='default_input'
        />
        <Text mt={2} >
            valid weight (22-227 kg or 48.5-500 lbs) 
        </Text>
      {/* </FormControl> */}
      <br></br>
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button'   colorScheme='teal' variant='outline' onClick={() => navigateStep(-1)}>
          Back
        </Button>
        <Button
         className='default_next_button' 
          colorScheme='teal'
          variant='outline'
          onClick={() => navigateStep(1)}
          isDisabled={!isValidWeight}
        >
          Next
        </Button>
      </Stack>
      </FormControl>
      {/* <Image src={down_backgd_img} alt="Good hands"   boxSize="300px"   /> */}
    </>
  );
};

export default WeightStep;

