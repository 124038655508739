import React, { useState, useEffect, useContext } from 'react';
import { VStack, Box, Text, Image, Button } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { sendDietInfo } from 'services/foodItemsService';
import { initialFormData } from '..';
import { AppContext } from 'contexts/AppContext';
import subittingHand from 'assets/img/subittingHand.png'; // Replace with your actual image path

const SubmissionConfirmation = ({ formData, setFormData, currentStep, setCurrentStep })  => {
  const [isLoading, setIsLoading] = useState(true);
  const previewUrl=process.env.REACT_APP_PREVIEW_URL;

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
  useEffect(() => {
    const submitData = async () => {
      setIsLoading(true); // Activate loading state
      try {

        console.log(formData)
        const res = await sendDietInfo(formData);
        if (res.statusCode === 200) {
          setIsLoading(false); // Deactivate loading state on success
          // Reset form data
         setFormData(initialFormData);
          // Show success message and redirect
          // Swal.fire({ 
          //   icon: 'success',
          //   title: res.message,
          // }).then(() => {
          //  // window.open(previewUrl + res.data.dietFilePath, '_blank');
          // // setCurrentStep(1); // Reset to the first step
          // }); 
        } else {
          throw new Error(res.message);
        } 
      } catch (error) {
        setIsLoading(false); // Deactivate loading state on error
        Swal.fire({
          icon: 'error',
          title: 'Submission Failed!',
          text: error.toString(),
        });
      }
    };

    submitData();
  }, []);
  const { setQuestionNo,setDisplayheader } = useContext(AppContext);
  useEffect(() => {
   setDisplayheader(false);

  });



  // if (isLoading) {
  //   return <Text>Submitting please wait ...</Text>; // Loading indicator
  // }

  return (
    <VStack
    
    spacing={4}
      align="stretch"
      p={5}
        color="white"  // Text color set to white
       boxShadow="xl"
      minH="80vh"
      justify="center"
      background={'#161616'}
    >
    
{
  isLoading ? <>
  <Text 
        sx={{
          background: "linear-gradient(to right, #52f0c8 0%, #028fab 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text"
        }}
        p={10}
        fontSize="5xl" fontWeight="extrabold"         
 mb={3}>
Submitting please wait .....
        </Text>

  </>:


      <Box textAlign="center" p={5}>
        
        <Text 
        sx={{
          background: "linear-gradient(to right, #52f0c8 0%, #028fab 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text"
        }}
        
        fontSize="5xl" fontWeight="extrabold"         
 mb={3}>
          Thank you for submitting the form.
        </Text>
        <Text fontSize="md">
          We've received your details & we'll be sending you a confirmation message on your Whatsapp soon along with your diet plan! You'll hear more on your fitness plan from us through Whatsapp too.
        </Text>
        <Box mt={5} display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Image  src={subittingHand} alt="Thumbs Up" width={'250px'}  />
        </Box>
        {/* <Button colorScheme="teal" onClick={handleBack}>Go Back to Start</Button> */}
      </Box>
}
    </VStack>
  );
};

export default SubmissionConfirmation;
