import React, { useState, useEffect, useContext } from 'react';
import {
  VStack,
  Checkbox,
  CheckboxGroup,
  Button,
  Heading,
  Stack,
  Flex,
  Spinner,
  Text} from '@chakra-ui/react';
import { fetchlifeEvents } from 'services/foodItemsService';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const LifeEventsSelectorStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedEvents, setSelectedEvents] = useState(formData.lifeEvents || []);
 
  // Simulating an API call to fetch life events from a database
  const [lifeEvents, setLifeEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  
  useEffect(() => {
    // Fetch life events from the API
    setLoading(true)
    fetchlifeEvents()
      .then(res => {

        setLifeEvents(res);

        // If formData.lifeEvents has values, set them as selected
        if (formData.lifeEvents && formData.lifeEvents.length > 0) {
          const preSelectedEvents = formData.lifeEvents.map(event => event.toString());
          setSelectedEvents(preSelectedEvents);
        }

        setLoading(false);
      })
      .catch(error => {
       console.log(error)
       setLoading(false);
      });
  }, []);
  //}, [formData]);
  

  const handleEventSelection = (events) => {
    setSelectedEvents(events);
    setFormData({ ...formData, lifeEvents: events });
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1); // Move to the next step
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(12)
}, []);
//for setting question no end
  return (
    <VStack spacing={4} align="stretch"  pl={10} pr={10}
       
       className='bottomImage'
    
    >
     
     <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>Have any of these factors led to weight gain/loss in the last few years? </Heading>
      <p  textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>Select all that apply</p>
      
      {/* <Heading as="h3" size="lg" textAlign="center" color={'teal'}>Have any of these factors led to weight gain/loss in the last few years?</Heading> */}
      {loading ? (
        <Flex justify="center" align="center" height="100%">
          <Spinner size="xl" color="teal.500" />
          <Text fontSize="lg" color="teal.600">Loading...</Text> 
        </Flex>
      ) : (
     
     
      <CheckboxGroup  colorScheme="teal"   value={selectedEvents} onChange={handleEventSelection}>
        {lifeEvents.map((event) => (
          <Checkbox className='default_checkbox1' key={event.id} value={event.id+""} pl={4} pr={4} pt={3} pb={3}>
            {event.eventDescription}
          </Checkbox>
        ))}
      </CheckboxGroup>

      )}
      <br></br>
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button  className='default_back_button'  colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button  className='default_next_button'  colorScheme="teal" onClick={handleNext} isDisabled={selectedEvents.length === 0}>
          Next
        </Button>
      </Stack>
      <br></br>
      <br></br>
    </VStack>
  );
};

export default LifeEventsSelectorStep;
