import React, { useState, useEffect, useContext } from 'react';
import { FormControl, FormLabel, Input, ButtonGroup, Button, Stack, Text, Heading, Image } from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_left_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const HeightStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [isMetric, setIsMetric] = useState(formData.heightType ? formData.heightType === 'cm' : true);
  const [metricHeight, setMetricHeight] = useState('');
  const [feet, setFeet] = useState('');
  const [inches, setInches] = useState('');
  const [isValidHeight, setIsValidHeight] = useState(false);

  useEffect(() => {
    // Initialize values based on formData and unit type
    if (formData.heightType === 'cm') {
      setMetricHeight(formData.heightInCm || '');
      setIsValidHeight(formData.heightInCm >= 60 && formData.heightInCm <= 300);
    } else {
      setFeet(formData.heightInFeet || '');
      setInches(formData.heightInInches || '');
      const cmValue = feetInchesToCm(formData.heightInFeet, formData.heightInInches);
      setIsValidHeight(cmValue >= 60 && cmValue <= 300);
    }
  }, [formData]);

  const feetInchesToCm = (feet, inches) => (parseInt(feet) * 12 + parseInt(inches)) * 2.54;

  const handleUnitChange = (metric) => {
    setIsMetric(metric);
    // Clear input fields to prompt for new values
    setMetricHeight('');
    setFeet('');
    setInches('');
    setIsValidHeight(false);
    // Optionally clear or retain formData here based on design choice
  };

  const updateFormDataForMetric = (cmValue) => {
    setFormData({ ...formData, heightInCm: cmValue, heightType: 'cm',heightInFeet:'',heightInInches:'' });
  };

  const updateFormDataForImperial = (feet, inches) => {
    const cmValue = feetInchesToCm(feet, inches).toFixed(2);
    setFormData({
      ...formData,
      heightInFeet: feet,
      heightInInches: inches,
      heightInCm: '',
      heightType: 'ft',
    });
  };

  const handleMetricHeightChange = (e) => {
    const value = e.target.value;
    setMetricHeight(value);
    const isValid = value >= 60 && value <= 300;
    setIsValidHeight(isValid);
    if (isValid) {
      updateFormDataForMetric(value);
    }
  };

  const handleFeetChange = (e) => {
    const value = e.target.value;
    setFeet(value);
    if (isValidHeight || inches) {
      updateFormDataForImperial(value, inches);
    }
  };

  const handleInchesChange = (e) => {
    const value = e.target.value;
    setInches(value);
    if (isValidHeight || feet) {
      updateFormDataForImperial(feet, value);
    }
  };

  const nextStep = () => {
    if (currentStep < totalSteps && isValidHeight) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(5)
}, []);
//for setting question no end
  return (
    <>
      <FormControl  display={'flex'} flexDirection={'column'}  pl={10} pr={10} className='bottomLeftImage'>
      {/* <Heading   textAlign="left" as="h3" size="lg"   mb={6} color={'teal'}>What's your Height ?</Heading> */}
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>Tell us your height </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>Mention in cms. Eg. 175 </p>
     
     <br />
        <ButtonGroup isAttached variant='solid'>
          <Button
            mr="-px"
            border='1px'
            colorScheme={!isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitChange(false)}
          >
            FT/IN
          </Button>
          <Button
            border='1px'
            colorScheme={isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitChange(true)}
          >
            CM
          </Button>
        </ButtonGroup>
        {isMetric ? (
          <Input
            type="number"
            width="150px"
            placeholder="000"
            value={metricHeight}
            onChange={handleMetricHeightChange}
            mt={3}
            className='default_input'
          />
        ) : (
          <>
            <Input
              type="number"
              width="150px"
              placeholder="Feet"
              value={feet}
              onChange={handleFeetChange}
              mt={3}
              className='default_input'
            />
            <Input
              type="number"
              width="150px"
              placeholder="Inches"
              value={inches}
              onChange={handleInchesChange}
              mt={3}
              className='default_input'
            />
          </>
        )}
        <Text mt={2}>
          Valid height is {isMetric ? '60-300 cm' : "2'-9'11\""}
        </Text>
      {/* </FormControl> */}
 
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button  className='default_back_button'  colorScheme='teal' variant='outline' onClick={prevStep}>
          Back
        </Button>
        <Button
         className='default_next_button' 
          colorScheme='teal'
          variant='outline'
          onClick={nextStep}
          isDisabled={!isValidHeight}
        >
          Next
        </Button>
      </Stack>
      </FormControl>
      {/* <Image src={down_backgd_img} alt="Good hands"   boxSize="300px"   /> */}
    </>
  );
};

export default HeightStep;
