import React, { useContext, useEffect } from 'react';
import { Box, Button, Text, Heading, VStack, Progress, SimpleGrid, Icon, Image, Flex } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';  // Ensure you have this context setup
import BehaviouralPsychology from 'assets/img/BehaviouralPsychology.png'
import NutritionalScience from 'assets/img/NutritionalScience.png'
import strength from 'assets/img/strength.png'

const StrengthEnduranceCard = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Hide header based on AppContext
  const { setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(false);
  }, [setDisplayheader]);

  return (
    <VStack
      spacing={4}
      align="stretch"
      p={5}
        color="white"  // Text color set to white
       boxShadow="xl"
      minH="80vh"
      justify="center"
      background={'rgb(87 197 202)'}
    >
    <br></br>
      <Heading textAlign="center" fontSize="xl" >
      80% of Our Clients Saw 
Results Within 3 Months 
With Strength Training
      </Heading>
     
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}   width={'100%'} >
      <Image
        src={strength} // Replace with your actual logo path
        alt="strength"
   
        width={'85%'}
         align={'center'}
    
      />
    </Box>
 
      <Text textAlign="center" fontSize="md" mt={2} mb={4}>
      We incorporate at min some amount of basic 
Strength & Endurance Training as a part of our 
program for people of all ages. The purpose 
isn t to tone your body but to improve your 
overall health. You will see overall benefits like 
increase in your sleep quality, handling your 
day to day tasks better, etc. These benefits 
have a trickle down effect.           </Text>
      <br></br>
      <Flex mt={8} mb={20} width="100%" justifyContent="space-around" flexDirection={'row'} alignItems={'center'}>
     <Button className='default_back_button' bg="gray.700" _hover={{ bg: 'gray.600' }} onClick={backStep}>
        Back
      </Button>  
      <Button className='default_next_button_for_special' background={'white'} color={'grey'} width={'50%'} alignSelf={'center'} borderRadius={'5'} onClick={nextStep}  >
        Continue
      </Button>
    </Flex>

      {/* <Button background={'white'} color={'grey'} width={'50%'} alignSelf={'center'} borderRadius={'5'} onClick={nextStep} size="lg">
        Continue
      </Button> */}
    </VStack>
  );
};

export default StrengthEnduranceCard;
