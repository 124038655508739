import React, { useContext, useEffect, useState } from 'react';
import { Container, Progress, Heading, Stack, Text, Box } from '@chakra-ui/react';
import NameStep from './Fields/NameStep';
import GenderStep from './Fields/GenderStep';
import AgeStep from './Fields/AgeStep';
import HeightStep from './Fields/HeightStep';
import Swal from 'sweetalert2';
import WeightStep from './Fields/WeightStep';
import FatPercentageStep from './Fields/FatPercentageStep';
import LifestyleIssuesStep from './Fields/LifestyleIssuesStep';
import PhoneNumberStep from './Fields/PhoneNumberStep';
import FitnessGoalSelectorStep from './Fields/FitnessGoalSelectorStep';
import TimeSinceIdealWeightStep from './Fields/TimeSinceIdealWeightStep';
import LifeEventsSelectorStep from './Fields/LifeEventsSelectorStep';
import IdealWeightInputStep from './Fields/IdealWeightInputStep';
import DietaryRestrictionStep from './Fields/DietaryRestrictionsStep';
import PrepareYourMealStep from './Fields/PrepareYourMealStep';
 import ActivityLevelStep from './Fields/ActivityLevelStep';
 import AddWorkoutStep from './Fields/AddWorkoutStep';
import TrainingDaysStep from './Fields/TrainingDaysStep';
import MealStepNew from './Fields/MealStepNew';
import IngredientsSelectorStep from './Fields/IngredientsSelectorStep';
import CoachVatsavHeader from './CoachVatsavHeader';
import MealSelectionStep from './Fields/MealSelectionStep';
import DrinkPreferenceStep from './Fields/DrinkPreferenceStep';
import DrinkTimeStep from './Fields/DrinkTimeStep';
import WorkoutActivitiesStep from './Fields/WorkoutActivitiesStep';
import WorkoutPreferenceStep from './Fields/WorkoutPreferenceStep';
import HomeEquipmentStep from './Fields/HomeEquipmentStep';
import ExerciseTimeStep from './Fields/ExerciseTimeStep';
import ExerciseDaysStep from './Fields/ExerciseDaysStep';
import SubmissionConfirmation from './Fields/SubmissionConfirmation';
import WorkoutIntensityStep from './Fields/WorkoutIntensityStep';
import WorkoutStep from './Fields/WorkoutStep';
import { AppContext } from 'contexts/AppContext';
import BodyFatResultCard from './Cards/BodyFatResultCard';
import SuccessStoriesCard from './Cards/SuccessStoriesCard';
import GoalCard from './Cards/GoalCard';
import VatsavClientJourney from './Cards/VatsavClientJourney';
import FoodPreferencesCard from './Cards/FoodPreferencesCard';
import MovementCard from './Cards/MovementCard';
import StrengthEnduranceCard from './Cards/StrengthEnduranceCard';
 
export const DietFormNew = ({ formData,setFormData, handleSubmit,setIsOnboarding }) => {
  
  const { question,displayHeader } = useContext(AppContext);

     const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 36;
    const totalQuestions = 28;


useEffect(() => {
   console.log(formData)
}, [formData]);

  const renderStep = () => {
    switch (currentStep) {
       
        case 1:
            return  <PhoneNumberStep formData={formData} setFormData={setFormData}  currentStep={currentStep} setCurrentStep={setCurrentStep}   totalSteps={totalSteps}    setIsOnboarding={setIsOnboarding}   />
        case 2:
            return  <NameStep formData={formData} setFormData={setFormData}  currentStep={currentStep} setCurrentStep={setCurrentStep}   totalSteps={totalSteps} />

        case 3:
            return  <GenderStep   formData={formData} setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
            
        case 4:
            return  <AgeStep formData={formData} setFormData={setFormData}  currentStep={currentStep} setCurrentStep={setCurrentStep} totalSteps={totalSteps} />

        case 5:
            return <HeightStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
       
        case 6:
            return <WeightStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
       
        case 7:
            return <FatPercentageStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 8:
              return <BodyFatResultCard formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 9:
              return <SuccessStoriesCard formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 10:
              return <GoalCard formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 11:
              return <FitnessGoalSelectorStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 12:
                return <IdealWeightInputStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 13:
            return <LifestyleIssuesStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 14:
              return <VatsavClientJourney formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
          
        
            case 15:
          return <TimeSinceIdealWeightStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 16:
          return <LifeEventsSelectorStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />       
        
        
        case 17:
          return <FoodPreferencesCard formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 18:
          return <DietaryRestrictionStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
                           
        case 19:
          return <PrepareYourMealStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 20:
          return <DrinkPreferenceStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
          case 21:
            return <DrinkTimeStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
          
        case 22:
            return <IngredientsSelectorStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 23:
            return <MealSelectionStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />                                 
        case 24:
          return <MealStepNew formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
          case 25:
            return <MovementCard formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
                                             
        case 26:
          return <ActivityLevelStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
                                                   
        case 27:
          return <WorkoutStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
                                                         
          case 28:
            return <StrengthEnduranceCard formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
                                                                        
          case 29:
            return <WorkoutIntensityStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
                                                                   
        case 30:
          return <AddWorkoutStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />                                                           
        case 31:
          return <WorkoutActivitiesStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 32:
          return <WorkoutPreferenceStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        case 33:
          return <HomeEquipmentStep  formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
       case 34:
          return <ExerciseTimeStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
      case 35:
          return <ExerciseDaysStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
      case 36:
          return <SubmissionConfirmation formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
               
        // case 21:
        //   return <TrainingDaysStep formData={formData}  setFormData={setFormData} currentStep={currentStep}  setCurrentStep={setCurrentStep} totalSteps={totalSteps} />
        
        default:
            return null; 
    }
};




  return (<Box >

    <CoachVatsavHeader />
    {
displayHeader&&
  <>   <Progress
          value={(currentStep / totalSteps) * 100}
          size='md'
          sx={{
            width: '100%',
            bg: '#d9d9d9',
            borderRadius: '0px',
            '& > div': {
              background: 'linear-gradient(-90deg, #fea44d 0%, #ffcd3c 100%)', // Gradient from #fea44d to #ffcd3c

              borderRadius: '0px',
            },
          }}
          mb={6}
          
        />
                <Text pl={10} style={{color:'rgb(254 189 4)'}}>{`Question ${question} of ${totalQuestions}`}</Text>

        </>
      }
      <Container maxW='container' p={0} m={0} >
       
        {/* <br />
        <hr />
        <br /> */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            Swal.fire("hello");
          }}
        >
          <Stack spacing={4}>
            {renderStep()}
          </Stack>
        </form>
      </Container>
    </Box>
  );
};
