import React, { useState, useEffect } from 'react';
import {
  VStack,
  Button,
  Select,
  Text,
  Box,
  Stack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

const EditAddWorkoutStep = ({ formData, setFormData }) => {
  const [addWorkout, setAddWorkout] = useState('No');
  // Rename workoutDuration to weightTrainingDuration
  const [weightTrainingDuration, setWeightTrainingDuration] = useState('60');

  useEffect(() => {
    // Initialize state from formData when the component mounts
    setAddWorkout(formData.addWorkout || 'No');
    // Use formData.workoutDuration for backward compatibility
    // Update to formData.weightTrainingDuration if available
    setWeightTrainingDuration(formData.weightTrainingDuration || formData.workoutDuration || '');
  }, [formData]);

  const handleAddWorkoutChange = (event) => {
    const { value } = event.target;
    setAddWorkout(value);
    if (value === 'No') {
      setWeightTrainingDuration('');
      setFormData({ ...formData, addWorkout: value, weightTrainingDuration: '' });
    } else {
      // If "Yes" is selected, just update addWorkout in formData.
      // This keeps the existing weightTrainingDuration unless the user changes it.
      setFormData({ ...formData, addWorkout: value });
    }
  };

  const handleDurationChange = (event) => {
    const { value } = event.target;
    setWeightTrainingDuration(value);
    // Update formData with the new weightTrainingDuration value
    setFormData({ ...formData, weightTrainingDuration: value });
  };

 

 
  return (

    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel>Do you want to add workout to your program?</FormLabel>
    
      <Box>
        
        <Select onChange={handleAddWorkoutChange} value={addWorkout} placeholder="Select option">
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </Select>
      </Box>
      {addWorkout === 'Yes' && (
        <Box>
          <Text mt={4}>Duration of the weight training every day?</Text>
          <Select onChange={handleDurationChange} value={weightTrainingDuration} placeholder="Select duration">
            <option value="15">15 mins</option>
            <option value="30">30 mins</option>
            <option value="45">45 mins</option>
            <option value="60">1 hr</option>
          </Select>
        </Box>
      )}
    
</FormControl>
  );
};

export default EditAddWorkoutStep;
