import React, { useEffect, useState } from 'react';
import {
  VStack,
  Heading,
  Select,
  Box,
  Text,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { workoutOptions } from 'views/diet/userDiet/Fields/WorkoutIntensityStep';
 

const EditWorkoutStep = ({ formData, setFormData }) => {
  const [workoutOptions1, setWorkoutOptions] = useState([]);
  const [workout, setWorkout] = useState(formData.workout || "No");
  const [intensity, setIntensity] = useState(formData.intensity || "");
  // UseEffect hook to set the initial values based on formData
  useEffect(() => {
    if (formData.workout) {
      setWorkout(formData.workout);
    }
    if (formData.intensity) {
      setIntensity(formData.intensity);
    }
  }, [formData]); // Dependency array includes formData to re-run if formData changes 
  
  
  useEffect(() => {
     setWorkoutOptions(workoutOptions)
  }, []); // Dependency array includes formData to re-run if formData changes

  const handleWorkoutChange = (e) => {
    const newWorkoutValue = e.target.value;
    setFormData({ ...formData, workout: newWorkoutValue, intensity: newWorkoutValue === "Yes" ? formData.intensity : "" });
  };

  const handleIntensityChange = (e) => {
    setFormData({ ...formData, intensity: e.target.value });
  };

  return (
    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
      <FormLabel>Do you currently workout?</FormLabel>
      <Select
        placeholder="Select option"
        value={formData.workout || ''}
        onChange={handleWorkoutChange}
      >
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </Select>
      {formData.workout === "Yes" && (
        <>
          <Text mt={4}>Select your workout intensity:</Text>
          <Select
            placeholder="Select intensity"
            value={formData.intensity || ''}
            onChange={handleIntensityChange}
          >
            {workoutOptions1.map((option) => (
              <option key={option.id} value={option.id}>
                {option.label} - {option.detail}
              </option>
            ))}
          </Select>
        </>
      )}
    </FormControl>
  );
};

export default EditWorkoutStep;
