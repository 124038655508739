import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, Input, ButtonGroup, Button, Stack, Text, Heading } from '@chakra-ui/react';

const EditHeightStep = ({ formData, setFormData }) => {
  const isMetric = formData.heightType ? formData.heightType === 'cm' : true;

  // Helper function to convert feet and inches to centimeters
  const feetInchesToCm = (feet, inches) => (parseInt(feet) * 12 + parseInt(inches)) * 2.54;

  useEffect(() => {
    // Update isValidHeight based on the current formData
    if (formData.heightType === 'cm') {
      setFormData({ ...formData, isValidHeight: formData.heightInCm >= 60 && formData.heightInCm <= 300 });
    } else {
      const cmValue = feetInchesToCm(formData.heightInFeet, formData.heightInInches);
      setFormData({ ...formData, isValidHeight: cmValue >= 60 && cmValue <= 300 });
    }
  }, [formData.heightInCm, formData.heightInFeet, formData.heightInInches]); // Depend on specific parts of formData to avoid infinite loops

  const handleUnitChange = (metric) => {
    setFormData({
      ...formData,
      heightType: metric ? 'cm' : 'ft',
      heightInCm: '',
      heightInFeet: '',
      heightInInches: '',
      isValidHeight: false,
    });
  };

  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    if (name === 'metricHeight') {
      setFormData({
        ...formData,
        heightInCm: value,
        isValidHeight: value >= 60 && value <= 300,
      });
    } else if (name === 'feet') {
      setFormData({
        ...formData,
        heightInFeet: value,
      });
    } else if (name === 'inches') {
      setFormData({
        ...formData,
        heightInInches: value,
      });
    }
  };

  return (
    <>
      <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
        <FormLabel>Height</FormLabel>
        <ButtonGroup isAttached variant='solid'>
          <Button
            mr="-px"
            border='1px'
            colorScheme={!isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitChange(false)}
          >
            FT/IN
          </Button>
          <Button
            border='1px'
            colorScheme={isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitChange(true)}
          >
            CM
          </Button>
        </ButtonGroup>
        {isMetric ? (
          <Input
            name="metricHeight"
            type="number"
            placeholder="Height in cm"
            value={formData.heightInCm}
            onChange={handleHeightChange}
            mt={3}
          />
        ) : (
          <>
            <Input
              name="feet"
              type="number"
              placeholder="Feet"
              value={formData.heightInFeet}
              onChange={handleHeightChange}
              mt={3}
            />
            <Input
              name="inches"
              type="number"
              placeholder="Inches"
              value={formData.heightInInches}
              onChange={handleHeightChange}
              mt={3}
            />
          </>
        )}
        <Text mt={2}>
          Valid height is {isMetric ? '60-300 cm' : "2'-9'11\""}
        </Text>
      </FormControl>
    </>
  );
};

export default EditHeightStep;
