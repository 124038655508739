import React from 'react';
import { Box, Image, Text, Badge, Heading } from '@chakra-ui/react';

const MealSelect = ({ meals, selectedMeals, onMealToggle,mainheading }) => {
  // Filter meals by dishType
  const tiffinsMeals = meals.filter(meal => meal.dishType === 'TIFFINS');
  const nonVegMainCourseMeals = meals.filter(meal => meal.dishType === 'NON_VEG_MAIN_COURSE');
  const vegMainCourseMeals = meals.filter(meal => meal.dishType === 'VEG_MAIN_COURSE');

  // Split each dishType into two rows
  const splitRows = (mealsArray) => {
    const halfwayIndex = Math.ceil(mealsArray.length / 2);
    return [mealsArray.slice(0, halfwayIndex), mealsArray.slice(halfwayIndex)];
  };

  // Render rows with heading inside a scrollable container
  const renderSectionWithHeading = (mealsArray, heading) => {
    const rows = splitRows(mealsArray);
    return (
      <Box mb="20px">
   
        <Heading size="md" mb={4}>{heading}</Heading>
        <Box
          display="flex"
          flexDirection="column"
          overflowX="auto"
          css={{
            '&::-webkit-scrollbar': {
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'teal',
              borderRadius: '24px',
            },
          }}
        >
          {rows.map((rowMeals, index) => (
            <Box key={index} display="flex" flexDirection="row" mb={4}>
              {rowMeals.map((meal) => (
                <MealItem key={meal.id} meal={meal} selectedMeals={selectedMeals} onMealToggle={onMealToggle} />
              ))}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box width="100%" padding="10px">
        {mainheading && (
        <Box mb={4}>
          <Heading size="md">{mainheading}</Heading> {/* Use the heading prop */}
        </Box>
      )}
      {tiffinsMeals.length > 0 && renderSectionWithHeading(tiffinsMeals, 'Tiffins')}
      {nonVegMainCourseMeals.length > 0 && renderSectionWithHeading(nonVegMainCourseMeals, 'Non-Veg Main Course')}
      {vegMainCourseMeals.length > 0 && renderSectionWithHeading(vegMainCourseMeals, 'Veg Main Course')}
    </Box>
  );
};

const MealItem = ({ meal, selectedMeals, onMealToggle }) => {
  const previewUrl = process.env.REACT_APP_PREVIEW_URL;
  return (
    <Box
      onClick={() => onMealToggle(meal)}
      // minWidth="150px"
      width="120px"
      flex="none"
      // border="1px solid #ddd"
      borderRadius="8px"
      display="inline-block"
      marginRight="10px"
      bg={selectedMeals.some(selectedMeal => selectedMeal.id === meal.id) ? 'teal.100' : 'white'}
      boxShadow={selectedMeals.some(selectedMeal => selectedMeal.id === meal.id) ? '0 0 10px rgba(0, 0, 0, 0.5)' : '0'}
 
      cursor="pointer"
      position="relative"
    >
      <Image
        src={previewUrl + meal.foodIconPath}
        alt={meal.subHead}
        objectFit="cover"
        width="full"
        height="100px"
        borderRadius="8px"
        border="1px solid #ddd"
      />
      {selectedMeals.some(selectedMeal => selectedMeal.id === meal.id) && (
        <Badge position="absolute" top="1" right="1" colorScheme="green">
          Selected
        </Badge>
      )}
      <Text p="2" isTruncated textAlign="center">
    <b>  {meal.subHead}</b>  
      </Text>
    </Box>
  );
};

export default MealSelect;
