import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import DietLayout from 'layouts/diet';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
 import DietForm from 'views/diet/userDiet';
import ProtectedRoute from 'guards/ProtectedRoute';
import { EditUserDietInfo } from 'views/diet/Users/EditUserDietInfo';
import { AppProvider } from 'contexts/AppContext';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
			<AppProvider>
				<HashRouter>
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/admin`} component={AdminLayout} />
						<Route path={`/rtl`} component={RtlLayout} />

 						<ProtectedRoute path={`/diet`} component={DietLayout} />  

						
						<Route path={`/userDiet`} component={DietForm} />
						<Route path={`/editUserDiet/:id`} component={EditUserDietInfo} />

						<Redirect from='/' to='/auth' />
						{/* <Redirect from='/' to='/auth/sign-in' /> */}

					</Switch>
				</HashRouter>
				</AppProvider>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
