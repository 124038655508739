import React, { useEffect } from 'react';
import { FormControl, FormLabel, Input, ButtonGroup, Button, Text } from '@chakra-ui/react';

const EditWeightStep = ({ formData, setFormData }) => {
  const isMetric = formData.weightType ? formData.weightType === 'kg' : true;
  const weight = isMetric ? formData.weightInKg : formData.weightInLbs;
  
  // Helper functions for conversion and validation
  const poundsToKilograms = (pounds) => pounds / 2.20462;
  const kilogramsToPounds = (kilograms) => kilograms * 2.20462;
  const validateWeight = (weightInKg) => weightInKg >= 22 && weightInKg <= 227;

  useEffect(() => {
    // Initialize validation state based on existing formData
    const weightInKg = isMetric ? formData.weightInKg : poundsToKilograms(formData.weightInLbs);
    setFormData({ ...formData, isValidWeight: validateWeight(weightInKg) });
  }, []); // Intentionally left dependencies out to run only at mount

  const handleUnitToggle = (metric) => {
    const newWeightType = metric ? 'kg' : 'lbs';
    // Clear weight input and reset validation state on unit toggle
    setFormData({ 
      ...formData, 
      weightType: newWeightType, 
      weightInKg: '',
      weightInLbs: '',
      isValidWeight: false,
    });
  };

  const handleWeightChange = (e) => {
    const newWeight = e.target.value;
    let weightInKg = isMetric ? parseFloat(newWeight) : poundsToKilograms(parseFloat(newWeight));
    const isValid = validateWeight(weightInKg);

    // Update formData based on the unit type and validation
    if (isMetric) {
      setFormData({
        ...formData,
        weightInKg: newWeight,
        weightInLbs: isValid ? kilogramsToPounds(weightInKg).toFixed(2) : '',
        isValidWeight: isValid,
      });
    } else {
      setFormData({
        ...formData,
        weightInLbs: newWeight,
        weightInKg: isValid ? weightInKg.toFixed(2) : '',
        isValidWeight: isValid,
      });
    }
  };

  return (
    <>
      <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
        <FormLabel>Bodyweight</FormLabel>
        <ButtonGroup isAttached variant='solid'>
          <Button
            mr="-px"
            border='1px'
            colorScheme={!isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitToggle(false)}
          >
            LBS
          </Button>
          <Button
            border='1px'
            colorScheme={isMetric ? 'teal' : 'gray'}
            onClick={() => handleUnitToggle(true)}
          >
            KG
          </Button>
        </ButtonGroup>
        <Input
          type="number"
          placeholder={isMetric ? 'Weight in kg' : 'Weight in lbs'}
          value={weight || ''}
          onChange={handleWeightChange}
          mt={3}
        />
        <Text mt={2}>
          Valid weight is 22-227 kg or 48.5-500 lbs
        </Text>
      </FormControl>
    </>
  );
};

export default EditWeightStep;
