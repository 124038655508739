import React from 'react';
// import { Box, Image, Text } from '@chakra-ui/react';
import logo from 'assets/img/couchVastavLogo.png'; // Replace with the path to your logo image
import { Box, Image, Text } from '@chakra-ui/react';

const CoachVatsavHeader = () => {
  return (
    <Box
      bg="#161616"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center" 
      p={5} 
      minH={'20vh'} 
    >
      <Image src={logo} alt="Coach Vatsav Logo" style={{width:'40%'}}  mr={4} />
  
    </Box>
  );
};

export default CoachVatsavHeader;
