import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Button,
  Heading,
  Box,
  useRadioGroup,
  useColorModeValue,
  useRadio,
  Stack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const activityLevels = [
  { id: 'mostly-sitting', title: 'Mostly Sitting', description: 'Spend majority of the day sitting down (eg desk job)' },
  { id: 'some-movement', title: 'Some Movement', description: 'Move around occasionally throughout the day (eg. a teacher)' },
  { id: 'very-active', title: 'Very Active', description: 'Often on your feet & moving around (eg. a shop owner)' },
  { id: 'extremely-active', title: 'Extremely Active', description: 'Engage in vigorous physical activity throughout the day (eg. atheletes or fitness enthusiast)' },
];

const ActivityLevelStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedActivityLevel, setSelectedActivityLevel] = useState(formData.activityLevel);

  const handleActivityLevelChange = (value) => {
    setSelectedActivityLevel(value);
    setFormData({ ...formData, activityLevel: value });
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'activityLevel',
    defaultValue: selectedActivityLevel,
    onChange: handleActivityLevelChange,
  });
  const group = getRootProps();

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };


  //for setting question no start
  const { setQuestionNo,setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(true);
  setQuestionNo(20)
}, []);
//for setting question no end
// Custom radio card component
const RadioCard = (props) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();
  
    const bg = useColorModeValue('gray.100', 'gray.700');
    const selectedBg = "black";
    const borderColor = useColorModeValue('gray.200', 'gray.500');
  
    return (
      <Box as='label' w="full">
        <input {...input} />
        <Box
          {...checkbox}
          bg={ bg}
          borderColor={borderColor}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
            mb={'10px'}
          _checked={{
            bg: selectedBg,
            borderColor: 'teal.500',
            color: 'white',
          }}
          display={'flex'}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    ); 
  };
  return (
    <VStack spacing={4} align="stretch"  pl={10} pr={10}
    
    className='bottomImage'
    
    >
      <Heading  textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
      How active is your lifestyle  generally ?
      </Heading>
      <p  textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>(apart from your planned workouts)</p>

      <RadioGroup onChange={handleActivityLevelChange} value={selectedActivityLevel}> 
      <Box {...group}>
        {activityLevels.map((level) => (
          <RadioCard key={level.id} {...getRadioProps({ value: level.id })}>
          <Radio value={level.id}   className='default_radio_input1 default_input' mr={5} />  
          <div style={{display:'flex',flexDirection:'column'}}>
            <Heading as="h4" size="sm">{level.title}</Heading> 
            <Box>{level.description}</Box></div>
          </RadioCard>
        ))}
      </Box>
      </RadioGroup>
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button  className='default_back_button'  colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button  className='default_next_button'  colorScheme="teal" onClick={handleNext} isDisabled={!selectedActivityLevel}>
          Next
        </Button>
      </Stack> 
      <br></br>
      <br></br>
    </VStack>
  );
};

export default ActivityLevelStep;
