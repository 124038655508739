import React, { useState, useEffect, useContext } from 'react';
import { FormControl, FormLabel, Checkbox, Stack, Button, Text, Flex, Heading, Spinner } from '@chakra-ui/react';
import MotivationalCard from './MotivationalCard';
import { fetchLifestyleIssues } from 'services/foodItemsService';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const LifestyleIssuesStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [selectedIssues, setSelectedIssues] = useState(new Set(formData.lifestyleIssues || []));
  const [lifestyleIssues, setLifestyleIssues] = useState([]);
  const [showMotivationCard, setShowMotivationCard] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true); 
    fetchLifestyleIssues().then(data => {
      setLifestyleIssues(data.map(issue => ({ ...issue, id: issue.id.toString() }))); // Ensure IDs are strings

      if (formData.lifestyleIssues) {
        const issueSet = new Set(formData.lifestyleIssues.map(id => id.toString())); // Convert IDs to strings
        setSelectedIssues(issueSet);
      }
      setLoading(false);
    }).catch(error => {
      console.error('Error fetching lifestyle issues:', error);
      setLoading(false); // Ensure loading is set to false even on error
    });
    
  }, []);
  //}, [formData.lifestyleIssues]);

  const handleCheckboxChange = (issueId) => {
    const newSelectedIssues = new Set(selectedIssues);
    if (newSelectedIssues.has(issueId)) {
      newSelectedIssues.delete(issueId);
    } else {
      newSelectedIssues.add(issueId);
    }
    setSelectedIssues(newSelectedIssues);
    setFormData({ ...formData, lifestyleIssues: Array.from(newSelectedIssues) });
  };

  const prevStep = () => setCurrentStep(currentStep - 1);
  
  const nextStep = () => {
    if (currentStep < totalSteps) setCurrentStep(currentStep + 1);
  };

  const handleContinue = () => nextStep();
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(10)
}, []);
//for setting question no end
  return (
    <>
      {!showMotivationCard ? (
        <FormControl  pl={10} pr={10}
        
        className='bottomImage'
       
       
        
        >
          {/* <Heading textAlign="center" as="h3" size="lg" mb={6} color={'teal'}>
            Do you have any lifestyle issues?
          </Heading>
          <Text>Tick all the options that are relevant to you.</Text> */}

          <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}> Do you have any medical or lifestyle issues? </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>Tick all the options that are relevant to you. </p>
     
     <br />
     {loading ? (
        <Flex justify="center" align="center" height="100%">
          <Spinner size="xl" color="teal.500" />
        </Flex>
      ) : (
          <Stack  mt={1} spacing={1}>
            {lifestyleIssues.map((issue) => (
              <Checkbox
               className='default_checkbox1'
                key={issue.id}
                isChecked={selectedIssues.has(issue.id)}
                onChange={() => handleCheckboxChange(issue.id)}
              >
                {issue.name}
              </Checkbox>
            ))}
          </Stack>

      )}
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
            <Button className='default_back_button'  colorScheme='teal' variant='outline' onClick={prevStep}>
              Back
            </Button>
            {/* <Button  className='default_next_button'  colorScheme='teal' variant='outline' onClick={() => setShowMotivationCard(true)}> */}
            <Button  className='default_next_button'  colorScheme='teal' variant='outline' onClick={handleContinue}>
              Next
            </Button>
          </Stack>
          <br></br>
          <br></br>
        </FormControl>
      ) : (
        <MotivationalCard onNext={handleContinue} />
      )}
    </>
  );
};

export default LifestyleIssuesStep;
