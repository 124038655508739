import React, { useContext, useEffect, useState } from 'react';
import {
  VStack,
  Radio,
  RadioGroup,
  Button,
  Heading,
  Stack,
  Box,
  Text,
  useRadioGroup,
  useRadio,
  useColorModeValue,
  Flex
} from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

const ExerciseDaysStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedDays, setSelectedDays] = useState(formData.trainingDaysPerWeek || '');
  const [selectedWeekdays, setSelectedWeekdays] = useState(formData.selectedWeekdays || []);

  const dayOptions = [
    { id: '3', title: '3 days' },
    { id: '4', title: '4 days' },
    { id: '5', title: '5 days' },
    { id: '6', title: '6 days(we recommend 1 rest day min)' }
  ];

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'trainingDaysPerWeek',
    defaultValue: selectedDays,
    onChange: (value) => {
      setSelectedDays(value);
      setSelectedWeekdays([]); // Reset selected weekdays when changing days
      setFormData({ ...formData, trainingDaysPerWeek: value, selectedWeekdays: [] });
    }
  });
  const group = getRootProps();

  const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

  const toggleDaySelection = (day) => {
    const newSelection = selectedWeekdays.includes(day)
      ? selectedWeekdays.filter(d => d !== day)
      : [...selectedWeekdays, day];
    setSelectedWeekdays(newSelection);
    // Update formData whenever selectedWeekdays changes
    setFormData({ ...formData, selectedWeekdays: newSelection });
  };

  // Custom radio card component
  const RadioCard = (props) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const bg = useColorModeValue('gray.100', 'gray.700');
    const selectedBg = "black";
    const borderColor = useColorModeValue('gray.200', 'gray.500');
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
  setDisplayheader(true);
   setQuestionNo(28)
 }, []);
 //for setting question no end
    return (
      <Box as='label' w="full">
        <input {...input} />
        <Box
          {...checkbox}
          bg={bg}
          borderColor={borderColor}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          mb={'10px'}
          _checked={{
            bg: selectedBg,
            borderColor: 'teal.500',
            color: 'white',
          }}
          display={'flex'}
          px={5}
          py={3}
        >
          {props.children}
        </Box>
      </Box>
    );
  };

  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10}  className='bottomImage'>
      <Heading  as="h6"  size='md'   mb={1}    textAlign="left" >
        How many days a week can you devote to exercising?
      </Heading>
      <RadioGroup defaultValue={selectedDays} value={selectedDays}>
        <Box {...group}>
          {dayOptions.map((option) => (
            <RadioCard key={option.id} {...getRadioProps({ value: option.id })}>
              <Radio className='default_radio_input1 default_input' value={option.id} mr={5} /> {option.title}        </RadioCard>
          ))}
        </Box>
      </RadioGroup>
      <Text fontSize="sm" textAlign="left" mb={4}>
        Note: We recommend a min of 3-4 days
      </Text>
      <Flex wrap="wrap" justifyContent="center" mb={4}>
        {weekDays.map(day => (
          <Box key={day} p={2} m={1} minWidth="140px" height="40px" bg={selectedWeekdays.includes(day) ? "black" : "gray.200"} color={selectedWeekdays.includes(day) ? "white" : "black"} borderRadius="md" cursor="pointer" onClick={() => toggleDaySelection(day)} textAlign="center">
            {day}
          </Box>
        ))}
      </Flex>
      <Text fontSize="sm" textAlign="center" color="red.500" mb={4}>
        {selectedWeekdays.length !== parseInt(selectedDays) && `Please select exactly ${selectedDays} days.`}
      </Text>
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={4}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={() => setCurrentStep(currentStep + 1)} isDisabled={selectedWeekdays.length !== parseInt(selectedDays)}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default ExerciseDaysStep;
