import React, { useState, useEffect, useContext } from 'react';
import { VStack, Button, Heading, Radio, RadioGroup, Box, useRadioGroup, useRadio, useColorModeValue, Stack } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

// Drink options with unique IDs
const workoutOptions = [
  { id: 'yes', title: 'Yes' },
  { id: 'no', title: 'No' },
 
];

// Custom radio card component
const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const bg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = "black";
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  return (
    <Box as='label' w="full">
      <input {...input} />
      <Box
        {...checkbox}
        bg={bg}
        borderColor={borderColor}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        mb={'10px'}
        _checked={{
          bg: selectedBg,
          borderColor: 'teal.500',
          color: 'white',
        }}
        display={'flex'}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const AddWorkoutStep  = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedAddWorkout, setSelectedAddWorkout] = useState(formData.addWorkout);

  useEffect(() => {
    // Ensure we have a default value if the form data already contains a addWorkout selection
    if (formData.addWorkout) {
      setSelectedAddWorkout(formData.addWorkout);
    }
  }, [formData.addWorkout]);
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
  setDisplayheader(true);
   setQuestionNo(23)
 }, []);
 //for setting question no end
 

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "addWorkout",
    defaultValue: selectedAddWorkout,
    onChange: (value) => {
      setSelectedAddWorkout(value);
       if (value === 'no') {
        setFormData({
           ...formData,
            addWorkout: value,
            workoutActivities:[],
            workoutPreference:'',
            homeEquipment :[],
            weightTrainingDuration:'',
            trainingDaysPerWeek:'',
            selectedWeekdays:[],
            
            });
      } else {
        setFormData({ ...formData, addWorkout: value });
      }
    }
  });
  const group = getRootProps();
 

  const handleBack = () => {
    if (formData.workout === 'no') {
    setCurrentStep(currentStep - 2);
    }else{
    setCurrentStep(currentStep - 1);

    }
  };
  const handleNext = () => {
    if (formData.addWorkout === 'no') {
    setCurrentStep(currentStep + 6);
    }else{
    setCurrentStep(currentStep + 1);

    }
  };
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10} className='bottomImage' >
      <Heading as="h5" size="md" textAlign="left"  >
      Do you want to add a workout to your program?
            </Heading>
   

      <RadioGroup     value={selectedAddWorkout}>
        <Box {...group}>
          {workoutOptions.map((option) => (
            <RadioCard key={option.id} {...getRadioProps({ value: option.id })}>
              <Radio  className='default_radio_input1 default_input' value={option.id} mr={5} /> {option.title}
            </RadioCard>
          ))} 
        </Box> 
      </RadioGroup>
      <p   textAlign="left" style={{fontSize:'15px'}}    mb={6} color={'#b2b2b'}>
      <b>Note</b> : Workout is a major part of your fitness journey, we recommend you do the given workouts for maximum 
benefit. But in case, if you have any injury or unable to do the workout you can select No.
      
      </p>

      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={handleNext} isDisabled={!selectedAddWorkout}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default AddWorkoutStep ;
