import React, { useEffect, useState } from 'react';
import {
  VStack,
  Heading,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { Multiselect } from 'multiselect-react-dropdown';
import { fetchlifeEvents } from 'services/foodItemsService';

const EditLifeEventsSelectorStep = ({ formData, setFormData }) => {
  const [lifeEvents, setLifeEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  useEffect(() => {
    fetchlifeEvents()
      .then(res => {
        const formattedEvents = res.map(event => ({
          id: event.id.toString(), // Convert id to string
          name: event.eventDescription,
        }));
        setLifeEvents(formattedEvents);

        // Filter based on string comparison
        const preSelectedEvents = formattedEvents.filter(event =>
          formData.lifeEvents?.includes(event.id) // Assumes formData.lifeEvents are strings
        );
        setSelectedEvents(preSelectedEvents);
      })
      .catch(error => {
        console.log(error);
      });
  }, []); // Ensure re-fetching when formData.lifeEvents changes

  useEffect(() => {
    const preSelectedEvents = lifeEvents.filter(event =>
      formData.lifeEvents?.includes(event.id)
    );
    setSelectedEvents(preSelectedEvents);
  }, [formData.lifeEvents, lifeEvents]);

  const handleEventSelection = (selectedList, selectedItem) => {
    // Since ids are strings, we can directly use them
    setFormData({
      ...formData,
      lifeEvents: selectedList.map(item => item.id) // ids are already strings
    });
  };

  return (
    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel> Have any life events led to weight gain in the last few years?</FormLabel>
        
       <Multiselect
        options={lifeEvents}
        selectedValues={selectedEvents}
        onSelect={handleEventSelection}
        onRemove={handleEventSelection}
        displayValue="name"
        placeholder="Select Life Events"
        style={{ chips: { background: 'teal' } }}
      />
    </FormControl>
  );
};

export default EditLifeEventsSelectorStep;
