import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { myAxios } from "../helpers/axioHelper"
import { doLogout } from "helpers/loginHelper"



export const login=async (user)=>{
  const response = await myAxios.post('/login',user)
  console.log(response)
  return response.data
}


