import React, { useContext, useEffect } from 'react';
import { Box, Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import coach_vatsav_logo from 'assets/img/couchVastavLogo.png';
 import foodPreferances from 'assets/img/foodPreferances.png'; // Replace with your actual image path
import { AppContext } from 'contexts/AppContext';

const FoodPreferencesCard = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Hide header based on AppContext
  const { setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(false);
  }, [setDisplayheader]);
  return (
  <Flex
    direction="column"
    align="center"
    justify="center"
  
    bg="#161616"
    color="white"
     
    
    minH="80vh"
  >
   

    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}   width={'100%'} >
      <Image
        src={foodPreferances} // Replace with your actual logo path
        alt="foodPreferances"
        height={'450px'} 
        width={'100%'}
         align={'center'}
    
      />
    </Box>

    <Box   width="70%"   textAlign="left">
      <Text fontSize="xl" fontWeight="bold" mt={4}   mb={4}>
      Food Preferences
      </Text>
      <Flex align="center" justify="center" mb={4} display={'flex'} justifyContent={'space-between'}>
        <Text  >
        Let us know your dietary preferences.         </Text>
      
      </Flex>
      
     
      
      <Flex width="100%" mt={2} direction="row" justify="center" align="center">
        <Divider  height={'5px'} backgroundColor={'#ffbe4a'} borderColor="#ffbe4a" width="25%"  m={1} borderRadius={10}/>
        <Divider height={'5px'} backgroundColor={'#ffbe4a'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
        <Divider height={'5px'} backgroundColor={'#ffbe4a'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
        <Divider height={'5px'} backgroundColor={'#ffffff'}  borderColor="#ffbe4a" width="25%" m={1} borderRadius={10} />
      </Flex>
    </Box>
 
    <Flex   mt={8} mb={8} width="80%" justifyContent="space-around" flexDirection={'row'} alignItems={'center'}>
     <Button className='default_back_button' bg="gray.700" _hover={{ bg: 'gray.600' }} onClick={backStep}>
        Back
      </Button>  
      <Button className='default_next_button' bg="blue.400" _hover={{ bg: 'blue.300' }} onClick={nextStep}>
        Next
      </Button>
    </Flex>

    <Flex  width="70%"   align="center" justify="center" mb={4} display={'flex'} justifyContent={'space-between'}>
        <Text color={'#ffbe4a'} >
        Note do spend some time on this section, this 
is important for us to curate your nutrition plan        </Text>
      
      </Flex>

  </Flex>
)};

export default FoodPreferencesCard;
