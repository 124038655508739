import React, { useEffect, useState } from 'react';
import {
  VStack,
  Heading,
  Select,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { prepareMeals } from 'views/diet/userDiet/Fields/PrepareYourMealStep';

 

const EditPrepareYourMealStep = ({ formData, setFormData }) => {

  const [prepareMeals1,setPrepareMeals]=useState([])
  // Updates the formData directly without the need for local state management,
  // assuming formData is managed by a parent component's state or context.
  const handlePrepareMealChange = (value) => {
    setFormData({ ...formData, prepareYourMeal: value });
  };

  useEffect(()=>{
    setPrepareMeals(prepareMeals); 
  },[])


  return (

    <FormControl isRequired display={'flex'} flexDirection={'column'} flexBasis="48%">
    <FormLabel>   How do you usually prepare your meals?</FormLabel>
      
  
      <Select
        placeholder="Select your option"
        value={formData.prepareYourMeal || ''}
        onChange={(e) => handlePrepareMealChange(e.target.value)}
      >
        {prepareMeals1.map((mealOption, index) => (
          <option key={mealOption.title} value={mealOption.title}>{mealOption.title}</option>
        ))}
      </Select>
 </FormControl>
  );
};

export default EditPrepareYourMealStep;
