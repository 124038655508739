import React, { useContext, useEffect, useState } from "react";
import {
  VStack,
  Button,
  Heading,
  Box,
  useRadioGroup,
  useRadio,
  Stack,
  Text,
  useColorModeValue,
  HStack,
  Select,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from "contexts/AppContext";

export const workoutOptions = [
    
    {
      id: "light",
      label: "Light",
      detail: "I do some walking / stretching a couple of times a week",
      image: '/path-to-your-light-dumbbell-image.png', // Replace with your image path
    },
    {
      id: "moderate",
      label: "Moderate",
      detail: "I jog, cycle or do yoga for 20 30 mins a day, 2-3 times a week",
      image: '/path-to-your-moderate-dumbbell-image.png', // Replace with your image path
    },  
    {
      id: "intense",
      label: "Intense",
      detail: "I workout/play for 1 hour a day, 4-5 times a week",
      image: '/path-to-your-intense-dumbbell-image.png', // Replace with your image path
    },
    {
      id: "very-intense",
      label: "Very Intense",
      detail: "I workout/play for more than 1 hour a day on most days",
      image: '/path-to-your-very-intense-dumbbell-image.png', // Replace with your image path
    },
    // ... Add other options as necessary
  ];

const WorkoutIntensityStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [workout, setWorkout] = useState(formData.workout || "");
  const [intensity, setIntensity] = useState(formData.intensity || "");

  useEffect(() => {
    setWorkout(formData.workout || "");
    setIntensity(formData.intensity || "");


    if (formData.workout === 'no') {
      setCurrentStep(currentStep + 1);  // Move to the next step
    }


  }, []);
 
  // const handleWorkoutChange = (event) => {
  //   const { value } = event.target;
  //   setWorkout(value);
  //   if (value === "No") {
  //     setIntensity("");
  //   }
  //   setFormData({ ...formData, workout: value, intensity: "" });
  // };

  const handleIntensityChange = (value) => {
    setIntensity(value);
    setFormData({ ...formData, intensity: value });
  };

  const isNextEnabled = workout === "no" || (workout === "yes" && intensity);

  const handleNext = () => {
    setFormData({ ...formData, workout, intensity });
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const {
    getRootProps: getIntensityGroupProps,
    getRadioProps: getIntensityProps,
  } = useRadioGroup({
    name: "intensity",
    defaultValue: intensity,
    onChange: handleIntensityChange,
  });
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
  setDisplayheader(true);
   setQuestionNo(22)
 }, []);
 //for setting question no end
  const intensityGroup = getIntensityGroupProps();

  const RadioCard = (props) => {
    const { getInputProps, getCheckboxProps } = useRadio(props);
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const bg = useColorModeValue("gray.100", "gray.700");
    const selectedBg = "black";
    const borderColor = useColorModeValue("gray.200", "gray.500");

    return (
      <Box as="label" w="full">
        <input {...input} />
        <Box
          {...checkbox}
          bg={bg}
          borderColor={borderColor}
          borderWidth="1px"
          borderRadius="md"
          _checked={{
            bg: selectedBg,
            borderColor: selectedBg,
            color: "white",
          }}
          _hover={{
            cursor:'pointer',
          }}
          px={5}
          py={3}
          display="flex"
          alignItems="center"
          gap={3}
          mt={2}
        >
          {props.children}
        </Box>
      </Box>
    );
  };

  return (
    <VStack spacing={4} align="stretch"  pl={10} pr={10}
    
    className='bottomImage'
    >
      <Heading  textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
      What are your workouts like 
generally?      </Heading>
      {/* <Select placeholder="Select option" value={workout} onChange={handleWorkoutChange}>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </Select> */}
      {workout === "yes" && (
       
        <RadioGroup onChange={handleIntensityChange} value={intensity}> 
        <VStack {...intensityGroup} spacing={4}>
          {workoutOptions.map((option) => (
            <RadioCard key={option.id} {...getIntensityProps({ value: option.id })} mt={5}>
            <Radio value={option.id}   className='default_radio_input1 default_input' mr={5} />
              <HStack spacing={4}>
                <VStack align="start">
                  <Heading as="h5" size="sm">{option.label}</Heading>
                  <Text fontSize="sm">{option.detail}</Text>
                </VStack>
              </HStack>
            </RadioCard>
          ))}
          </VStack>
          </RadioGroup>
        
      )}
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button  className='default_back_button' colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button
        className='default_next_button'
          colorScheme="teal"
          onClick={handleNext}
          isDisabled={!isNextEnabled}
        >
          Next
        </Button>
      </Stack>
      <br></br>
      <br></br>
    </VStack>
  );
};

export default WorkoutIntensityStep;
