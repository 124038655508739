import { useState } from 'react';
import {
  Box,
} from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { sendDietInfo } from 'services/foodItemsService';
import { useHistory } from 'react-router-dom';
import { genPdf } from 'services/foodItemsService';
import { DietFormNew } from './DietFormNew';
import OnboardingForm from './Fields/OnboardingForm';
export const initialFormData = {
  name: '',
  gender:'',
  email: '',
  phoneNumber: '',
  bodyweight: '',
  bodyFatPercentage: '',
  physicalActivityFactor: '1.15',
  thermicEffectOfFoodFactor: '1.16',
  weightTrainingDuration: '',
  energyBalanceFactor: '0.8',
  trainingDaysPerWeek: '',
  bigMeals:'',
  smallMeals:'',
  addOnMeals:'',
  bigMealsItems: [[]],
  smallMealsItems: [[]],
  addOnMealsItems: [[]],

  age: '',
  heightInCm: '',
  heightType: 'cm', // Assuming you want to set a default value
  heightInFeet: '',
  heightInInches: '',
  weightInKg: '',
  weightInLbs: '',
  weightType: 'kg', // Assuming you want to set a default value
  goal: '',
  timeSinceIdealWeight: '',
  lifeEvents: [],
  idealWeight: '',
  prepareYourMeal: '',
  meal: '',
  activityLevel: '',
  workout: '', // Assuming you want to set a default value
  intensity: '',
  addWorkout: '', // Assuming you want to set a default value
  hasAllergies: false, // Default value assuming no allergies
  allergies: [],
  lifestyleIssues: [],
  ingredients:[],

  drink:'',
  // drinkTime:'',
  drinkTime:[],
  workoutActivities:[],
  workoutPreference:'',
  homeEquipment :[],
  selectedWeekdays:[]


};
  const WeightLossForm = () => {
  const history = useHistory();

 
  const [isLoading, setIsLoading] = useState(false); // New state for loading indication

  const [formData, setFormData] = useState(initialFormData);
  const [foodItems, setFoodItems] = useState([]);
  const [addOnItems, setAddOnItems] = useState([]);
  const [isOnboarding, setIsOnboarding] = useState(true); // State to toggle forms



  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
  };

//   const handleChangeMeals = (e) => {
//     const { name, value } = e.target;
//     console.log(name);

//     // Dynamically update bigMealsItems or smallMealsItems based on the change in bigMeals or smallMeals
//     let updatedMealsItems = [...formData[name + 'Items']];
//     const mealsCount = parseInt(value);

//     // Adjust the length of the meals items array
//     updatedMealsItems = new Array(mealsCount).fill([]);

//     setFormData({
//         ...formData,
//         [name]: value,
//         [name + 'Items']: updatedMealsItems,
//     });
// };
const handleChangeMeals = (e) => {
  const { name, value } = e.target;
  console.log(name);

  // Dynamically update bigMealsItems or smallMealsItems based on the change in bigMeals or smallMeals
  let updatedMealsItems = [...formData[name + 'Items']];
  const mealsCount = parseInt(value);

  // Adjust the length of the meals items array
  //updatedMealsItems = new Array(mealsCount).fill([]);
 // Adjust the length of the meals items array
  updatedMealsItems = updatedMealsItems.slice(0, mealsCount);

  // If bigMeals is changed to 0, update addOnMealsItems as well
  if (name === 'bigMeals' && value === '0') {
      updatedMealsItems = [[]];
      setFormData({
          ...formData,
          [name]: value,
          [name + 'Items']: updatedMealsItems,
          addOnMealsItems: [[]],
      });
  } else {
      setFormData({
          ...formData,
          [name]: value,
          [name + 'Items']: updatedMealsItems,
      });
  }
};


  const handleCheckboxChange = (checkedItems) => {
      setFormData({
          ...formData,
          breakfastItems: checkedItems,
      });
  };


  


  const handleSubmit = (e) => {

    console.log(formData)
    e.preventDefault();
  
    // Validation checks
    const errors = {};
  
    // Check if name is empty
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
  
    // Check if email is valid
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
  
    // Check if phoneNumber is valid
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone number is required';
    } else if (!/^\d{10}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = 'Phone number is invalid';
    }
  
    // Check if bodyweight is valid
    if (!formData.bodyweight.trim()) {
      errors.bodyweight = 'Bodyweight is required';
    } else if (isNaN(formData.bodyweight)) {
      errors.bodyweight = 'Bodyweight must be a number';
    }
  
    // Check if bodyFatPercentage is valid
    if (!formData.bodyFatPercentage.trim()) {
      errors.bodyFatPercentage = 'Body fat percentage is required';
    } else if (isNaN(formData.bodyFatPercentage)) {
      errors.bodyFatPercentage = 'Body fat percentage must be a number';
    }
  
    // Check if physicalActivityFactor is valid
    if (!formData.physicalActivityFactor.trim()) {
      errors.physicalActivityFactor = 'Physical activity factor is required';
    } else if (isNaN(formData.physicalActivityFactor)) {
      errors.physicalActivityFactor = 'Physical activity factor must be a number';
    }
  
    // Check if thermicEffectOfFoodFactor is valid
    if (!formData.thermicEffectOfFoodFactor.trim()) {
      errors.thermicEffectOfFoodFactor = 'Thermic effect of food factor is required';
    } else if (isNaN(formData.thermicEffectOfFoodFactor)) {
      errors.thermicEffectOfFoodFactor = 'Thermic effect of food factor must be a number';
    }
  
    // Check if weightTrainingDuration is valid
    if (!formData.weightTrainingDuration.trim()) {
      errors.weightTrainingDuration = 'Weight training duration is required';
    } else if (isNaN(formData.weightTrainingDuration)) {
      errors.weightTrainingDuration = 'Weight training duration must be a number';
    }
  
    // Check if energyBalanceFactor is valid
    if (!formData.energyBalanceFactor.trim()) {
      errors.energyBalanceFactor = 'Energy balance factor is required';
    } else if (isNaN(formData.energyBalanceFactor)) {
      errors.energyBalanceFactor = 'Energy balance factor must be a number';
    }
  
    // Check if trainingDaysPerWeek is valid
    if (!formData.trainingDaysPerWeek.trim()) {
      errors.trainingDaysPerWeek = 'Training days per week is required';
    } else if (isNaN(formData.trainingDaysPerWeek)) {
      errors.trainingDaysPerWeek = 'Training days per week must be a number';
    }
  

    if (parseInt(formData.bigMeals) !== formData.bigMealsItems.length) {
      errors.bigMealsItems = 'Please select at least one item for each big meal';
    }
    
    // Check if bigMealsItems is empty
    if (formData.bigMealsItems.some(list => list.length === 0)) {
      errors.bigMealsItems = 'Please select at least one item for each big meal';
    }

    // Check if addOnMealsItems is empty
    if (formData.addOnMealsItems.some(list => list.length === 0)) {
      errors.addOnMealsItems = 'Please select at least one item for add-ons';
    }

    if (formData.smallMeals > 0 && parseInt(formData.smallMeals) !== formData.smallMealsItems.length) {
      errors.smallMealsItems = 'Number of small meals and selected items do not match';
    }
    

    // Check if smallMealsItems is empty when smallMeals has a value
    if (formData.smallMeals > 0 && formData.smallMealsItems.some(list => list.length === 0)) {
      errors.smallMealsItems = 'Please select at least one item for each small meal';
      }

    // If there are errors, display SweetAlert with error messages
    if (Object.keys(errors).length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Required Fields!',
        text: 'Please fix the following errors:',
        html: Object.values(errors).map(error => `<p>${error}</p>`).join('')
      });
      return;
    }
  
    // If there are no errors, proceed with submitting the form data  
    
    console.log('Form data is valid:', formData);

  
        sendDietInfo(formData).then(res => {
          console.log(res);
          // If sendDietInfo is successful, initiate PDF download
          genPdf(res?.data?.id)
            .then(downloadRes => {
              console.log(downloadRes);
              // If downloadPdf returns true, redirect to another page
             history.push('/userDiet');  
             setFormData(initialFormData);
            // setIsPaymentDone(false)
            })
            .catch(error => {
              console.error('Error downloading PDF:', error);
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to download PDF',
              });
            });
        })
        .catch(error => {
          console.error('Error sending diet info:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Failed to send diet information',
          });
        });
    };


  return (
<Box maxWidth={{ 
        base: "100%", // for mobile devices
        sm: "100%",   // for tablets
        md: "45%"    // for laptops and desktops


}} mx="auto"      boxShadow="lg" bg="white"
 // Minimum height of 100% of the viewport height
//  minHeight={'100vh'}  
>
          <Box  style={{borderRadius:'0px'}} >
              <form onSubmit={handleSubmit}>
               
              {isOnboarding ? (
        <OnboardingForm onNext={() => setIsOnboarding(false)} />
      ) : (
                      <DietFormNew
                          formData={formData}
                          setFormData={setFormData}
                          handleChange={handleChange}
                          handleChangeMeals={handleChangeMeals}
                          foodItems={foodItems}
                          setFoodItems={setFoodItems}
                          addOnItems={addOnItems}
                          setAddOnItems={setAddOnItems}
                          handleCheckboxChange={handleCheckboxChange}
                          handleSubmit={handleSubmit}
                          
                          setIsOnboarding={setIsOnboarding}
                          
                      />)}
                

              
              </form>
          </Box>
      </Box>
  );
};

export default WeightLossForm;
