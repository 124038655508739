import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Heading,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { fetchfoodAllergies } from 'services/foodItemsService';
import down_backgd_img from 'assets/img/down_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const DietaryRestrictionStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [hasAllergies, setHasAllergies] = useState(false);
  const [allergiesList, setAllergiesList] = useState([]);
  const [loading, setLoading] = useState(true);

  // Handle click on Yes
  const handleYes = () => {
    setHasAllergies(true);
    onOpen();
    setFormData({ ...formData, hasAllergies: true });
  };

  // Handle click on No
  const handleNo = () => {
    setHasAllergies(false);
    setSelectedAllergies([]);
    onClose();
    setFormData({ ...formData, hasAllergies: false, allergies: [] });
  };

  // Handle selection of allergies
  const handleAllergyChange = (selected) => {
    setSelectedAllergies(selected);
    setFormData({ ...formData, allergies: selected });
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    fetchfoodAllergies()
      .then(data => {
        setAllergiesList(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching allergies:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (formData.hasAllergies !== undefined && formData.allergies !== undefined) {
      setHasAllergies(formData.hasAllergies);
      setSelectedAllergies(formData.allergies);
      formData.hasAllergies ? onOpen() : onClose();
    } else {
      setHasAllergies(false);
      setSelectedAllergies([]);
    }
  }, [formData, onOpen, onClose]);
//for setting question no start
const { setQuestionNo,setDisplayheader } = useContext(AppContext);
useEffect(() => {
  setDisplayheader(true);
  setQuestionNo(13)
}, []);
//for setting question no end
  return (
    <Flex direction="column" align="left"    pl={10} pr={10}
    
    className='bottomImage'
    >
     <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>
        Do you have any dietary restrictions or food allergies?
      </Heading>
      <Stack direction="column"  mb={4} mt={4} alignItems={'center'} justifyContent={'space-around'}>
        <Button
          border={'1px'}
          className='dietary_default_next_button' 
          onClick={handleYes}
          borderColor="gray.400" // Adjust as needed
  bg={hasAllergies === true ? 'gray.900' : 'gray.100'} // 'gray.900' is almost black
  color={hasAllergies === true ? 'white' : 'black'} // Text color
 
        >
          Yes
        </Button>
        <Button
         className='dietary_default_next_button' 
          border={'1px'}
          onClick={handleNo}
         borderColor="gray.400" // Adjust as needed
  bg={hasAllergies === false ? 'gray.900' : 'gray.100'} // 'gray.900' is almost black
  color={hasAllergies === false ? 'white' : 'black'} // Text color
        >
          No
        </Button>
      </Stack>
      {isOpen && (
        <Box p={4} borderWidth="1px" borderRadius="md" boxShadow="sm">
          <Text>Select all that apply:</Text>
          <CheckboxGroup value={selectedAllergies} onChange={handleAllergyChange}>
            <Stack pl={6} mt={2} spacing={1}>
              {loading ? (
                <Text>Loading allergies...</Text>
              ) : (
                allergiesList.map(allergy => (
                  <Checkbox  className='default_checkbox1' key={allergy.id} value={allergy.id + ''}>
                    {allergy.allergyName}
                  </Checkbox>
                ))
              )}
            </Stack>
          </CheckboxGroup>
        </Box>
      )}
       <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button  className='default_back_button'   colorScheme="teal" variant="outline" onClick={handleBack}>
          Back
        </Button>
        <Button
           className='default_next_button' 
          mt={4}
          colorScheme="teal"
          isDisabled={
            hasAllergies
              ? selectedAllergies.length === 0
              : false
          }
          onClick={() => setCurrentStep(currentStep + 1)}
        >
          Next
        </Button>
      </Stack>
      <br></br>
      <br></br>
    </Flex>
  );
};

export default DietaryRestrictionStep;
