import React from "react";

// Chakra imports
import { Box, Flex, Image, useColorModeValue } from "@chakra-ui/react";

// Custom components
 import { HSeparator } from "components/separator/Separator";
import logoW from "assets/img/Logo.png";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
        {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} />   */}
       {/* <h1 style={{fontSize:'40px'}}>DIET APP</h1> */}
 
   <Image src={logoW} alt='Dan Abramov' style={{background:'black',width:'50%','borderRadius':'5px'}} />
       <HSeparator mt='20px' />
    </Flex>
  );
}

export default SidebarBrand;
