import React, { useContext, useEffect } from 'react';
import { Box, Button, Text, Heading, VStack, Progress, Flex } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';  // Ensure you have this context setup

const VatsavClientJourney = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Hide header based on AppContext
  const { setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(false);
  }, [setDisplayheader]);

  return (
    <VStack
      spacing={4}
      align="stretch"
      p={5}
      bgGradient="linear(to-b, rgba(0 139 147) 0%, rgba(6,140,159,1) 53%, rgba(3,102,117,1) 100%)" // Applying the gradient background

      color="white"              // Text color set to white
       boxShadow="xl"
      minH="80vh"
      justify="center"
    >
      <Heading textAlign="center" fontSize="xl" >
      A look into one of our client s 
journey at Coach Vatsav
      </Heading>
       
      <Text textAlign="center" fontSize="md" mt={2} mb={4}>
      Varsha is a 36 year old mother to a baby girl. She s been diabetic for the past 5 years. She struggled to lose her post pregancy weight & due to low energy levels gained a few more. We helped her lose 17 kgs 
& bring her diabetes into control. We got her hba1c 
from 6.7 to 5.8 through our plan.      </Text>
      <br></br>

      <Flex mt={8} mb={20} width="100%" justifyContent="space-around" flexDirection={'row'} alignItems={'center'}>
     <Button className='default_back_button' bg="gray.700" _hover={{ bg: 'gray.600' }} onClick={backStep}>
        Back
      </Button>  
      <Button className='default_next_button_for_special' background={'white'} color={'grey'} width={'50%'} alignSelf={'center'} borderRadius={'5'} onClick={nextStep}  >
        Continue
      </Button>
    </Flex>
{/* 
      <Button background={'white'} color={'grey'} width={'50%'} alignSelf={'center'} borderRadius={'5'} onClick={nextStep} size="lg">
        Continue
      </Button> */}
    </VStack>
  );
};

export default VatsavClientJourney;
