import React, { useState, useEffect, useContext } from 'react';
import { VStack, Button, Heading, Checkbox, CheckboxGroup, Box, Stack } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

// Drink options with unique IDs
const drinkOptions = [
  { id: 'morning', title: 'Morning' },
  { id: 'evening', title: 'Evening' },
];

const DrinkTimeStep = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedDrinkTimes, setSelectedDrinkTimes] = useState(formData.drinkTime || []);

  useEffect(() => {
    // Ensure we have default values if the form data already contains drinkTime selections
    if (formData.drinkTime) {
      setSelectedDrinkTimes(formData.drinkTime);
    }
  }, [formData.drinkTime]);

  useEffect(() => {
    // Check if we should skip the DrinkTimeStep
    if (formData.drink === 'none') {
      setCurrentStep(currentStep + 1);  // Move to the next step
    }
  }, [formData.drink, setCurrentStep, currentStep]);

  const handleDrinkTimeSelection = (values) => {
    setSelectedDrinkTimes(values);
    setFormData({ ...formData, drinkTime: values });
  };

  // For setting question number start
  const { setQuestionNo, setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(true);
    setQuestionNo(16);
  }, [setDisplayheader, setQuestionNo]);
  // For setting question number end

  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10} className='bottomImage'>
      <Heading as="h5" size="md" textAlign="left">
        When do you drink your tea/coffee?
      </Heading>

      <CheckboxGroup colorScheme="teal" value={selectedDrinkTimes} onChange={handleDrinkTimeSelection}>
        {drinkOptions.map((option) => (
          <Checkbox key={option.id} value={option.id} className='default_checkbox1'>
            {option.title}
          </Checkbox>
        ))}
      </CheckboxGroup>

      <p textAlign="left" style={{fontSize: '15px'}} mb={6} color={'#b2b2b'}>
        <b>Friendly Note -</b> We recommend drinking tea or coffee only once a day to prevent potential negative effects on 
        sleep & hydration.
      </p>

      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={() => setCurrentStep(currentStep + 1)} isDisabled={selectedDrinkTimes.length === 0}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default DrinkTimeStep;
