import React, { useContext, useEffect } from 'react';
import { Box, Button, Text, Heading, VStack, Progress, Flex } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';  // Ensure you have this context setup

const BodyFatResultCard = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const getDescription = (percentage) => {
    if (percentage < 18) {
      return "You are under the recommended body fat percentage. It's important to maintain a healthy balance.";
    } else if (percentage < 25) {
      return "You have a healthy body fat percentage. Continue maintaining your current habits.";
    } else if (percentage < 35) {
      return "Your body fat percentage is slightly above average, but with regular exercise and diet, you can improve.";
    } else {
      return "A fat % of 35 is considered overweight. You may be at risk for cardiovascular disease and type 2 diabetes & other obesity related health problems.";
    }
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Hide header based on AppContext
  const { setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(false);
  }, [setDisplayheader]);

  return (
    <VStack
      spacing={4}
      align="stretch"
      p={5}
      bgGradient="linear(to-b, rgba(0 139 147) 0%, rgba(6,140,159,1) 53%, rgba(3,102,117,1) 100%)" // Applying the gradient background

      color="white"              // Text color set to white
       boxShadow="xl"
      minH="80vh"
      justify="center"
    >
      <Heading textAlign="center" fontSize="xl" >
        Your current body fat % is
      </Heading>
      <Text fontSize="9xl" fontWeight="bold"  textAlign="center">
        {formData.bodyFatPercentage}
      </Text>
      <Text textAlign="center" fontSize="md" >
      We use body fat % as a very rough metric 
to access your overall health. While it may 
not always be accurate & is open to further 
discussion,  {getDescription(formData.bodyFatPercentage)}
      </Text>
      <Text textAlign="center" fontSize="md" mt={2} mb={4}>
        Our focus with this program will be to help you reach a more healthy fat percentage through a balanced diet & regular exercise.
      </Text>
      <br></br>
      <Flex mt={8} mb={20} width="100%" justifyContent="space-around" flexDirection={'row'} alignItems={'center'}>
     <Button className='default_back_button' bg="gray.700" _hover={{ bg: 'gray.600' }} onClick={backStep}>
        Back
      </Button>  
      <Button className='default_next_button_for_special' background={'white'} color={'grey'} width={'50%'} alignSelf={'center'} borderRadius={'5'} onClick={nextStep} >
        Continue
      </Button>
    </Flex>


      
    </VStack>
  );
};

export default BodyFatResultCard;
