import React, { useState, useEffect, useContext } from 'react';
import { FormControl, FormLabel, NumberInput, NumberInputField, Text, Stack, Button, Heading, Image } from '@chakra-ui/react';
import down_backgd_img from 'assets/img/down_left_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const AgeStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [age, setAge] = useState(formData.age || '');
  const [isAgeValid, setIsAgeValid] = useState(false);

  useEffect(() => {
    // Validate age on initial mount and when formData changes
    setIsAgeValid(formData.age >= 18 && formData.age <= 120);
  }, [formData.age]);

  const handleAgeChange = (valueAsString, valueAsNumber) => {
    setAge(valueAsString);
    const isValid = valueAsNumber >= 18 && valueAsNumber <= 120;
    setIsAgeValid(isValid);
    if (isValid) {
      setFormData({ ...formData, age: valueAsNumber });
    }
  };

  const nextStep = () => {
    if (currentStep < totalSteps && isAgeValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };
  //for setting question no start
  const { setQuestionNo,setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(true);
    setQuestionNo(4)
  }, []);
 //for setting question no end
  return (
    <>
      <FormControl isRequired   pl={10} pr={10}  className='bottomLeftImage'>
      <Heading   textAlign="left" as="h6"  size='md'   mb={1}   color={'#b2b2b'}>How old are you ? </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>This question is required.</p>
     
     <br />
      {/* <Heading   textAlign="left" as="h3" size="lg"   mb={6} color={'teal'}>What's your age?*</Heading> */}
        <NumberInput 
          max={120} 
          min={18} 
          value={age} 
          onChange={handleAgeChange} 
          clampValueOnBlur={false}
           
        >
          <NumberInputField 
          className='default_input'
            id='age' 
            maxLength={3}
            fontSize="3xl"
            textAlign="center"
            width="150px"
            height="50px"
            size="lg"
            placeholder='000'
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
            _placeholder={{ fontSize: 'xl', color: 'gray.500' }}
          />
        </NumberInput>
        <Text mt={2}>Valid age is 18-120</Text>
      {/* </FormControl> */}
 
      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button  
         className='default_back_button' 
          colorScheme='teal' 
          variant='outline' 
          onClick={prevStep}
        >
          Back
        </Button>
        <Button 
         className='default_next_button' 
          colorScheme='teal' 
          variant='outline' 
          onClick={nextStep}
          isDisabled={!isAgeValid}
        >
          Next
        </Button>
      </Stack>
      </FormControl>
      {/* <Image src={down_backgd_img} alt="Good hands"  boxSize="300px"  /> */}
    </>
  );
};

export default AgeStep;
