import React, { createContext, useContext, useState } from 'react';

// Create the context object with a default value
export const AppContext = createContext(null);

// Provider in your app
export const AppProvider = ({ children }) => {
    const [question, setQuestion] = useState(0);
    const [displayHeader, setDisplayHeader] = useState(true);

    // You could fetch user data here or handle authentication

    const setQuestionNo = (question) => {
        setQuestion(question);
    };    
    
    const setDisplayheader = (displayHeader) => {
        setDisplayHeader(displayHeader);
    };

     
 
    return (
        <AppContext.Provider value={{question,setQuestionNo,displayHeader,setDisplayheader }}>
            {children}
        </AppContext.Provider>
    );
};
