import React, { useState, useEffect, useContext } from 'react';
import { VStack, Button, Heading, Radio, RadioGroup, Box, useRadioGroup, useRadio, useColorModeValue, Stack } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';

// Drink options with unique IDs
const workoutOptions = [
  { id: 'home', title: 'Home' },
  { id: 'gym', title: 'Gym' },
 
];

// Custom radio card component
const RadioCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const bg = useColorModeValue('gray.100', 'gray.700');
  const selectedBg = "black";
  const borderColor = useColorModeValue('gray.200', 'gray.500');

  return (
    <Box as='label' w="full">
      <input {...input} />
      <Box
        {...checkbox}
        bg={bg}
        borderColor={borderColor}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        mb={'10px'}
        _checked={{
          bg: selectedBg,
          borderColor: 'teal.500',
          color: 'white',
        }}
        display={'flex'}
        px={5}
        py={3}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const WorkoutPreferenceStep  = ({ formData, setFormData, currentStep, setCurrentStep }) => {
  const [selectedWorkoutPreference, setSelectedWorkoutPreference] = useState(formData.workoutPreference);

  useEffect(() => {
    // Ensure we have a default value if the form data already contains a workoutPreference selection
    if (formData.workoutPreference) {
      setSelectedWorkoutPreference(formData.workoutPreference);
    }
  }, [formData.workoutPreference]);

 

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "workoutPreference",
    defaultValue: selectedWorkoutPreference,
    onChange: (value) => {
      setSelectedWorkoutPreference(value);
      setFormData({ ...formData, workoutPreference: value });
    }
  });
  const group = getRootProps();
 
 //for setting question no start
 const { setQuestionNo,setDisplayheader } = useContext(AppContext);
 useEffect(() => {
  setDisplayheader(true);
   setQuestionNo(25)
 }, []);
 //for setting question no end
  return (
    <VStack spacing={4} align="stretch" pl={10} pr={10} className='bottomImage' >
      <Heading as="h5" size="md" textAlign="left"  >
      Do you prefer home workouts or  going to the gym?            </Heading>
   

      <RadioGroup    value={selectedWorkoutPreference}>
        <Box {...group}>
          {workoutOptions.map((option) => (
            <RadioCard key={option.id} {...getRadioProps({ value: option.id })}>
              <Radio  className='default_radio_input1 default_input' value={option.id} mr={5} /> {option.title}
            </RadioCard>
          ))} 
        </Box>
      </RadioGroup>
  

      <Stack direction='row' justifyContent='space-around' alignItems='center' p={8}>
        <Button className='default_back_button' colorScheme="teal" variant="outline" onClick={() => setCurrentStep(currentStep - 1)}>
          Back
        </Button>
        <Button className='default_next_button' colorScheme="teal" onClick={() => setCurrentStep(currentStep + 1)} isDisabled={!selectedWorkoutPreference}>
          Next
        </Button>
      </Stack>
    </VStack>
  );
};

export default WorkoutPreferenceStep  ;
