import React, { useContext, useEffect, useState } from 'react';
import { FormControl, FormLabel, Input, Stack, Button, Heading, Image } from '@chakra-ui/react';
import down_left_backgd_img from 'assets/img/down_left_backgd_img.png'
import { AppContext } from 'contexts/AppContext';

const NameStep = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  const [isNameValid, setIsNameValid] = useState(formData.name.trim().length > 0);
  const { question,setQuestionNo ,setDisplayheader} = useContext(AppContext);
  useEffect(() => {
    setQuestionNo(2)
    setDisplayheader(true)

  }, []);
  const handleChange = (e) => {
    const { value } = e.target;
    setFormData({ ...formData, name: value });
    setIsNameValid(value.trim().length > 0);
  };

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <>
      <FormControl isRequired pl={10} pr={10}   className='bottomLeftImage'>
   
      <Heading   textAlign="left" as="h6"  size='md'   mb={2}   color={'#b2b2b'}>What is your name? </Heading>
       <p   textAlign="left" style={{fontSize:'14px'}}    mb={6} color={'#b2b2b'}>This question is required.</p>
     
       <br></br>
        <Input 
          id='name' 
          name='name' 
          value={formData.name} 
          onChange={handleChange} 
          placeholder='Type your answer here...' 
          className='default_input'
        />

      <Stack direction='row' justifyContent='space-around'  alignItems='center' p={8}>
        <Button 
        className='default_back_button'
          colorScheme='teal' 
          
          onClick={() => setCurrentStep(currentStep - 1)} 
          isDisabled={currentStep === 1}
        >
          Back 
        </Button>
        <Button 
        className='default_next_button'
          colorScheme='teal' 
          variant='outline' 
          onClick={nextStep} 
          isDisabled={!isNameValid}
        >
          Next
        </Button>
      </Stack>
      </FormControl>
      {/* <Image src={down_left_backgd_img} alt="Good hands" boxSize="300px"   /> */}
    </>
  );
};

export default NameStep;
