import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import Swal from "sweetalert2";
import { login } from "services/loginService";
import { doLogin } from "helpers/loginHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { isLoggedIn } from "helpers/loginHelper";
 
function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [loginData, setLoginData] = useState({
    userId: '',
    password: ''
});

useEffect(() => {
  // Redirect to diet/home if already logged in
  if (isLoggedIn()) {
    history.push('/diet/home');
  }
}, [history]);

const handleLogin = async () => {
  if (!loginData.userId || !loginData.password) {
      Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please enter both user ID and password.',
      });
      return;
  }
  setLoading(true); // Set loading state to true when starting the login process

  try {

    
          // Submit the data to server to generate token
          const response = await login(loginData);
          console.log(response);
          setLoading(false);

          // Save the data to sessionStorage
          doLogin(response, () => {
            if (response.statusCode === 308) {
            // toast.error(response.message);
              Swal.fire({
                icon: 'error',
                title: response.message,
                
                });
              setLoginData({
                userId: '',
                password: '',
              });
              return;
            }
            console.log('Login Data stored to session storage');
            history.push('/diet/home');
          });
      } catch (error) {
          console.error('Error occurred while logging in:', error);
          setLoading(false);
      }
};


const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({
        ...loginData,
        [name]: value
    });
};








  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
    
      <Flex mt={8} p={6} borderRadius="lg" boxShadow="lg" bg="white"
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        // px={{ base: "25px", md: "0px" }}
        // mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Sign In
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your userid and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              User Id<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='text'
              placeholder='User id'
              mb='24px'
              fontWeight='500'
              size='lg'

              name="userId"
              value={loginData.userId}
              onChange={handleChange}

            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Password'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                value={loginData.password}
                onChange={handleChange}
                name="password"
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
          
            <Button
              fontSize='sm'
              // variant='brand'
              backgroundColor={'black'}
              textColor={'white'}
            
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              onClick={handleLogin}
              isLoading={loading} loadingText="Signing In..."
              >
              Sign In
            </Button>
          </FormControl>
           
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
