import React, { useContext, useEffect } from 'react';
import { Box, Button, Text, Heading, VStack, Progress, SimpleGrid, Icon, Image, Flex } from '@chakra-ui/react';
import { AppContext } from 'contexts/AppContext';  // Ensure you have this context setup
import BehaviouralPsychology from 'assets/img/BehaviouralPsychology.png'
import NutritionalScience from 'assets/img/NutritionalScience.png'
import HolisticApproaches from 'assets/img/HolisticApproaches.png'

const SuccessStoriesCard = ({ formData, setFormData, currentStep, setCurrentStep, totalSteps }) => {
  

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  const backStep = () => {
    setCurrentStep(currentStep - 1);
  };
  // Hide header based on AppContext
  const { setDisplayheader } = useContext(AppContext);
  useEffect(() => {
    setDisplayheader(false);
  }, [setDisplayheader]);

  return (
    <VStack
      spacing={4}
      align="stretch"
      p={5}
      bgGradient="linear(to-b, rgb(238 84 107) 0%, rgb(241 127 102) 53%, rgb(245 172 98) 100%)" // Applying the gradient background
       color="white"  // Text color set to white
       boxShadow="xl"
      minH="80vh"
      justify="center"
    >
      <Heading textAlign="center" fontSize="xl" >
      We've helped more than 1,100 
people reach their goals with 
our program.
      </Heading>
      <br></br>
      <SimpleGrid fontWeight={'bold'} columns={3} spacing={5} width="100%" mt={4}>
        <VStack  >
        <Image src={NutritionalScience} alt="Nutritional Science"   boxSize="50px"  />
          <Text textAlign={'center'}  fontSize="sm" casing={'uppercase'}>Nutritional Science</Text>
        </VStack>
        <VStack>
        <Image src={HolisticApproaches} alt="Holistic Approaches"   boxSize="50px"  />

           <Text fontWeight={''} textAlign={'center'} fontSize="sm" casing={'uppercase'}>Holistic Approaches</Text>
        </VStack>
        <VStack>
        <Image src={BehaviouralPsychology} alt="Behavioral Psychology"   boxSize="50px"  />

           <Text  textAlign={'center'} fontSize="sm" casing={'uppercase'}>Behavioral Psychology</Text>
        </VStack>
      </SimpleGrid>
      <br></br>

      <Text textAlign="center" fontSize="md" mt={2} mb={4}>
      Achieve sustainable results & embrace a healthier lifestyle for the long haul once & for all. 
           </Text>
      <br></br>
      <Flex mt={8} mb={20} width="100%" justifyContent="space-around" flexDirection={'row'} alignItems={'center'}>
     <Button className='default_back_button' bg="gray.700" _hover={{ bg: 'gray.600' }} onClick={backStep}>
        Back
      </Button>  
      <Button className='default_next_button_for_special' background={'white'} color={'grey'} width={'50%'} alignSelf={'center'} borderRadius={'5'} onClick={nextStep}  >
        Continue
      </Button>
    </Flex>


 
    </VStack>
  );
};

export default SuccessStoriesCard;
